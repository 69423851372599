import React from "react";
import TopSection from "./Accreditation/TopSection";
import Carousel from "./Accreditation/Carousel";
import { useAwardsQuery } from "../../services/awardApi";

const Accreditation = () => {
  const { data } = useAwardsQuery({page: '00', perPage: '00'});
  return (
    <div className="pt-[48px] pb-[100px] px-[20px] xl:px-0">
      <TopSection />
      <Carousel data={data?.data} />
    </div>
  );
};

export default Accreditation;
