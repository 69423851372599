import * as React from "react";
import { HiOutlineTrash } from "react-icons/hi";
import { AiOutlineEdit } from "react-icons/ai";
import EditFAQSModal from "./EditFAQModal";
import {
  useDeleteFaqByIdMutation,
  useFaqsQuery,
} from "../../../services/faqApi";
import ModalDeleteTableRow from "../../Common/ModalDeleteTableRow";
import { toast } from "react-toastify";
import { Button, CardFooter, Typography } from "@material-tailwind/react";

const headCells = [
  {
    id: "faqHeading",
    numeric: false,
    disablePadding: false,
    label: "Heading",
  },
  {
    id: "faqText",
    numeric: false,
    disablePadding: false,
    label: "Text",
  },
  {
    id: "action",
    numeric: false,
    disablePadding: false,
    label: "Action",
  },
];

export default function FAQSTable() {
  const [showEditableModal, setEditableModal] = React.useState(false);
  const [editableRow, setEditableRow] = React.useState({});
  const [showDeleteModal, setDeleteModal] = React.useState(false);
  const [deleteRowId, setDeleteRowId] = React.useState("");
  const [currentPage, setCurrentPage] = React.useState(1);

  const { data } = useFaqsQuery({ page: currentPage, perPage: 5 });
  const [deleteFaqById] = useDeleteFaqByIdMutation();

  // REMOVE TABLE ROW FUNCTION
  const rmvFunc = () => {
    if (deleteRowId) {
      deleteFaqById(deleteRowId).then((res) => {
        if (res?.data?.status) {
          setDeleteRowId("");
          toast.success(res?.data?.message);
        } else {
          toast.error(res?.data?.message);
        }
      });
    }
  };
  //console.log("faq table", data?.data);
  return (
    <>
      {/* ////// TABLE ////// */}
      <div className="w-[100%] overflow-x-auto">
        <table className="mt-4 w-full min-w-max table-auto text-left">
          {/* ///// TABLE HEAD ///// */}
          <thead>
            <tr>
              {headCells?.map((head, index) => (
                <th
                  key={head}
                  className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4"
                >
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="text-[1rem] font-bold leading-none opacity-70"
                  >
                    {head?.label}
                  </Typography>
                </th>
              ))}
            </tr>
          </thead>
          {/* ///// TABLE BODY ///// */}
          <tbody>
            {data?.data?.map((row, index) => {
              const isLast = index === data?.data?.length - 1;
              const classes = isLast
                ? "p-4"
                : "p-4 border-b border-blue-gray-50";

              return (
                <tr key={row?.question}>
                  <td className={classes}>
                    <p className="w-[300px] truncate">{row?.question}</p>
                  </td>
                  <td className={classes}>
                    <p className="w-[300px] truncate">{row?.answer}</p>
                  </td>
                  <td className={classes}>
                    <div className="flex gap-[.5rem]">
                      <AiOutlineEdit
                        onClick={() => {
                          setEditableModal(true);
                          setEditableRow(row);
                        }}
                        className="text-[1.3rem] cursor-pointer"
                      />
                      <HiOutlineTrash
                        onClick={() => {
                          setDeleteRowId(row?._id);
                          setDeleteModal(true);
                        }}
                        className="text-[1.3rem] cursor-pointer"
                      />
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        {/* ////// TABLE FOOTER ////// */}
        <CardFooter className="flex items-center justify-between border-t border-blue-gray-50 p-4">
          <Typography
            variant="small"
            color="blue-gray"
            className="font-normal whitespace-nowrap mr-[1rem]"
          >
            Page {currentPage} of {data?.totalPages}
          </Typography>
          <div className="flex gap-2">
            {currentPage > 1 ? (
              <Button
                onClick={() => setCurrentPage(currentPage - 1)}
                variant="outlined"
                size="sm"
              >
                Previous
              </Button>
            ) : (
              <Button disabled variant="outlined" size="sm">
                Previous
              </Button>
            )}
            {currentPage < data?.totalPages ? (
              <Button
                onClick={() => setCurrentPage(currentPage + 1)}
                variant="outlined"
                size="sm"
              >
                Next
              </Button>
            ) : (
              <Button disabled variant="outlined" size="sm">
                Next
              </Button>
            )}
          </div>
        </CardFooter>
      </div>
      {/* ///// EDIT ROW MODAL ////// */}
      <EditFAQSModal
        showEditableModal={showEditableModal}
        setEditableModal={setEditableModal}
        row={editableRow}
      />
      {/* ////// CONFIRMATION DELETE ROW MODAL ////// */}
      <ModalDeleteTableRow
        state={showDeleteModal}
        action={setDeleteModal}
        rmvFunc={rmvFunc}
      />
    </>
  );
}
