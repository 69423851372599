import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl } from "./api";
const getTokenFromLocalStorage = () => {
  return localStorage.getItem("token");
};
export const testimonialApi = createApi({
  reducerPath: "testimonialApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseUrl}/testimonials`,
    prepareHeaders: (headers) => {
      const token = getTokenFromLocalStorage();
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["Testimonial"],
  endpoints: (builder) => ({
    testimonials: builder.query({
      query: ({ page, perPage }) => `/?page=${page}&perPage=${perPage}`,
      providesTags: ["Testimonial"],
    }),
    getTestimonialById: builder.query({
      query: (id) => `/${id}`,
      providesTags: ["Testimonial"],
    }),
    deleteTestimonialById: builder.mutation({
      query: (id) => ({
        url: `/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Testimonial"],
    }),
    addTestimonial: builder.mutation({
      query: (data) => ({
        url: "/",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Testimonial"],
    }),
    updateTestimonial: builder.mutation({
      query: ({ id, data }) => ({
        url: `/${id}`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["Testimonial"],
    }),
  }),
});
export const {
  useTestimonialsQuery,
  useAddTestimonialMutation,
  useGetTestimonialByIdQuery,
  useUpdateTestimonialMutation,
  useDeleteTestimonialByIdMutation,
} = testimonialApi;
