import React from "react";
import TopCarouselButton from "../../Home_Page/CarouselDivs/TopCarouselButton";

const TopSection = () => {
  return (
    <div className="max-w-[1140px] mx-auto">
      <h1 className="text-[#0a2a6c] text-[1.875rem] lg:text-[2.875rem] font-semibold">
        We’ll Treat You Well
      </h1>
      <div className="flex justify-between items-center">
        <div className="max-w-[915px] text-[#384f7e]">
        <p className="leading-[1.5rem]">
          Avis Vascular Centre, Hyderabad, India, has been a beacon of excellence in vascular healthcare for a decade. Our team of globally recognised interventional radiologists and surgeons have the latest equipment to provide minimally invasive treatments, adhering to international standards across all facilities.          <br /><br /> {/* Added double line break for extra space */}
          Our commitment to delivering top-notch healthcare extends beyond borders, earning the trust of patients not only across India but also globally. For our international patients, we understand that their healthcare needs extend beyond their hospital visits.          <br /><br /> {/* Added double line break for extra space */}
          Our dedicated International Patient Services Team ensures stress-free treatment and recovery for medical tourists. The team makes visa and travel arrangements, hotel bookings, hires translators and oversees international insurance coverage. We aim to ensure a comfortable stay conducive to optimal health and well-being.        </p>
        </div>
      </div>
      {/* <div>
        <TopCarouselButton text={"Know More"} />
      </div> */}
      <div className="mt-[2rem]">
        <img
          src={
            "https://www.avishospitals.in/sites/default/files/styles/webp/public/2021-01/we-wll-treat-you-well.jpg.webp?itok=4sP3VOS0"
          }
          alt=""
        />
      </div>
    </div>
  );
};

export default TopSection;
