import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dialog } from "@mui/material";
import React from "react";

const ModalDeleteTableRow = ({ state, action, rmvFunc }) => {
  return (
    <Dialog maxWidth="lg" open={state}>
      {/* ///// modal body ///// */}
      <div className="w-fit pt-[1.5rem] pb-[1rem] px-[2rem]">
        <div className="flex gap-[1rem]">
          <div className="flex justify-center w-[50px] h-[50px] rounded-[50%] bg-red-500">
            <FontAwesomeIcon
              icon={faTriangleExclamation}
              className="text-[32px] text-white mt-[.4rem]"
            />
          </div>
          <div>
            {/* ///// heading ///// */}
            <h4 className="text-[1.3rem] font-semibold">Delete Confirmation</h4>
            <p className="text-[1.1rem] font-[500]">
              Are you sure to delete this row?
            </p>
            <div className="w-[100%] flex justify-end gap-[.5rem] mt-[1rem]">
              <button
                onClick={() => action(false)}
                className="border-[2px] px-[.8rem] py-[.2rem] rounded-[.5rem] font-semibold uppercase"
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  action(false);
                  rmvFunc();
                }}
                className="bg-red-500 hover:bg-red-600 text-white border-[2px] px-[.8rem] py-[.2rem] rounded-[.5rem] font-semibold uppercase"
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default ModalDeleteTableRow;
