import React from "react";
import ArrowComp from "./ArrowComp";
import { Link } from "react-router-dom";

const ViewAllBtn = ({ text_class, arrow_class, route_link }) => {
  return (
    <Link to={route_link}>
      <button
        className={`activeTranition ${text_class} flex items-center gap-[10px] whitespace-nowrap uppercase font-semibold`}
      >
        View All
        <p className={`${arrow_class}`}>
          <ArrowComp />
        </p>
      </button>
    </Link>
  );
};

export default ViewAllBtn;
