import React, { useContext, useState } from "react";
import { useLoginUserMutation } from "../../../services/userApi";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { UserContext } from "../../../App";

const Login = () => {
  const [email, setEmail] = useState("");
  const { setUser } = useContext(UserContext);
  const [password, setPassword] = useState("");
  const [loginUser] = useLoginUserMutation("");
  const navigate = useNavigate();
  const handleSave = () => {
    const data = {
      email: email,
      password: password,
    };
    loginUser(data).then((res) => {
      if (res?.data?.status) {
        localStorage.setItem("token", res?.data?.token);
        setUser(res?.data?.result);
        navigate("/dashboard");
        setEmail("");
        setPassword("");
      } else {
        toast.error(res.data.message);
      }
    });
  };
  return (
    <div className="h-[100vh] flex items-center justify-center bg-[#5b8891]">
      <div className="bg-[#f2f2f2] flex flex-col gap-[1rem] w-[100%] md:w-[400px]  pt-[2rem] pb-[4rem] px-[2rem] rounded-[.5rem]">
        <h3 className="text-center text-[24px] font-bold text-[#1a1b1d]">
          Login
        </h3>
        <input
          className="w-[100%] h-[40px] px-[20px] py-[2px] text-[.875rem] text-[#6e81a8] bg-white focus:outline-none rounded-[.2rem]"
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          className="w-[100%] h-[40px] px-[20px] py-[2px] text-[.875rem] text-[#6e81a8] bg-white focus:outline-none rounded-[.2rem]"
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button
          onClick={handleSave}
          className="w-[100%] h-[48px] bg-[#2796ac] hover:bg-[#1a778a] text-white rounded-[.2rem] uppercase"
        >
          Login
        </button>
      </div>
    </div>
  );
};

export default Login;
