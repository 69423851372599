import React from "react";
import TopSection from "./Aster_Specialist/TopSection";
import AsterSpecialistCarousel from "./Aster_Specialist/AsterSpecialistCarousel";
import { useServicesQuery } from "../../services/serviceApi";

const AsterSpecialist = () => {
  const { data } = useServicesQuery({ page: "00", perPage: "00" });
  return (
    <div id="treatmentNprocedures">
      <div className="bg-[#f2f2f2] py-[60px] px-[20px] xl:px-0">
        <div>
          <TopSection />
          <AsterSpecialistCarousel data={data?.data} />
        </div>
      </div>
    </div>
  );
};

export default AsterSpecialist;
