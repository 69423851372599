import React, { useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";

import ExpandArrowComp from "../../Common/ExpandArrowComp";
import { useFaqsQuery } from "../../../services/faqApi";

const FAQsAccordions = () => {
  const [active, setActive] = useState(null);
  const { data } = useFaqsQuery({page: '00', perPage: '00'});
  const handleAccordion = (index) => {
    if (active === index) {
      setActive(null);
    } else {
      setActive(index);
    }
  };
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 h-min gap-[1rem] mt-[1rem]">
      {data?.data?.map((el, index) => (
        <div>
          <Accordion
            expanded={active === index}
            onClick={() => handleAccordion(index)}
            className="css-rsplyy-MuiPaper-root-MuiAccordion-root"
            sx={{
              width: "100%",
              padding: "1rem",
              margin: 0,
              backgroundColor: `${active === index ? "#0653a2" : "white"}`,
              boxShadow: "none",
              border: "none",
            }}
          >
            <AccordionSummary
              expandIcon={
                <p
                  style={{
                    fill: `${active === index ? "white" : "#0a2a6c"}`,
                    transform: "rotate(180deg)",
                  }}
                >
                  <ExpandArrowComp />
                </p>
              }
              sx={{ width: "100%", padding: 0 }}
            >
              <p
                className={`text-[1.125rem] text-[${
                  active === index ? "white" : "#0a2a6c"
                }]`}
              >
                {el?.question}
              </p>
            </AccordionSummary>
            <AccordionDetails sx={{ width: "100%", padding: 0 }}>
              <p className={`text-[${active === index ? "white" : "#0a2a6c"}]`}>
                {el?.answer}
              </p>
            </AccordionDetails>
          </Accordion>
        </div>
      ))}
    </div>
  );
};

export default FAQsAccordions;
