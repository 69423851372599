import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl } from "./api";
const getTokenFromLocalStorage = () => {
  return localStorage.getItem("token");
};
export const phoneApi = createApi({
  reducerPath: "phoneApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseUrl}/phone`,
    prepareHeaders: (headers) => {
      const token = getTokenFromLocalStorage();
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["Phone"],
  endpoints: (builder) => ({
    createCode: builder.mutation({
      query: (data) => ({
        url: "/",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Phone"],
    }),
    verifyCode: builder.mutation({
      query: (data) => ({
        url: `/verify`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Phone"],
    }),
  }),
});
export const { useCreateCodeMutation, useVerifyCodeMutation } = phoneApi;
