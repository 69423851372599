import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { branchApi } from "../services/branchApi";
import { doctorApi } from "../services/doctorApi";
import { faqApi } from "../services/faqApi";
import { testimonialApi } from "../services/testimonialsApi";
import { newsApi } from "../services/newsApi";
import { homeApi } from "../services/homeApi";
import { userApi } from "../services/userApi";
import { awardApi } from "../services/awardApi";
import { serviceApi } from "../services/serviceApi";
import { phoneApi } from "../services/phoneApi";
import { leadApi } from "../services/leadApi";
import { appointmentApi } from "../services/appointmentApi";

export const store = configureStore({
  reducer: {
    [branchApi.reducerPath]: branchApi.reducer,
    [doctorApi.reducerPath]: doctorApi.reducer,
    [faqApi.reducerPath]: faqApi.reducer,
    [homeApi.reducerPath]: homeApi.reducer,
    [testimonialApi.reducerPath]: testimonialApi.reducer,
    [newsApi.reducerPath]: newsApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [awardApi.reducerPath]: awardApi.reducer,
    [serviceApi.reducerPath]: serviceApi.reducer,
    [phoneApi.reducerPath]: phoneApi.reducer,
    [leadApi.reducerPath]: leadApi.reducer,
    [appointmentApi.reducerPath]: appointmentApi.reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(branchApi.middleware)
      .concat(doctorApi.middleware)
      .concat(faqApi.middleware)
      .concat(homeApi.middleware)
      .concat(testimonialApi.middleware)
      .concat(newsApi.middleware)
      .concat(userApi.middleware)
      .concat(awardApi.middleware)
      .concat(serviceApi.middleware)
      .concat(phoneApi.middleware)
      .concat(leadApi.middleware)
      .concat(appointmentApi.middleware),
});
setupListeners(store.dispatch);
