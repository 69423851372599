import React from "react";
import TopSection from "./TopSection";
import BottomSection from "./BottomSection";

const TreatSection = () => {
  return (
    <div>
      <TopSection />
      <BottomSection />
    </div>
  );
};

export default TreatSection;
