import React, { useEffect, useState } from "react";
import { Dialog } from "@mui/material";
import { MdOutlineClose } from "react-icons/md";
import { toast } from "react-toastify";
import { useUpdateTestimonialMutation } from "../../../services/testimonialsApi";
import BackDropComponent from "../../Common/BackDropComponent";

const EditTestimonialModal = ({ row, showEditableModal, setEditableModal }) => {
  const [patientName, setPatientName] = useState("");
  const [patientFrom, setPatientFrom] = useState("");
  const [overview, setOverview] = useState("");
  const [ytLink, setYtLink] = useState("");
  const [updateTestimonial] = useUpdateTestimonialMutation();
  const [showBackdrop, setBackdrop] = useState(false);
  useEffect(() => {
    setPatientName(row?.name);
    setPatientFrom(row?.address);
    setOverview(row?.overview?.join("\n"));
    setYtLink(row?.videoLink);
  }, [row]);

  const handleSave = () => {
    if (patientName && patientFrom && overview && ytLink) {
      const data = {
        name: patientName,
        address: patientFrom,
        overview: overview,
        videoLink: ytLink,
      };
      setBackdrop(true);
      updateTestimonial({ id: row?.id, data: data }).then((res) => {
        if (res?.data?.status) {
          setPatientName("");
          setPatientFrom("");
          setOverview("");
          setYtLink("");
          toast.success("Successfully updated");
          setEditableModal(false);
          setBackdrop(false);
        } else {
          toast.error("Something went wrong!");
          setBackdrop(false);
        }
      });
    } else {
      toast.error("Please fullfill all information!");
    }
  };

  return (
    <Dialog maxWidth="lg" open={showEditableModal}>
      <div className="flex flex-col gap-[2rem] w-[100%] md:w-[600px] bg-white px-[2rem] py-[3rem] relative">
        {/* close modal button  */}
        <div className="absolute top-[.8rem] right-[1rem]">
          <MdOutlineClose
            onClick={() => setEditableModal(false)}
            className="text-[1.5rem] text-red-500 cursor-pointer"
          />
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 items-center gap-[.5rem]">
          <div className="flex flex-col">
            <label className="text-[13px]" htmlFor="patientName">
              Patient Name
            </label>
            <input
              type="text"
              className="w-[100%] h-[48px] p-[1rem] border focus:outline-none"
              placeholder="Banner Heading"
              name="patientName"
              value={patientName}
              onChange={(e) => setPatientName(e.target.value)}
            />
          </div>
          <div className="flex flex-col">
            <label className="text-[13px]" htmlFor="patient_from">
              Patient From
            </label>
            <input
              type="text"
              className="w-[100%] h-[48px] p-[1rem] border focus:outline-none"
              placeholder="Banner Text"
              name="patient_from"
              value={patientFrom}
              onChange={(e) => setPatientFrom(e.target.value)}
            />
          </div>
        </div>
        <div>
          <textarea
            className="border w-[100%] focus:outline-none p-[1rem]"
            name="overview"
            id="overview"
            cols="30"
            rows="10"
            placeholder="Overview"
            onChange={(e) => setOverview(e.target.value)}
            value={overview}
          ></textarea>
        </div>
        <div className="flex flex-col">
          <label className="text-[13px]" htmlFor="ytLink">
            Youtube Link
          </label>
          <input
            type="text"
            className="w-[100%] h-[48px] p-[1rem] border focus:outline-none"
            placeholder="Banner Heading"
            name="ytLink"
            value={ytLink}
            onChange={(e) => setYtLink(e.target.value)}
          />
        </div>
        <div>
          <button
            onClick={handleSave}
            className="w-[100%] h-[48px] bg-[#2796ac] hover:bg-[#1a778a] text-white uppercase"
          >
            Save
          </button>
        </div>
      </div>
      <BackDropComponent state={showBackdrop} />
    </Dialog>
  );
};

export default EditTestimonialModal;
