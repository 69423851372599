import React from "react";
import { FaArrowRight } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";

const TopSection = ({ data: DoctorAboutData }) => {
  const navigate = useNavigate();
  return (
    <div className="w-[100%] flex flex-col lg:flex-row gap-[3rem]">
      {/* ///// left image ///// */}
      <img
        className="w-[220px] h-[280px] md:w-[245px] md:h-[307px] object-cover"
        src={DoctorAboutData?.image}
        alt=""
      />
      {/* ///// text side ///// */}
      <div className="grow">
        <h1 className="text-[2rem] text-[#0a2a6c] font-semibold">
          {DoctorAboutData?.doctorName}
        </h1>
        <h5 className="text-[#384f7e] text-[1.375rem]">
          {DoctorAboutData?.role}
        </h5>
        <h6 className="text-[#6e81a8] mt-[.5rem]">
          {DoctorAboutData?.subRole}
        </h6>
        <div className="w-[100%] grid grid-cols-1 xl:grid-cols-3 items-center gap-[2rem] xl:gap-0">
          <div className="w-[100%] col-span-2 grid grid-cols-2 gap-[2rem] mt-[1rem]">
            <div>
              <h6 className="text-[1.125rem] text-[#0a2a6c] font-semibold uppercase">
                Speciality
              </h6>
              <p className="text-[#6e81a8] ">{DoctorAboutData?.speciality}</p>
            </div>
            <div>
              <h6 className="text-[1.125rem] text-[#0a2a6c] font-semibold uppercase">
                HOSPITAL
              </h6>
              <p className="text-[#6e81a8] ">
                Avis Hospital, {DoctorAboutData?.hospital}
              </p>
            </div>
            <div>
              <h6 className="text-[1.125rem] text-[#0a2a6c] font-semibold uppercase">
                Languages
              </h6>
              <p className="text-[#6e81a8] ">{DoctorAboutData?.languages}</p>
              <div className="mt-[20px]">
                <button
                  onClick={() =>
                    navigate(`/appointment/${DoctorAboutData?.doctorName?.split(' ').join('-')}`)
                  }
                  className="bg-[#2d7bc9] text-white px-[20px] rounded py-[12px] hover:bg-[#0653a2]"
                >
                  Book An Appointment{" "}
                  <FaArrowRight className="text-white inline ms-[10px]" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopSection;
