import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl } from "./api";
const getTokenFromLocalStorage = () => {
  return localStorage.getItem("token");
};
export const serviceApi = createApi({
  reducerPath: "serviceApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseUrl}/services`,
    prepareHeaders: (headers) => {
      const token = getTokenFromLocalStorage();
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["Service"],
  endpoints: (builder) => ({
    services: builder.query({
      query: ({ page, perPage }) => `/?page=${page}&perPage=${perPage}`,
      providesTags: ["Service"],
    }),
    getServiceById: builder.query({
      query: (id) => `/${id}`,
      providesTags: ["Service"],
    }),
    getServiceByName: builder.query({
      query: (name) => `/getServiceByName/${name}`,
      providesTags: ["Service"],
    }),
    addService: builder.mutation({
      query: (data) => ({
        url: "/",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Service"],
    }),
    deleteServiceById: builder.mutation({
      query: (id) => ({
        url: `/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Service"],
    }),
    updateService: builder.mutation({
      query: ({ id, data }) => ({
        url: `/${id}`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["Service"],
    }),
  }),
});
export const {
  useServicesQuery,
  useAddServiceMutation,
  useGetServiceByIdQuery,
  useUpdateServiceMutation,
  useDeleteServiceByIdMutation,
  useGetServiceByNameQuery,
} = serviceApi;
