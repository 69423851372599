// import React from "react";
// import NavBar from "../Components/Common/NavBar";
// import Footer from "../Components/Footer/Footer";
// import SkeletonComponent from "../Components/Common/SkeletonComponent";
// import { Button, CardFooter, Typography } from "@material-tailwind/react";
// import { useTestimonialsQuery } from "../services/testimonialsApi";
// import Loading from "../Components/Common/Loading";
// import PatientAllCard from "../Components/Common/PatientAllCard";

// const AllTestimonials = () => {
//   const [currentPage, setCurrentPage] = React.useState(1);
//   const { data, isLoading } = useTestimonialsQuery({
//     page: currentPage,
//     perPage: 6,
//   });

//   return (
//     <div>
      
//       <NavBar />
//       {/* ///// page components ///// */}
//       <div className="bg-[whitesmoke] px-[20px] xl:px-0 py-[3rem]">
//         {/* ///// heading ///// */}
//         <div className="max-w-[1140px] mx-auto">
//           <h1 className="text-[1.875rem] lg:text-[2.875rem] font-semibold text-[#0a2a6c]">
//             Testimonials
//           </h1>
//           <div className="flex justify-between items-center">
//             <div className="max-w-[915px] text-[#384f7e]">
//               <p className="leading-[1.5rem]">
//                 Every month, countless patients walk out of our facilities free
//                 from the pain they once carried. Their success stories inspire
//                 our boundless pursuit of excellence.
//               </p>
//             </div>
//           </div>
//         </div>
//         {/* ////// all testimonials //// */}
//         <div>
//           {isLoading ? (
//             <Loading></Loading>
//           ) : (
//             <>
//               <div>
//                 <div className="max-w-[1140px] mx-auto py-[3rem] px-[20px] xl:px-0">
//                   {/* ///// ALL TESTIMONIALS ///// */}
//                   <div className="grid grid-cols-1 gap-y-[50px] min-h-[60vh]">
//                     {data?.data?.length
//                       ? data?.data?.map((el) => (
//                           <div>
//                             <PatientAllCard el={el} />
//                           </div>
//                         ))
//                       : [1, 2, 3, 4, 5].map((el) => <SkeletonComponent />)}
//                   </div>

//                   {/* ///// PAGINATION OPTIONS ///// */}
//                   <CardFooter className="flex items-center justify-between p-4 mt-[3rem]">
//                     <Typography
//                       variant="small"
//                       color="blue-gray"
//                       className="font-[500] whitespace-nowrap mr-[1rem] text-[1rem]"
//                     >
//                       Page {currentPage} of {data?.totalPages}
//                     </Typography>
//                     <div className="flex gap-2">
//                       {currentPage > 1 ? (
//                         <Button
//                           onClick={() => setCurrentPage(currentPage - 1)}
//                           variant="outlined"
//                           size="sm"
//                         >
//                           Previous
//                         </Button>
//                       ) : (
//                         <Button disabled variant="outlined" size="sm">
//                           Previous
//                         </Button>
//                       )}
//                       {currentPage < data?.totalPages ? (
//                         <Button
//                           onClick={() => setCurrentPage(currentPage + 1)}
//                           variant="outlined"
//                           size="sm"
//                         >
//                           Next
//                         </Button>
//                       ) : (
//                         <Button disabled variant="outlined" size="sm">
//                           Next
//                         </Button>
//                       )}
//                     </div>
//                   </CardFooter>
//                 </div>
//               </div>
//             </>
//           )}
//         </div>
//       </div>
//       <Footer />
//     </div>
//   );
// };

// export default AllTestimonials;


import React, { useEffect } from "react";
import NavBar from "../Components/Common/NavBar";
import Footer from "../Components/Footer/Footer";
import SkeletonComponent from "../Components/Common/SkeletonComponent";
import { Button, CardFooter, Typography } from "@material-tailwind/react";
import { useTestimonialsQuery } from "../services/testimonialsApi";
import Loading from "../Components/Common/Loading";
import PatientAllCard from "../Components/Common/PatientAllCard";

const AllTestimonials = () => {
  const [currentPage, setCurrentPage] = React.useState(1);
  const { data, isLoading } = useTestimonialsQuery({
    page: currentPage,
    perPage: 6,
  });

  useEffect(() => {
    document.title = "Patient Testimonials - Avis Hospital ";
  }, []);

  return (
    <div>
      <NavBar />
      <div className="bg-[whitesmoke] px-[20px] xl:px-0 py-[3rem]">
        <div className="max-w-[1140px] mx-auto">
          <h1 className="text-[1.875rem] lg:text-[2.875rem] font-semibold text-[#0a2a6c]">
            Testimonials
          </h1>
          <div className="flex justify-between items-center">
            <div className="max-w-[915px] text-[#384f7e]">
              <p className="leading-[1.5rem]">
                Every month, countless patients walk out of our facilities free
                from the pain they once carried. Their success stories inspire
                our boundless pursuit of excellence.
              </p>
            </div>
          </div>
        </div>
        <div>
          {isLoading ? (
            <Loading />
          ) : (
            <div className="max-w-[1140px] mx-auto py-[3rem] px-[20px] xl:px-0">
              <div className="grid grid-cols-1 gap-y-[50px] min-h-[60vh]">
                {data?.data?.length ? (
                  data?.data?.map((el, index) => (
                    <div key={index}>
                      <PatientAllCard el={el} />
                    </div>
                  ))
                ) : (
                  [1, 2, 3, 4, 5].map((el, index) => <SkeletonComponent key={index} />)
                )}
              </div>
              <CardFooter className="flex items-center justify-between p-4 mt-[3rem]">
                <Typography
                  variant="small"
                  color="blue-gray"
                  className="font-[500] whitespace-nowrap mr-[1rem] text-[1rem]"
                >
                  Page {currentPage} of {data?.totalPages}
                </Typography>
                <div className="flex gap-2">
                  <Button
                    onClick={() => setCurrentPage(currentPage > 1 ? currentPage - 1 : 1)}
                    variant="outlined"
                    size="sm"
                    disabled={currentPage === 1}
                  >
                    Previous
                  </Button>
                  <Button
                    onClick={() => setCurrentPage(currentPage < data?.totalPages ? currentPage + 1 : data?.totalPages)}
                    variant="outlined"
                    size="sm"
                    disabled={currentPage === data?.totalPages}
                  >
                    Next
                  </Button>
                </div>
              </CardFooter>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AllTestimonials;
