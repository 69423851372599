import React from "react";

const FacebookIconComp = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="7.949"
      height="17.902"
      viewBox="0 0 7.949 17.902"
    >
      <path
        id="facebook_copy"
        data-name="facebook copy"
        d="M1592.653,5867.957h-2.4v8.944H1586.7v-8.944h-1.689V5864.8h1.689v-2.044c0-1.462.665-3.753,3.583-3.753l2.633.014v3.067h-1.909c-.315,0-.755.162-.755.86v1.859h2.708Zm0,0"
        transform="translate(-1585.014 -5858.999)"
      />
    </svg>
  );
};

export default FacebookIconComp;
