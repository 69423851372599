import hospitalIcon from "../Assets/Icons/our-hospital-live.svg";
import specialityIcon from "../Assets/Icons/our-speciality.svg";
import calenderIcon from "../Assets/Icons/calendar.svg";
import patientIcon from "../Assets/Icons/patient.svg";
import callIcon from "../Assets/Icons/call-icon-new.svg";
import menuIcon from "../Assets/Icons/menu.svg";
import arrowIcon from "../Assets/Icons/next-arrow.svg";
import globeIcon from "../Assets/Icons/globe.svg";
import ambulanceIcon from "../Assets/Icons/ambulance-new.svg";
import helplineIcon from "../Assets/Icons/helpline-hospital.svg";
import facebookIcon from "../Assets/Icons/facebook.svg";
import instagramIcon from "../Assets/Icons/instagram.svg";
import twitterIcon from "../Assets/Icons/twitter.svg";
import youtubeIcon from "../Assets/Icons/youtube.svg";
import linkedinIcon from "../Assets/Icons/linkedin.svg";
import starIcon from "../Assets/Icons/star.svg";
import careIcon from "../Assets/Icons/care.svg";
import heartIcon from "../Assets/Icons/heart.svg";
import planeIcon from "../Assets/Icons/plane.png";
import busIcon from "../Assets/Icons/bus.png";
import trainIcon from "../Assets/Icons/train.png";
import smartphoneIcon from "../Assets/Icons/smartphone.svg";
import askedIcon from "../Assets/Icons/asked.svg";
import newsletterIcon from "../Assets/Icons/newsletter.svg";
import keralaIcon from "../Assets/Icons/kerla.svg";
import maharashtraIcon from "../Assets/Icons/maharashtra.svg";
import bangaloreIcon from "../Assets/Icons/bangalore.svg";
import telenganaIcon from "../Assets/Icons/telangana.svg";
import andhraPradeshIcon from "../Assets/Icons/andra-predesh.svg";
import ourClinicIcon from "../Assets/Icons/our-clinic.svg";
import badgeIcon from "../Assets/Icons/badge.svg";
import stethoscopeIcon from "../Assets/Icons/stethoscope.svg";
import expandArrow from "../Assets/Icons/up-down-arrow.svg";

// icons
const icons = {
  hospitalIcon,
  specialityIcon,
  calenderIcon,
  patientIcon,
  callIcon,
  menuIcon,
  arrowIcon,
  globeIcon,
  ambulanceIcon,
  helplineIcon,
  facebookIcon,
  instagramIcon,
  twitterIcon,
  youtubeIcon,
  linkedinIcon,
  starIcon,
  careIcon,
  heartIcon,
  planeIcon,
  busIcon,
  trainIcon,
  smartphoneIcon,
  askedIcon,
  newsletterIcon,
  keralaIcon,
  maharashtraIcon,
  bangaloreIcon,
  telenganaIcon,
  andhraPradeshIcon,
  ourClinicIcon,
  badgeIcon,
  stethoscopeIcon,
  expandArrow,
};

export default icons;
