// 

import React from "react";
import moment from "moment";

const Section = ({ data }) => {
  return (
    <div className="max-w-[1140px] mx-auto py-[1rem] md:py-[2rem] xl:py-[3rem] px-[20px] xl:px-0">
      <h1 className="text-[1.2rem] lg:text-[2.375rem] text-[#0a2a6c] font-bold">
        {data?.heading}
      </h1>
      <div className="grid grid-cols-1 xl:grid-cols-1 gap-[2rem] mt-[1rem]">
        <div className="w-[100%]">
          <img className="w-[100%] md:h-[350px] lg:h-[400px] xl:h-[550px] " src={data?.image} alt="banner" />
        </div>
        <div>
          <div className="style-tags @apply" dangerouslySetInnerHTML={{ __html: data?.overview }} />
          <h4 className="mt-[1rem] text-[#0a2a6c] font-semibold">
            {data?.writer}
          </h4>
          <p className="text-[13px] text-[#6e81a8]">
            {moment(data?.createdAt).format("MMMM Do YYYY, h:mm:ss a")}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Section;
