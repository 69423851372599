import React, { useEffect, useState } from "react";
import { useBranchesQuery } from "../../services/branchApi";
import { Link } from "react-router-dom";
import { FaLocationDot } from "react-icons/fa6";
const OurBranchesOptions = () => {
  const { data } = useBranchesQuery({ page: "00", perPage: "00" });
  const [states, setStates] = useState();

  useEffect(() => {
    if (data?.data?.length > 0) {
      const uniqueData = data?.data?.filter(
        (item, index, self) =>
          self.findIndex((otherItem) => otherItem.name === item.name) === index
      );
      const sortedData = uniqueData?.sort((a, b) =>
        a.name.localeCompare(b.name)
      );
      const statesData = [];
      for (const i of sortedData) {
        const stateData = [];
        for (const j of data?.data) {
          if (i?.name === j?.name) {
            stateData.push(j);
          }
        }
        statesData.push({ name: i.name, cities: stateData });
      }
      setStates(
        statesData?.sort((a, b) => b?.cities?.length - a?.cities?.length)
      );
    }
  }, [data?.data, data?.data?.length]);

  const array1 = states?.slice(0, states?.length / 3);
  const array2 = states?.slice(states?.length / 3, (2 * states?.length) / 3);
  const array3 = states?.slice((2 * states?.length) / 3);

  return (
    <div className="grid grid-cols-1 md:grid-cols-3">
      {/* ///// first loop ///// */}
      <div className="h-[100%] w-[100%] overflow-y-auto flex flex-col gap-[1rem] px-[38px] py-[15px] lg:py-[45px]">
        {array1?.map((el) => (
          <div>
            <div className="flex items-center gap-[5px] text-white">
              <FaLocationDot
                style={{ color: "white" }}
                className="h-[17px] w-[17px]"
              />
              <p className="text-[17px] font-semibold mb-0 pb-0 mt-[5px] ">
                {el?.name}
              </p>
            </div>
            <div className="flex flex-col gap-[8px]">
              {el?.cities?.map((link) => (
                <Link
                  to={`/branch/${link?.name.split(' ').join('-')}/${link?.city.split(' ').join('-')}`}
                  className="text-white text-[14px]"
                >
                  {link?.city}
                </Link>
              ))}
            </div>
          </div>
        ))}
      </div>
      {/* ///// second loop ///// */}
      <div className="h-[100%] w-[100%] overflow-y-auto flex flex-col gap-[1rem] px-[38px]  py-[15px] lg:py-[45px]">
        {array2?.map((el) => (
          <div>
            <div className="flex items-center gap-[5px] text-white">
              <FaLocationDot
                style={{ color: "white" }}
                className="h-[17px] w-[17px]"
              />
              <p className="text-[17px] font-semibold mb-0 pb-0 mt-[5px] ">
                {el?.name}
              </p>
            </div>
            <div className="flex flex-col gap-[8px]">
              {el?.cities?.map((link) => (
                <Link
                  to={`/branch/${link?.name.split(' ').join('-')}/${link?.city.split(' ').join('-')}`}
                  className="text-white text-[14px]"
                >
                  {link?.city}
                </Link>
              ))}
            </div>
          </div>
        ))}
      </div>
      {/* ///// third loop ///// */}
      <div className="h-[100%] w-[100%] overflow-y-auto flex flex-col gap-[1rem] px-[38px]  py-[15px] lg:py-[45px]">
        {array3?.map((el) => (
          <div>
            <div className="flex items-center gap-[5px] text-white">
              <FaLocationDot
                style={{ color: "white" }}
                className="h-[17px] w-[17px]"
              />
              <p className="text-[17px] font-semibold mb-0 pb-0 mt-[5px] ">
                {el?.name}
              </p>
            </div>
            <div className="flex flex-col gap-[8px]">
              {el?.cities?.map((link) => (
                <Link
                  to={`/branch/${link?.name.split(' ').join('-')}/${link?.city.split(' ').join('-')}`}
                  className="text-white text-[14px]"
                >
                  {link?.city}
                </Link>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OurBranchesOptions;
