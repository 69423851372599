import React, { useState } from "react";
import Sidebar from "./Sidebar";
import { Outlet } from "react-router-dom";
import Header from "./Header";

const Layout = () => {
  const [showFull, setShowFull] = useState(false);
  const [mobileNav, setMobileNav] = useState(true);
  return (
    <div>
      <div className="relative z-[10]">
        <Sidebar showFull={showFull} setShowFull={setShowFull} mobileNav={mobileNav} setMobileNav={setMobileNav}></Sidebar>
      </div>
      <div className={`${showFull ? "ml-[70px]" : "ml-[70px] lg:ml-[300px]"}`}>
        <Header />
        <Outlet />
      </div>
    </div>
  );
};

export default Layout;
