import React from "react";
import icons from "../../utils/icons";

const FollowUsComp = () => {
  return (
    <div className="flex items-center gap-[2rem]">
      <p className="text-[.875rem] text-[#dedede]">Follow Us</p>
      <div className="flex items-center gap-[2rem]">
        <a target="_blank" href="https://www.facebook.com/AvisVascularCentre" rel="noreferrer">
          <img src={icons.facebookIcon} alt="" />
        </a>
        <a target="_blank" href="https://www.instagram.com/avishospitals/" rel="noreferrer">
          <img src={icons.instagramIcon} alt="" />
        </a>
        <a target="_blank" href="https://www.youtube.com/channel/UCDHD4EJh3hMu3Znx3eSDdTA" rel="noreferrer">
          <img src={icons.youtubeIcon} alt="" />
        </a>
      </div>
    </div>
  );
};

export default FollowUsComp;
