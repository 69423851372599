import React from "react";
import {  HiOutlineMail } from "react-icons/hi";

const LeftSide = ({ data }) => {
  return (
    <div className="w-[100%] lg:w-[30%]">
      <div className="w-[100%]">
        <img
          className="max-w-[100%] h-[313px] object-cover"
          src={data?.img}
          alt="doctor_image"
        />
      </div>
      <div className="pt-[28px] px-[30px] pb-[40px] bg-[#f4f9fd] border-[1px] border-[#dfeaf2]">
        <div>
          <h1 className="text-[24px] font-bold text-[#193a5e] pb-[.2rem] border-b-[1px] border-[#dfeaf2]">
            Dr. Rajah V Koppala
          </h1>
        </div>
        <div className="pt-[1rem]">
          <h2 className="text-[20px] font-bold text-[#193a5e]">
            Qualification
          </h2>
          <p className="text-[14px] mt-[10px]">{data?.overview}</p>
        </div>
        <div className="pt-[2rem]">
          <h2 className="text-[20px] font-bold text-[#193a5e]">Contact Info</h2>
          <ul className="list-disc list-inside mt-[1rem] text-[#193a5e] font-[600]">
            <li className="flex items-center gap-[10px]">
              <HiOutlineMail />
              <span>{data?.contact?.email}</span>
            </li>
          </ul>
        </div>
      </div>
      <div className="pt-[28px] px-[30px] pb-[40px] bg-[#f4f9fd] border-[1px] border-[#dfeaf2] mt-[30px]">
        {data?.avaibilities?.map((el) => (
          <div className="flex items-center justify-between text-[14px] text-[#0653a2] py-[10px] border-b-[1px] border-[#dfeaf2]">
            <h6>{el?.days}</h6>
            <h6>{el?.time}</h6>
          </div>
        ))}
      </div>
    </div>
  );
};

export default LeftSide;
