// import React from "react";
// import NavBar from "../Components/Common/NavBar";
// import Footer from "../Components/Footer/Footer";
// import SkeletonComponent from "../Components/Common/SkeletonComponent";
// import DoctorTalk from "../Components/DoctorTalk/DoctorTalk";

// const DoctorTalks = () => {
//   const data = [
//     {
//       doctorName: "Dr. Rajah V Koppala",
//       qualification: "Varicose Veins Specialist (Vascular & Interventional Radiologist)",
//       videoLink: "https://youtu.be/IUC6KPcEti8",
//     },
//     {
//       doctorName: "Dr. Manimaran Govindaraju",
//       qualification: "Laparoscopic And Endovascular Surgeon",
//       videoLink: "https://youtu.be/ITT00-4tG7Q",
//     },
//     {
//       doctorName: "Dr. Sampath Vadithya",
//       qualification: "Varicose Veins Specialist (Vascular & Interventional Radiologist)",
//       videoLink: "https://youtu.be/U3nsF0Zhk-4",
//     },
//     {
//       doctorName: "Dr. Sourav Chakroborty",
//       qualification: "Varicose Veins Specialist (Vascular General Surgeon)",
//       videoLink: "https://youtu.be/idwAniOKo1w",
//     },
//     {
//       doctorName: "Dr. Vijaya Kumar",
//       qualification: "Varicose Veins Specialist (General Surgeon)",
//       videoLink: "https://youtu.be/ZKcHMJuP2tI",
//     },
//     {
//       doctorName: "Dr. Rajah V Koppala",
//       qualification: "Varicose Veins Specialist (Vascular & Interventional Radiologist)",
//       videoLink: "https://youtu.be/UuUS1pnayhM",
//     },
//     {
//       doctorName: "Dr. Kannan Gunasekharan",
//       qualification: "Vascular & Interventional Radiologist",
//       videoLink: "https://youtu.be/yWqxEZaT3Nc",
//     },
//     {
//       doctorName: "Dr. Abhinav Kesarwani",
//       qualification: "Varicose Veins Specialist (General Surgeon)",
//       videoLink: "https://youtu.be/XhXtoMYZxUA",
//     },
//   ];



//   return (
//     <div>
      
//       <NavBar />
//       {/* ///// page components ///// */}
//       <div className="bg-[whitesmoke] px-[20px] xl:px-0 py-[3rem]">
//         {/* ///// heading ///// */}
//         <div className="max-w-[1140px] mx-auto">
//           <h1 className="text-[1.875rem] lg:text-[2.875rem] font-semibold text-[#0a2a6c]">
//             Doctor Talks
//           </h1>
//           <div className="flex justify-between items-center">
//             <div className="max-w-[915px] text-[#384f7e]">
//               <p className="leading-[1.5rem]">
//                 Every month, countless patients walk out of our facilities free
//                 from the pain they once carried. Their success stories inspire
//                 our boundless pursuit of excellence.
//               </p>
//             </div>
//           </div>
//         </div>
//         {/* ////// all testimonials //// */}
//         <div>
//           <>
//             <div>
//               <div className="max-w-[1140px] mx-auto py-[3rem] px-[20px] xl:px-0">
//                 {/* ///// ALL TESTIMONIALS ///// */}
//                 <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 justify-items-center gap-y-[50px] min-h-[60vh]">
//                   {data?.length > 0
//                     ? data?.map((el) => (
//                         <div>
//                           <DoctorTalk el={el} />
//                         </div>
//                       ))
//                     : [1, 2, 3, 4, 5].map((el) => <SkeletonComponent />)}
//                 </div>
//               </div>
//             </div>
//           </>
//         </div>
//       </div>
//       <Footer />
//     </div>
//   );
// };

// export default DoctorTalks;

import React, { useEffect } from "react";
import NavBar from "../Components/Common/NavBar";
import Footer from "../Components/Footer/Footer";
import SkeletonComponent from "../Components/Common/SkeletonComponent";
import DoctorTalk from "../Components/DoctorTalk/DoctorTalk";

const DoctorTalks = () => {
  const data = [
    {
      doctorName: "Dr. Rajah V Koppala",
      qualification: "Varicose Veins Specialist (Vascular & Interventional Radiologist)",
      videoLink: "https://youtu.be/IUC6KPcEti8",
    },
    {
      doctorName: "Dr. Manimaran Govindaraju",
      qualification: "Laparoscopic And Endovascular Surgeon",
      videoLink: "https://youtu.be/ITT00-4tG7Q",
    },
    {
      doctorName: "Dr. Sampath Vadithya",
      qualification: "Varicose Veins Specialist (Vascular & Interventional Radiologist)",
      videoLink: "https://youtu.be/U3nsF0Zhk-4",
    },
    {
      doctorName: "Dr. Sourav Chakroborty",
      qualification: "Varicose Veins Specialist (Vascular General Surgeon)",
      videoLink: "https://youtu.be/idwAniOKo1w",
    },
   
    {
      doctorName: "Dr. Rajah V Koppala",
      qualification: "Varicose Veins Specialist (Vascular & Interventional Radiologist)",
      videoLink: "https://youtu.be/UuUS1pnayhM",
    },
    {
      doctorName: "Dr. Kannan Gunasekharan",
      qualification: "Vascular & Interventional Radiologist",
      videoLink: "https://youtu.be/yWqxEZaT3Nc",
    },
    {
      doctorName: "Dr. Abhinav Kesarwani",
      qualification: "Varicose Veins Specialist (General Surgeon)",
      videoLink: "https://youtu.be/XhXtoMYZxUA",
    },
  ];

  useEffect(() => {
    document.title = "Expert Insights on Varicose Veins Treatment - Avis Hospital";
  }, []);

  return (
    <div>
      <NavBar />
      <div className="bg-[whitesmoke] px-[20px] xl:px-0 py-[3rem]">
        <div className="max-w-[1140px] mx-auto">
          <h1 className="text-[1.875rem] lg:text-[2.875rem] font-semibold text-[#0a2a6c]">
            Doctor Talks
          </h1>
          <div className="flex justify-between items-center">
            <div className="max-w-[915px] text-[#384f7e]">
              <p className="leading-[1.5rem]">
                Every month, countless patients walk out of our facilities free
                from the pain they once carried. Their success stories inspire
                our boundless pursuit of excellence.
              </p>
            </div>
          </div>
        </div>
        <div>
          <div className="max-w-[1140px] mx-auto py-[3rem] px-[20px] xl:px-0">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 justify-items-center gap-y-[50px] min-h-[60vh]">
              {data?.length > 0
                ? data?.map((el, index) => (
                    <div key={index}>
                      <DoctorTalk el={el} />
                    </div>
                  ))
                : [1, 2, 3, 4, 5].map((el, index) => <SkeletonComponent key={index} />)}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default DoctorTalks;

