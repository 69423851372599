import React from "react";
import ViewAllBtn from "../../Common/ViewAllBtn";

const TopSection = () => {
  return (
    <div className="max-w-[1140px] mx-auto">
      <h1 className="text-[1.875rem] lg:text-[2.875rem] font-semibold text-[#0a2a6c]">
        Testimonials
      </h1>
      <div className="flex justify-between items-center">
        <div className="max-w-[915px] text-[#384f7e]">
          <p className="leading-[1.5rem]">
            Every month, countless patients walk out of our facilities free from
            the pain they once carried. Their success stories inspire our
            boundless pursuit of excellence.
          </p>
        </div>
        <div className="hidden md:block">
          <ViewAllBtn
            text_class={"ester_specialist_view_btn"}
            arrow_class={"ester_specialist_view_arrow"}
            route_link={"/all-testimonials"}
          />
        </div>
      </div>
    </div>
  );
};

export default TopSection;
