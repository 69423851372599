// import React, { useState } from "react";
// import { useSearchParams } from "react-router-dom";
// import NavBar from "../Components/Common/NavBar";
// import Footer from "../Components/Footer/Footer";
// import Section from "../Components/News_Events_Blogs/Section";
// import { useGetNewsByNameQuery, useNewsQuery } from "../services/newsApi";
// import NewEvents from "../Components/Home_Page/NewEvents";
// import Loading from "../Components/Common/Loading";

// const NewsEventBlogs = () => {
//   const [language, setLanguage] = useState("All");
//   const [searchParams] = useSearchParams();
//   const paramValue = searchParams.get('search')
//   const { data, isLoading } = useGetNewsByNameQuery(paramValue?.split('_')?.join(' '));
//   const { data: newsData, isLoading: isLoading1 } = useNewsQuery({
//     page: "00",
//     perPage: "00",
//     language: language,
//   });
//   return (
//     <div>
      
//       <NavBar />
//       {isLoading ? (
//         <Loading></Loading>
//       ) : (
//         <>
//           {" "}
//           <Section data={data?.data} />
//           <NewEvents
//             data={newsData?.data}
//             language={language}
//             setLanguage={setLanguage}
//             isLoading={isLoading1}
//           />
//         </>
//       )}
//       <Footer />
//     </div>
//   );
// };

// export default NewsEventBlogs;

import React, { useState, useEffect, useContext } from "react";
import { useSearchParams } from "react-router-dom";
import NavBar from "../Components/Common/NavBar";
import Footer from "../Components/Footer/Footer";
import Section from "../Components/News_Events_Blogs/Section";
import { useGetNewsByNameQuery, useNewsQuery } from "../services/newsApi";
import NewEvents from "../Components/Home_Page/NewEvents";
import Loading from "../Components/Common/Loading";
import InQueryForm from "../Components/Home_Page/InQueryForm";
import { BookFormContext, FirstModalContext } from "../App";
import { Slide } from "react-awesome-reveal";

const NewsEventBlogs = () => {
  const [language, setLanguage] = useState("All");
  const [searchParams] = useSearchParams();
  const paramValue = searchParams.get('search');
  const { data, isLoading } = useGetNewsByNameQuery(paramValue?.split('_')?.join(' '));
  const { data: newsData, isLoading: isLoading1 } = useNewsQuery({
    page: "00",
    perPage: "00",
    language: language,
  });

  const { bookForm } = useContext(BookFormContext);
  const { firstModal, setFirstModal } = useContext(FirstModalContext);
  
  const from = "service"; // Define the 'from' variable

  useEffect(() => {
    if (data && data.data && data.data.heading) {
      document.title = `${data.data.heading} - Avis Vascular Centre`;
      updateMetaTags(data.data);
    } else {
      document.title = "News, Events, and Blogs - Avis Vascular Centre";
      resetMetaTags();
    }
    console.log(data?.data?.previewImage);
  }, [data]);

  const updateMetaTags = (blogData) => {
    const metaTags = [
      { property: "og:title", content: blogData.heading },
      { property: "og:description", content: blogData.description },
      { property: "og:image", content: blogData.previewImage },
      { property: "og:url", content: window.location.href },
    ];

    metaTags.forEach(tag => {
      const metaTag = document.querySelector(`meta[property="${tag.property}"]`);
      if (metaTag) {
        metaTag.setAttribute("content", tag.content);
      } else {
        const newTag = document.createElement("meta");
        newTag.setAttribute("property", tag.property);
        newTag.setAttribute("content", tag.content);
        document.head.appendChild(newTag);
      }
      console.log(`Meta tag updated: ${tag.property} = ${tag.content}`);
    });
  };

  const resetMetaTags = () => {
    const defaultMetaTags = [
      { property: "og:title", content: "News, Events, and Blogs - Avis Vascular Centre" },
      { property: "og:description", content: "Explore the latest news, events, and blogs from Avis Vascular Centre." },
      { property: "og:image", content: "https://example.com/default_image.jpg" }, // Replace with an actual image URL
      { property: "og:url", content: window.location.href },
    ];

    defaultMetaTags.forEach(tag => {
      const metaTag = document.querySelector(`meta[property="${tag.property}"]`);
      if (metaTag) {
        metaTag.setAttribute("content", tag.content);
      } else {
        const newTag = document.createElement("meta");
        newTag.setAttribute("property", tag.property);
        newTag.setAttribute("content", tag.content);
        document.head.appendChild(newTag);
      }
      console.log(`Default meta tag set: ${tag.property} = ${tag.content}`);
    });
  };

  return (
    <div>
      <NavBar />
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <Section data={data?.data} />
          <NewEvents
            data={newsData?.data}
            language={language}
            setLanguage={setLanguage}
            isLoading={isLoading1}
          />
        </>
      )}
      {bookForm && (
        <InQueryForm
          stylesClass={`mt-32 right-0 left-0 top-0 w-[100%] xl:w-[350px] block ${
            from === "service" && "block lg:hidden"
          }`}
          from={"middle"}
        />
      )}
      <InQueryForm
        stylesClass={`mt-32 h-[620px] xl:right-[5%] top-0 w-[100%] xl:w-[300px] inqueryFormhidden ${
          from === "service" && "hidden lg:block"
        }`}
        from={"side"}
      />

      {firstModal && (
        <Slide
          delay={500}
          className="mt-12 w-[100%] top-[133px] xl:translate-y-[-50%] fixed lg:absolute lg:top-[50%] lg:left-0 lg:right-0 z-[1000] block lg:hidden"
        >
          <InQueryForm
            stylesClass={"mt-12 right-0 left-0 top-0 w-[100%] xl:w-[350px] block"}
            from={"middle"}
            from1={"slide"}
            setFirstModal={setFirstModal}
          />
        </Slide>
      )}
      <Footer />
    </div>
  );
};

export default NewsEventBlogs;
