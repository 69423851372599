import React from "react";
import { Link } from "react-router-dom";

const BottomSection = () => {
  return (
    <div className="flex items-center flex-col md:flex-row gap-[1rem] text-[.875rem] text-[#dedede]">
      <p>© 2024 Avis Vascular Centre, Inc. All Rights Reserved.</p>
      <Link to="/privacy-policy" className="text-[#dedede] underline">
        Privacy Policy
      </Link>
    </div>
  );
};

export default BottomSection;
