import React from "react";
import NavBar from "../Components/Common/NavBar";
import Footer from "../Components/Footer/Footer";
import SkeletonComponent from "../Components/Common/SkeletonComponent";
import { Button, CardFooter, Typography } from "@material-tailwind/react";
import { useServicesQuery } from "../services/serviceApi";
import Loading from "../Components/Common/Loading";
import ServiceAllCard from "../Components/Common/ServiceAllCard";

const AllServices = () => {
  const [currentPage, setCurrentPage] = React.useState(1);
  const { data, isLoading } = useServicesQuery({
    page: currentPage,
    perPage: 6,
  });
  return (
    <div>
      
      <NavBar />
      {/* ///// page components ///// */}
      <div className="bg-[whitesmoke] py-[3rem] px-[20px] xl:px-0">
        {/* //// heading //// */}
        <div className="max-w-[1140px] mx-auto">
          <h1 className="text-[1.875rem] lg:text-[2.875rem] font-semibold text-[#0a2a6c]">
          Treatments Offered
          </h1>
          <div className="flex justify-between items-center">
            <div className="max-w-[915px] text-[#384f7e]">
              <p className="leading-[1.5rem]">
                Our internationally-recognized doctors have in-depth expertise
                in minimally-invasive surgical interventions. Our treatments are
                designed for minimum pain and quick recovery.
              </p>
            </div>
            <div className="hidden md:block"></div>
          </div>
        </div>
        {/* //// services //// */}
        <div>
          {isLoading ? (
            <Loading></Loading>
          ) : (
            <>
              <div>
                <div className="max-w-[1140px] mx-auto py-[3rem] px-[20px] xl:px-0 ">
                  {/* ///// ALL SERVICES ///// */}
                  <div className="grid grid-cols-1 gap-y-[50px] min-h-[60vh]">
                    {data?.data?.length
                      ? data?.data?.map((el) => (
                          <div>
                            <ServiceAllCard el={el} />
                          </div>
                        ))
                      : [1, 2, 3, 4, 5].map((el) => <SkeletonComponent />)}
                  </div>

                  {/* ///// PAGINATION OPTIONS ///// */}
                  <CardFooter className="flex items-center justify-between p-4 mt-[3rem]">
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-[500] whitespace-nowrap mr-[1rem] text-[1rem]"
                    >
                      Page {currentPage} of {data?.totalPages}
                    </Typography>
                    <div className="flex gap-2">
                      {currentPage > 1 ? (
                        <Button
                          onClick={() => setCurrentPage(currentPage - 1)}
                          variant="outlined"
                          size="sm"
                        >
                          Previous
                        </Button>
                      ) : (
                        <Button disabled variant="outlined" size="sm">
                          Previous
                        </Button>
                      )}
                      {currentPage < data?.totalPages ? (
                        <Button
                          onClick={() => setCurrentPage(currentPage + 1)}
                          variant="outlined"
                          size="sm"
                        >
                          Next
                        </Button>
                      ) : (
                        <Button disabled variant="outlined" size="sm">
                          Next
                        </Button>
                      )}
                    </div>
                  </CardFooter>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AllServices;
