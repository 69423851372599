import React, { useEffect, useState } from "react";
import { Dialog } from "@mui/material";
import { MdOutlineClose } from "react-icons/md";
import SelectRole from "./SelectRole";
import { toast } from "react-toastify";

const EditableRowModal = ({ row, showEditableModal, setEditableModal }) => {
  const [userEmail, setUserEmail] = useState("");
  const [userRole, setRole] = useState("");
  useEffect(() => {
    setUserEmail(row?.email);
    setRole(row?.role);
  }, [row]);
  // UPDATE ROW FUNCTION
  const handleSave = () => {
    if (userEmail && userRole) {
      //console.log("TRYING TO UPDATE ROW", userEmail, userRole);
    } else {
      toast.error("Please fullfill user info!");
    }
  };

  return (
    <Dialog maxWidth="lg" open={showEditableModal}>
      <div className="flex flex-col gap-[2rem] w-[100%] md:w-[600px] bg-white px-[2rem] py-[3rem] relative">
        {/* close modal button  */}
        <div className="absolute top-[.8rem] right-[1rem]">
          <MdOutlineClose
            onClick={() => setEditableModal(false)}
            className="text-[1.5rem] text-red-500 cursor-pointer"
          />
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 items-center gap-[.5rem]">
          <div className="flex flex-col">
            <input
              type="emaiol"
              className="w-[100%] h-[40px] p-[1rem] border focus:outline-none"
              placeholder="User Email"
              name="userEmail"
              value={userEmail}
              onChange={(e) => setUserEmail(e.target.value)}
            />
          </div>
          <div className="flex flex-col">
            <div className="w-[100%]">
              <SelectRole userRole={userRole} setRole={setRole} />
            </div>
          </div>
        </div>
        <div>
          <button
            onClick={handleSave}
            className="w-[100%] h-[48px] bg-[#2796ac] hover:bg-[#1a778a] text-white uppercase"
          >
            Save
          </button>
        </div>
      </div>
    </Dialog>
  );
};

export default EditableRowModal;
