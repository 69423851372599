import React, { useEffect, useState } from "react";
import { Dialog } from "@mui/material";

import { BsImage } from "react-icons/bs";
import { MdOutlineClose } from "react-icons/md";
import SelectState from "../Manage_Branches/SelectState";
import SelectCity from "../Manage_Branches/SelectCity";
import { useUpdateHomeMutation } from "../../../services/homeApi";
import { SuccessAlertFunction } from "../../Common/SuccessAlert";
import { FailedAlertFunction } from "../../Common/FailedAlertFunction";
import BackDropComponent from "../../Common/BackDropComponent";
import { IoIosCloseCircle } from "react-icons/io";
import axios from "axios";

const EditHomeModal = ({ row, showEditableModal, setEditableModal }) => {
  const [state, setState] = useState({});
  const [city, setCity] = useState("");
  const [updateHome] = useUpdateHomeMutation();
  const [overview, setOverview] = useState("");
  const [email, setEmail] = useState("");
  const [emergancy, setEmergency] = useState("");
  const [helpline, setHelpline] = useState("");
  const [image, setImage] = useState("");
  const [branchName, setBranchName] = useState("");
  const [banners, setBanners] = useState([]);
  const [showBackdrop, setBackdrop] = useState(false);
  useEffect(() => {
    setState(row?.name);
    setCity(row?.city);
    setOverview(row?.overview);
    setEmail(row?.email);
    setEmergency(row?.emergency);
    setHelpline(row?.helpline);
    setImage(row?.image);
    setBranchName(row?.branchName);
    setBanners(row?.banners);
    //console.log("checking row", row);
  }, [row]);
  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      setBackdrop(true);
      const file = e.target.files[0];
      const formData = new FormData();
      formData.append("file", file);
      formData.append("upload_preset", process.env.REACT_APP_PRESET);
      try {
        setBackdrop(true);
        const res = await axios.post(
          `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDNAME}/image/upload`,
          formData
        );
        setBackdrop(false);
        setImage(res.data.secure_url);
      } catch (error) {
        setBackdrop(false);
        console.log(error.message);
      }
    }
  };
  const handleSave = (e) => {
    e.preventDefault();
    const data = {
      name: state,
      city,
      overview,
      email,
      emergancy,
      helpline,
      image,
      branchName,
      banners,
    };
    setBackdrop(true);

    updateHome({ id: row?._id, data: data }).then((res) => {
      if (res?.data?.status) {
        setEmail("");
        setCity("");
        setHelpline("");
        setEmergency("");
        setState("");
        setCity("");
        setOverview("");
        setBranchName("");
        setBanners([]);
        setBackdrop(false);
        setEditableModal(false);
        SuccessAlertFunction(res?.data?.message);
      } else {
        FailedAlertFunction(res?.data?.message);
        setBackdrop(false);
      }
    });
  };

  const handleBannersUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("upload_preset", process.env.REACT_APP_PRESET);
      try {
        setBackdrop(true);
        const res = await axios.post(
          `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDNAME}/image/upload`,
          formData
        );
        setBackdrop(false);
        setBanners([
          ...banners,
          {
            bannerImage: res?.data?.secure_url,
          },
        ]);
      } catch (error) {
        setBackdrop(false);
        console.log(error.message);
      }
    }
  };
  const handleRemoveBanner = (b) => {
    const filter = banners.filter(
      (banner) => banner?.bannerImage !== b?.bannerImage
    );
    setBanners(filter);
  };

  return (
    <Dialog maxWidth="lg" open={showEditableModal}>
      <div className="flex flex-col gap-[2rem] max-w-[600px] bg-white px-[2rem] py-[3rem] relative">
        {/* close modal button  */}
        <div className="absolute top-[.8rem] right-[1rem]">
          <MdOutlineClose
            onClick={() => setEditableModal(false)}
            className="text-[1.5rem] text-red-500 cursor-pointer"
          />
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-[1rem]">
          <SelectState from={"modal"} state={state} setState={setState} />
          <SelectCity state={state} city={city} setCity={setCity} />
        </div>

        <div className="flex flex-col">
          <label className="text-[13px]" htmlFor="branchName">
            Branch Name
          </label>
          <input
            className="border w-[100%] focus:outline-none p-[1rem]"
            name="branchName"
            id="branchName"
            placeholder="Branch Name"
            value={branchName}
            onChange={(e) => setBranchName(e.target.value)}
          />
        </div>
        <div className="flex flex-col">
          <label className="text-[13px]" htmlFor="overview">
            Overview
          </label>
          <textarea
            className="border w-[100%] min-h-[200px] max-h-[200px] focus:outline-none p-[1rem]"
            name="overview"
            id="overview"
            cols="30"
            placeholder="Overview"
            value={overview}
            onChange={(e) => setOverview(e.target.value)}
          ></textarea>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-3 items-center gap-[.5rem]">
          <div className="flex flex-col">
            <label className="text-[13px]" htmlFor="email">
              Email
            </label>
            <input
              type="email"
              className="w-[100%] h-[48px] p-[1rem] border focus:outline-none"
              placeholder="Email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="flex flex-col">
            <label className="text-[13px]" htmlFor="emergency">
              Emergency
            </label>
            <input
              type="text"
              className="w-[100%] h-[48px] p-[1rem] border focus:outline-none"
              placeholder="Emergency"
              name="emergency"
              value={emergancy}
              onChange={(e) => setEmergency(e.target.value)}
            />
          </div>
          <div className="flex flex-col">
            <label className="text-[13px]" htmlFor="helpline">
              Helpline
            </label>
            <input
              type="text"
              className="w-[100%] h-[48px] p-[1rem] border focus:outline-none"
              placeholder="Helpline"
              name="helpline"
              value={helpline}
              onChange={(e) => setHelpline(e.target.value)}
            />
          </div>
        </div>
        <div>
          <label
            className="text-[1.5rem] flex items-center gap-[.5rem] justify-center w-[100%] h-[100px] bg-[#cecece] cursor-pointer"
            htmlFor="updateimage"
          >
            <BsImage /> Upload Image
            <input
              onChange={handleImageUpload}
              className="hidden"
              type="file"
              name="updateimage"
              id="updateimage"
            />
          </label>
        </div>
        <div className="flex justify-center">
          {image && (
            <img
              src={image}
              className="h-[150px] w-[290px]"
              alt={"bannerImage"}
            />
          )}
        </div>
        <div className="my-[15px]">
          {banners?.length !== 0 && (
            <>
              <p>Banners Added</p>

              <div className="grid grid-cols-4 gap-[15px] mb-[15px]">
                {banners?.map((b) => (
                  <div key={b?.bannerImage} className="relative">
                    <img
                      src={b?.bannerImage}
                      className="h-[100px] w-[200px] border-[1px] border-[gray] "
                      alt="banner"
                    />
                    <IoIosCloseCircle
                      onClick={() => handleRemoveBanner(b)}
                      style={{ color: "red" }}
                      className="absolute top-[10px] right-[10px] lg:right-[15px] h-[30px] w-[30px] cursor-pointer"
                    />
                  </div>
                ))}
              </div>
            </>
          )}
          <div>
            <label
              className="text-[1.5rem] flex items-center gap-[.5rem] justify-center w-[100%] h-[100px] bg-[#cecece] cursor-pointer"
              htmlFor="updatebanner1"
            >
              <BsImage /> Upload Banner
              <input
                onChange={handleBannersUpload}
                className="hidden"
                type="file"
                name="updatebanner1"
                id="updatebanner1"
              />
            </label>
          </div>
        </div>
        <div>
          <button
            onClick={handleSave}
            className="w-[100%] h-[48px] bg-[#2796ac] hover:bg-[#1a778a] text-white uppercase"
          >
            Update
          </button>
        </div>
      </div>
      <BackDropComponent state={showBackdrop} />
    </Dialog>
  );
};

export default EditHomeModal;
