import React, { useEffect, useState } from "react";
import TopSection from "./Our_Doctor/TopSection";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

// import required modules
import { Navigation } from "swiper/modules";
import DoctorCard from "../Common/DoctorCard";
import CarouselArrowBtns2 from "../Common/CarouselArrowBtns2";
import ViewAllBtn from "../Common/ViewAllBtn";
import { useDoctorsQuery } from "../../services/doctorApi";
import SkeletonComponent from "../Common/SkeletonComponent";

const OurDoctorCarousel = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const { data } = useDoctorsQuery({ page: "00", perPage: "00" });
  const updateIndex = (swiperInstance) => {
    if (swiperInstance === null) return;
    const currentSlide = swiperInstance?.activeIndex;
    setActiveIndex(currentSlide);
  };
  useEffect(() => {
    if (activeIndex > 0) {
      const asterCarousel = document.getElementById("doctor_swiper_slider");
      if (asterCarousel !== null) {
        asterCarousel.removeAttribute("id");
      }
    }
  }, [activeIndex]);
  return (
    <div className="py-[50px] px-[20px] xl:px-0">
      <TopSection />
      {/* carousel section */}
      <div className="max-w-[1140px] mx-auto">
        <Swiper
          navigation={{
            nextEl: ".doctor-arrow-right",
            prevEl: ".doctor-arrow-left",
          }}
          className="mySwiper"
          modules={[Navigation]}
          onActiveIndexChange={updateIndex}
          breakpoints={{
            0: {
              slidesPerView: 1.15,
            },
            768: {
              slidesPerView: 2.5, // Number of slides per view on tablets
            },
            1024: {
              slidesPerView: 3.5, // Number of slides per view on desktops
            },
            1300: {
              slidesPerView: 3.5,
            },
            1500: {
              slidesPerView: 3.5,
            },
          }}
        >
          {data?.data?.length
            ? data?.data?.map((el) => (
                <SwiperSlide>
                  <div className="py-[50px]">
                    <DoctorCard el={el} />
                  </div>
                </SwiperSlide>
              ))
            : [1, 2, 3, 4, 5].map((el) => (
                <SwiperSlide>
                  <SkeletonComponent />
                </SwiperSlide>
              ))}
        </Swiper>
      </div>
      <div className="max-w-[1140px] mx-auto">
        <CarouselArrowBtns2
          left_class={"doctor-arrow-left"}
          right_class={"doctor-arrow-right"}
          children={
            <ViewAllBtn
              text_class={"our_doctor_view_btn"}
              arrow_class={"our_doctor_view_arrow"}
              route_link={"/all-doctors"}
            />
          }
        />
      </div>
    </div>
  );
};

export default OurDoctorCarousel;
