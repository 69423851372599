export const ourValuesData = [
  {
    title: "Transparency",
    text: "We believe in open and honest communication. We provide clear information, discuss treatment options, and involve you in decisions about your care, empowering you to make informed choices.",
  },
  {
    title: "Clinical Excellence",
    text: "Our team of renowned specialists commit to staying at the forefront of medical advancements, ensuring you receive the best care possible.",
  },
  {
    title: "Accessibility",
    text: "We are committed to making high-quality healthcare accessible to all. Our empanelment with insurance providers and participation in government schemes are a testament to this commitment.",
  },
  {
    title: "Compassion and Respect",
    text: "Compassion and respect are the cornerstones of our interactions. We treat every patient with dignity, empathy, and understanding, fostering an environment of trust and support.",
  },
  {
    title: "Continuous Improvement",
    text: "We believe in continuous self-assessment and improvement. We listen to feedback, evaluate our performance, and evolve to meet the changing needs of our patients.",
  },
  {
    title: "Global Outreach",
    text: "Our dedication to providing world-class care extends beyond borders. We welcome international patients with open arms, fostering a diverse and inclusive healthcare community.",
  },
];
