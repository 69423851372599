import React, { useState } from "react";

const ServiceDesc = ({ data }) => {
  const [show, setShow] = useState(false);
  const getEmbedLink = (link) => {
    const youtubeRegex =
      /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/(watch\?v=.{11}|embed\/.{11}|.{11})/;
    if (youtubeRegex.test(link)) {
      const videoId = link?.match(
        /(?:youtu\.be\/|youtube\.com\/(?:watch\?(?:.*&)?v=|(?:embed|v)\/))([\w-]{11})/
      )[1];
      return `https://www.youtube.com/embed/${videoId}`;
    } else {
      return false;
    }
  };
  return (
    <div
      id="overview_section"
      className="max-w-[1140px] mx-auto px-[20px] xl:px-0 py-[5rem]"
    >
      <h1 className="text-[1.875rem] xl:text-[2.875rem] text-[#0a2a6c] font-semibold mb-[20px]">
        {data?.name}
      </h1>
      {getEmbedLink(data?.youtubeLink) && (
        <iframe
          className="w-[100%] h-[260px] lg:h-[500px] mb-[30px]"
          src={getEmbedLink(data?.youtubeLink)}
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      )}
      {data?.overview?.join("")?.length > 1000 ? (
        <>
          <p className="text-[#384f7e] font-[300]">
            {!show ? (
              <>
                {data?.overview
                  ?.join("\n")
                  ?.slice(0, 1000)
                  ?.concat(".....")
                  ?.split("\n")
                  .map((o) => (
                    <p className="text-[#384f7e] font-[300] mb-[15px]">{o}</p>
                  ))}
              </>
            ) : (
              <>
                {data?.overview?.map((o) => (
                  <p className="text-[#384f7e] font-[300] mb-[15px]">{o}</p>
                ))}
              </>
            )}
          </p>
          {!show ? (
            <span
              onClick={() => setShow(true)}
              className="w-fit text-[14px] text-[#007bff] hover:text-[#2b6f7d] font-[300] cursor-pointer"
            >
              Read More
            </span>
          ) : (
            <span
              href=""
              onClick={() => setShow(false)}
              className="w-fit text-[14px] text-[#007bff] hover:text-[#2b6f7d] font-[300] cursor-pointer"
            >
              Read less
            </span>
          )}
        </>
      ) : (
        <>
          {data?.overview?.map((o) => (
            <p className="text-[#384f7e] font-[300] mb-[15px]">{o}</p>
          ))}
        </>
      )}
    </div>
  );
};

export default ServiceDesc;
