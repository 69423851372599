import React from "react";
import TopSection from "./News_Events/TopSection";
import Carousel from "./News_Events/Carousel";

const NewEvents = ({ data, language, setLanguage, isLoading }) => {
  return (
    <div className="bg-[#1c456e] py-[48px] px-[20px] xl:px-0 mb-[40px]">
      <div>
        <TopSection language={language} setLanguage={setLanguage} />
        <Carousel data={data}  isLoading={isLoading} />
      </div>
    </div>
  );
};

export default NewEvents;
