import React, { useEffect, useState } from "react";
import { Dialog } from "@mui/material";
import { BsImage } from "react-icons/bs";
import { MdOutlineClose } from "react-icons/md";
import { useUpdateDoctorMutation } from "../../../services/doctorApi";
import BackDropComponent from "../../Common/BackDropComponent";
import { SuccessAlertFunction } from "../../Common/SuccessAlert";
import { FailedAlertFunction } from "../../Common/FailedAlertFunction";
import axios from "axios";

const EditDoctorModal = ({ row, showEditableModal, setEditableModal }) => {
  const [doctorName, setDoctorName] = useState("");
  const [role, setRole] = useState("");
  const [qualification, setQualification] = useState("");
  const [speciality, setSpeciality] = useState("");
  const [hospital, setHospital] = useState("");
  const [overview, setOverview] = useState("");
  const [expertise, setExpertise] = useState("");
  const [showBackdrop, setBackdrop] = useState(false);
  const [image, setImage] = useState("");
  const [updateDoctor] = useUpdateDoctorMutation();
  useEffect(() => {
    setDoctorName(row?.doctorName);
    setRole(row?.role);
    setQualification(row?.qualification);
    setSpeciality(row?.speciality);
    setHospital(row?.hospital);
    setOverview(row?.overview?.join("\n"));
    setExpertise(row?.expertise?.join("\n"));
    setImage(row?.image);
  }, [row]);
  //console.log(row);
  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("upload_preset", process.env.REACT_APP_PRESET);
      try {
        setBackdrop(true);
        const res = await axios.post(
          `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDNAME}/image/upload`,
          formData
        );
        setBackdrop(false);
        setImage(res.data.secure_url);
      } catch (error) {
        setBackdrop(false);
        console.log(error.message);
      }
    }
  };

  const handleUpdate = () => {
    const data = {
      doctorName: doctorName,
      role: role,
      qualification: qualification,
      speciality: speciality,
      hospital: hospital,
      overview: overview?.split("\n"),
      expertise: expertise?.split("\n"),
      image: image,
    };
    setBackdrop(true);
    updateDoctor({ id: row?._id, data: data })
      .then((res) => {
        if (res?.data?.status) {
          setDoctorName("");
          setRole("");
          setQualification("");
          setSpeciality("");
          setHospital("");
          setOverview("");
          setImage("");
          setEditableModal(false);
          setBackdrop(false);
          SuccessAlertFunction(res?.data?.message);
        } else {
          FailedAlertFunction(res?.data?.message);
          setBackdrop(false);
        }
      })
      .catch((error) => {
        FailedAlertFunction(error?.message);
        setBackdrop(false);
      });
  };
  return (
    <Dialog maxWidth="lg" open={showEditableModal}>
      <div className="flex flex-col gap-[2rem] max-w-[600px] bg-white px-[2rem] py-[3rem] relative">
        {/* close modal button  */}
        <div className="absolute top-[.8rem] right-[1rem]">
          <MdOutlineClose
            onClick={() => setEditableModal(false)}
            className="text-[1.5rem] text-red-500 cursor-pointer"
          />
        </div>
        <div className="flex flex-col">
          <label className="text-[13px]" htmlFor="name">
            Doctor Name
          </label>
          <input
            type="text"
            className="w-[100%] h-[48px] p-[1rem] border focus:outline-none"
            placeholder="Doctor Name"
            name="name"
            value={doctorName}
            onChange={(e) => setDoctorName(e.target.value)}
          />
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 items-center gap-[.5rem]">
          <div className="flex flex-col">
            <label className="text-[13px]" htmlFor="speciality">
              Speciality
            </label>
            <input
              type="text"
              className="w-[100%] h-[48px] p-[1rem] border focus:outline-none"
              placeholder="Doctor Name"
              name="speciality"
              value={speciality}
              onChange={(e) => setSpeciality(e.target.value)}
            />
          </div>
          <div className="flex flex-col">
            <label className="text-[13px]" htmlFor="role">
              Role
            </label>
            <input
              type="text"
              className="w-[100%] h-[48px] p-[1rem] border focus:outline-none"
              placeholder="Emergency"
              name="role"
              value={role}
              onChange={(e) => setRole(e.target.value)}
            />
          </div>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 items-center gap-[.5rem]">
          <div className="flex flex-col">
            <label className="text-[13px]" htmlFor="qualification">
              Qualification
            </label>
            <input
              type="text"
              className="w-[100%] h-[48px] p-[1rem] border focus:outline-none"
              placeholder="Qualification"
              name="qualification"
              value={qualification}
              onChange={(e) => setQualification(e.target.value)}
            />
          </div>
          <div className="flex flex-col">
            <label className="text-[13px]" htmlFor="hospital">
              Hospital
            </label>
            <input
              type="text"
              className="w-[100%] h-[48px] p-[1rem] border focus:outline-none"
              placeholder="Hospital"
              name="hospital"
              value={hospital}
              onChange={(e) => setHospital(e.target.value)}
            />
          </div>
        </div>
        <div className="flex flex-col">
          <label className="text-[13px]" htmlFor="overview">
            Overview
          </label>
          <textarea
            className="border w-[100%] min-h-[100px] max-h-[100px] focus:outline-none p-[1rem]"
            name="overview"
            id="overview"
            cols="30"
            placeholder="Overview"
            value={overview}
            onChange={(e) => setOverview(e.target.value)}
          ></textarea>
        </div>
        <div className="flex flex-col">
          <label className="text-[13px]" htmlFor="expertise">
            Expertise
          </label>
          <textarea
            className="border w-[100%] min-h-[100px] max-h-[100px] focus:outline-none p-[1rem]"
            name="expertise"
            id="expertise"
            cols="30"
            placeholder="Area of Expertise"
            value={expertise}
            onChange={(e) => setExpertise(e.target.value)}
          ></textarea>
        </div>
        <div>
          <label
            className="text-[1rem] flex items-center gap-[.5rem] justify-center w-[100%] h-[50px] bg-[whitesmoke] cursor-pointer"
            htmlFor="imageUpdate"
          >
            <BsImage /> Upload Image
            <input
              onChange={handleImageUpload}
              className="hidden"
              type="file"
              name="imageUpdate"
              id="imageUpdate"
            />
          </label>
        </div>
        <div className="flex justify-center">
          {image && (
            <img
              src={image}
              className="h-[150px] w-[150px]"
              alt={"bannerImage"}
            />
          )}
        </div>
        <div>
          <button
            onClick={handleUpdate}
            className="w-[100%] h-[48px] bg-[#2796ac] hover:bg-[#1a778a] text-white uppercase"
          >
            Save
          </button>
        </div>
      </div>
      <BackDropComponent state={showBackdrop} />
    </Dialog>
  );
};

export default EditDoctorModal;
