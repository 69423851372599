import React from "react";
import TopSection from "./TopSection";
import Card from "./Card";

const PlanSection = () => {
  return (
    <div>
      <TopSection />
      <Card />
    </div>
  );
};

export default PlanSection;
