import React from "react";
import { BsCheckCircleFill } from "react-icons/bs";
import NavBar from "../Components/Common/NavBar";
import Footer from "../Components/Footer/Footer";
import useGoogleGtag from "../utils/initializeGoogleGtag";
import { useNavigate } from "react-router-dom/dist";
import { Helmet } from "react-helmet";
import { initializeGtag } from "../initializeGoogleTag";

const ThankYou = () => {
  // useGoogleGtag();
  initializeGtag()
  const navigate = useNavigate();

  return (
    <>
    {useGoogleGtag && (
        <Helmet>
          <script
            dangerouslySetInnerHTML={{
              __html: `
                gtag('event', 'conversion', {'send_to': 'AW-458972515/TVaECJjH8e0BEOO67doB'});
              `,
            }}
          />
        </Helmet>
      )}
    <div className="h-[100vh] flex flex-col justify-between">
      <div>
        <NavBar />
      </div>
      <div className="flex flex-col justify-center items-center bg-[#eeeeeebd] p-[20px] w-[90%] mx-auto lg:w-[500px] rounded">
        <BsCheckCircleFill
          style={{ color: "#eb4825", height: "70px", width: "70px" }}
        />
        <h3 className="text-3xl font-bold my-[30px] text-center">
          Thanks For your Query. We will contact you soon!
        </h3>
        <button
          onClick={() => navigate("/")}
          className="bg-[#eb4825] text-[white] px-[20px] py-[8px] rounded cursor-pointer"
        >
          OKAY{" "}
        </button>
      </div>
      <div>
        {" "}
        <Footer />
      </div>
    </div></>
  );
};

export default ThankYou;
