import React from "react";

const OverviewSection = ({ data: DoctorAboutData }) => {
  return (
    <div>
      <h1 className="text-[#0a2a6c] text-[2.25rem] font-semibold">Overview</h1>
      <div className="flex flex-col gap-[1rem] mt-[1rem]">
        <p className="text-[#6e81a8]">{DoctorAboutData?.overview}</p>
      </div>
    </div>
  );
};

export default OverviewSection;
