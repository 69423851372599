import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { State } from "country-state-city";

export default function SelectState({ state, setState, from }) {
  const [open, setOpen] = React.useState(false);
  const [states, setStates] = React.useState([]);
  const handleChange = (e) => {
    //console.log(e.target.value);
    setState(e.target.value);
  };
  //console.log(state);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  React.useEffect(() => {
    const states = State.getStatesOfCountry("IN");
    setStates(states);
    if (from !== "modal" && states?.length > 0) {
      setState(states[0]?.name);
    }
  }, [from, setState]);
  //console.log(state)
  return (
    <div className="w-[100%]">
      <FormControl size="medium" sx={{ width: "100%", background: "white" }}>
        <InputLabel id="demo-controlled-open-select-label">State</InputLabel>
        <Select
          labelId="demo-controlled-open-select-label"
          id="demo-controlled-open-select"
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          value={state}
          label="state"
          onChange={handleChange}
          name="state"
        >
          {states?.map((state) => (
            <MenuItem value={state?.name}>{state?.name}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
