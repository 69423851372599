import { useEffect } from "react";
import { initializeGoogleGtag } from "../initializeGoogleTag";

function useGoogleGtag() {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://www.googletagmanager.com/gtag/js?id=AW-458972515";
    script.async = true;

    script.onload = () => {
      initializeGoogleGtag();
    };

    document.head.appendChild(script);
  }, []);

  return null;
}

export default useGoogleGtag;
