import React, { useState } from "react";
import ArrowComp from "./ArrowComp";

const ButtonComp = ({ text }) => {
  const [hovered, setHovered] = useState(false);
  return (
    <div>
      <button
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        className={`flex items-center gap-[1rem] pl-[1rem] pr-[20px] py-[11px] rounded-[.25rem] border-[1px] border-[#42a895] transition-all ${
          hovered ? "text-white bg-[#42a895]" : "text-[#42a895] bg-transparent"
        }`}
      >
        <span>{text}</span>
        <span style={{ fill: hovered ? "white" : "#42a895" }}>
          <ArrowComp />
        </span>
      </button>
    </div>
  );
};

export default ButtonComp;
