// import React from "react";
// import NavBar from "../Components/Common/NavBar";
// import Footer from "../Components/Footer/Footer";
// import TopBanner from "../Components/About_Doctor/TopBanner";
// import LeftSide from "../Components/About_Doctor/LeftSide";
// import { AboutDoctorData } from "../utils/AboutDoctorData";
// import RightSide from "../Components/About_Doctor/RightSide";

// const AboutDoctor = () => {
//   return (
//     <div>
      
//       <NavBar />
//       <TopBanner />
//       <div className="max-w-[1170px] flex flex-col md:flex-row gap-[30px] mx-auto py-[30px] md:py-[40px] lg:py-[50px] px-[20px] xl:px-0 xl:py-[90px]">
//         <LeftSide data={AboutDoctorData} />
//         <RightSide data={AboutDoctorData} />
//       </div>
//       <Footer />
//     </div>
//   );
// };

// export default AboutDoctor;

import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import NavBar from "../Components/Common/NavBar";
import Footer from "../Components/Footer/Footer";
import TopBanner from "../Components/About_Doctor/TopBanner";
import LeftSide from "../Components/About_Doctor/LeftSide";
import { AboutDoctorData } from "../utils/AboutDoctorData";
import RightSide from "../Components/About_Doctor/RightSide";

const AboutDoctor = () => {
  useEffect(() => {
    document.title = "Dr. Rajah V Koppala - India’s Most Experienced Varicose Veins Specialists";
  }, []);

  return (
    <div>
      <Helmet>
        <title>Dr. Rajah V Koppala - India’s Most Experienced Varicose Veins Specialists</title>
        <meta name="description" content="Learn about Dr. Rajah V Koppala, India's leading specialist in Varicose Veins treatment with extensive experience and expertise." />
        <meta name="keywords" content="Dr. Rajah V Koppala, Varicose Veins Specialist, Varicose Veins Treatment, Expert Doctor, Lymphatic Disorders" />
      </Helmet>
      <NavBar />
      <TopBanner />
      <div className="max-w-[1170px] flex flex-col md:flex-row gap-[30px] mx-auto py-[30px] md:py-[40px] lg:py-[50px] px-[20px] xl:px-0 xl:py-[90px]">
        <LeftSide data={AboutDoctorData} />
        <RightSide data={AboutDoctorData} />
      </div>
      <Footer />
    </div>
  );
};

export default AboutDoctor;


