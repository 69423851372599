import React from "react";
import TopSections from "./Overview/TopSections";
import MidSection from "./Overview/MidSection";

const Overview = ({ data }) => {
  return (
    <div className="max-w-[1140px] mx-auto px-[20px] xl:px-0 py-[64px] pt-[30px]">
      <TopSections data={data} />
      <MidSection data={data} />
    </div>
  );
};

export default Overview;
