import React from "react";
// import SubscribeComp from "./SubscribeComp";
import BottomSection from "./BottomSection";
import FollowUsComp from "../Common/FollowUsComp";

const Footer = () => {
  return (
    <div className="bg-[#1c456e] pt-[1.375rem] px-[20px] xl:px-0">
      <div className="w-[100%] lg:max-w-[1160px] mx-auto">
        <div className="flex flex-col lg:flex-row gap-[2rem] lg:gap-0 justify-between pt-[1.375rem] pb-[2rem]">
          <BottomSection />
          <FollowUsComp />
        </div>
      </div>
    </div>
  );
};

export default Footer;
