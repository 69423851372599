import React from "react";
import { Link, useNavigate } from "react-router-dom";
import ButtonComp from "./ButtonComp";
import { FaArrowRight } from "react-icons/fa6";

const DoctorAllCard = ({ el }) => {
  const navigate = useNavigate()
  return (
    <>
        <div className="activeTranition flex flex-col items-center md:items-start md:flex-row gap-[32px] w-[100%] h-[100%] cursor-pointer">
          <img
            className="w-[174px] xl:w-[174px] h-[218px] object-cover"
            src={el?.image}
            alt=""
          />
          <div className="w-[100%]">
            <div className="flex flex-col lg:flex-row justify-between w-[100%] bg-[#f2f2f2] py-[1rem]">
              <div className="w-[100%] md:w-[455px]">
                <h5 className="text-[1.375rem] text-[#384f7e] font-bold">
                  {el?.doctorName}
                </h5>
                <div className="text-[14px] text-[#6e81a8] capitalize leading-[1rem] mb-[1rem]">
                  <p>{el?.speciality}</p>
                  <p className="font-bold">{el?.role}</p>
                </div>
                <div className="h-[4ex] leading-[2ex] overflow-hidden">
                  {el?.overview?.map((o) => (
                    <p className="text-[14px] text-[#6e81a8]">{o}</p>
                  ))}
                </div>
              </div>
              <div className="flex flex-row lg:flex-col gap-[1rem] lg:gap-[.5rem] mt-[1rem] lg:mt-0">
                <div className="flex flex-col items-start lg:items-end leading-[1.2rem]">
                  <h6 className="text-[#0a2a6c] font-semibold">Hospital</h6>
                  <p className="text-[14px] text-[#6e81a8]">{el?.hospital}</p>
                </div>
                <div className="flex flex-col items-start lg:items-end leading-[1.2rem]">
                  <h6 className="text-[#0a2a6c] font-semibold">SPECIALITY</h6>
                  <p className="text-[14px] text-[#6e81a8]">{el?.speciality}</p>
                </div>
              </div>
            </div>
            <div className="flex flex-col md:flex-row items-center justify-end gap-[1rem] mt-[1rem]">
              {/* <ButtonComp text="Video Consultation" /> */}
              <Link to={`/doctor/${el?.doctorName?.split(' ').join('-')}`}>
              <ButtonComp text="View Details" />
              </Link>
              <div>
                <button
                  onClick={() =>
                    navigate(`/appointment/${el?.doctorName?.split(' ').join('-')}`)
                  }
                  className="bg-[#2d7bc9] text-white px-[20px] rounded py-[12px] hover:bg-[#0653a2]"
                >
                  Book An Appointment{" "}
                  <FaArrowRight className="text-white inline ms-[10px]" />
                </button>
              </div>
            </div>
            
          </div>
        </div>
    </>
  );
};

export default DoctorAllCard;
