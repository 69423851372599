import React from "react";
import { Link } from "react-router-dom";

const DoctorCard = ({ el }) => {
  return (
    <>
      <Link to={`/doctor/${el?.doctorName?.split(' ').join('-')}`}>
        <div className="activeTranition w-[265px] xl:w-[306px] h-[431px] hover:scale-[1.1] cursor-pointer">
          <img
            className="w-[265px] xl:w-[306px] h-[300px] object-cover"
            src={el?.image}
            alt=""
          />
          <div className="w-[100%] bg-[#f2f2f2] py-[1rem] px-[20px] h-[120px]">
            <h5 className="text-[1.25rem] text-[#384f7e] font-bold">
              {el?.doctorName}
            </h5>
            <p className="text-[12px] mb-[10px]">{el?.speciality}</p>
            {el?.overview
              ?.join("\n")
              ?.slice(0, 80)
              ?.concat(".....")
              ?.split("\n")
              .map((o) => (
                <p className="text-[12px] text-[#0a2a6c] font-semibold">{o}</p>
              ))}
          </div>
        </div>
      </Link>
    </>
  );
};

export default DoctorCard;
