import React, { useState } from "react";

const HashComp = () => {
  const [active, setActive] = useState("overview");
  return (
    <div className="bg-[#f2f2f2] py-[1.25rem] sticky top-[123px] md:top-[133px] lg:top-[82px]">
      <ul className="flex flex-wrap items-center justify-center gap-[1rem] md:gap-[44px]">
        <li
          onClick={() => setActive("overview")}
          className={`text-[13px] md:text-[1.125rem] font-semibold ${
            active === "overview" ? "text-[#0a2a6c]" : "text-[#6e81a8] "
          }`}
        >
          <a href="#overview_section">Overview</a>
        </li>
        <li
          onClick={() => setActive("treatmentNprocedures")}
          className={`text-[13px] md:text-[1.125rem] font-semibold ${
            active === "treatmentNprocedures"
              ? "text-[#0a2a6c]"
              : "text-[#6e81a8] "
          }`}
        >
          <a href="#treatmentNprocedures">Treatment & Procedures</a>
        </li>
        <li
          onClick={() => setActive("patient_stories")}
          className={`text-[13px] md:text-[1.125rem] font-semibold ${
            active === "patient_stories" ? "text-[#0a2a6c]" : "text-[#6e81a8] "
          }`}
        >
          <a href="#patient_stories">Patient Stories</a>
        </li>
      </ul>
    </div>
  );
};

export default HashComp;
