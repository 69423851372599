import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl } from "./api";
const getTokenFromLocalStorage = () => {
  return localStorage.getItem("token");
};
export const leadApi = createApi({
  reducerPath: "leadApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseUrl}/leads`,
    prepareHeaders: (headers) => {
      const token = getTokenFromLocalStorage();
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["Lead"],
  endpoints: (builder) => ({
    leads: builder.query({
      query: (data) => `/?page=${data?.page}&limit=10&search=${data?.search}`,
      providesTags: ["Lead"],
    }),
    getAllLeads: builder.query({
      query: () => `/getAllLeads`,
      providesTags: ["Lead"],
    }),
    getLeadById: builder.query({
      query: (id) => `/${id}`,
      providesTags: ["Lead"],
    }),
    addLead: builder.mutation({
      query: (data) => ({
        url: "/",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Lead"],
    }),
    deleteLeadById: builder.mutation({
      query: (id) => ({
        url: `/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Lead"],
    }),
    updateLead: builder.mutation({
      query: ({ id, data }) => ({
        url: `/${id}`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["Lead"],
    }),
  }),
});
export const {
  useLeadsQuery,
  useAddLeadMutation,
  useGetLeadByIdQuery,
  useUpdateLeadMutation,
  useDeleteLeadByIdMutation,
  useGetAllLeadsQuery
} = leadApi;
