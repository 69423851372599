// import React, { useState, useEffect } from "react";
// import TopCarouse from "../Components/Home_Page/TopCarouse";
// import Overview from "../Components/Home_Page/Overview";
// import OurDoctorCarousel from "../Components/Home_Page/OurDoctorCarousel";
// import AsterSpecialist from "../Components/Home_Page/AsterSpecialist";
// import PatientsSays from "../Components/Home_Page/PatientsSays";
// import NewEvents from "../Components/Home_Page/NewEvents";
// import Accreditation from "../Components/Home_Page/Accreditation";
// import NavBar from "../Components/Common/NavBar";
// import Footer from "../Components/Footer/Footer";
// import FAQsSection from "../Components/Services/Services/FAQsSection";
// import { useHomesQuery } from "../services/homeApi";
// import { useTestimonialsQuery } from "../services/testimonialsApi";
// import { useNewsQuery } from "../services/newsApi";
// import Loading from "../Components/Common/Loading";
// import AboutDoctor from "../Components/Home_Page/AboutDoctor";
// import { useLocation } from "react-router-dom";

// const Home = () => {
//   const [language, setLanguage] = useState("All");
//   const { data, isLoading } = useHomesQuery();
//   const { data: videoData } = useTestimonialsQuery({
//     page: "00",
//     perPage: "00",
//   });
//   const { data: newsData, isLoading: isLoading1 } = useNewsQuery({
//     page: "00",
//     perPage: "00",
//     language: language,
//   });
//   const { pathname } = useLocation();

//   useEffect(() => {
//     document.title = "Best Varicose Veins Treatment | Most Advanced Treatment | Avis Hospital"; // Set your desired page title here
//   }, []);

//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, [pathname]);

//   return (
//     <div>
//       <NavBar />
//       {isLoading ? (
//         <Loading />
//       ) : (
//         <>
//           <TopCarouse data={data?.data[0]} from={"home"} />
//           <AboutDoctor />
//           <Overview data={data?.data[0]} />
//           <OurDoctorCarousel />
//           <AsterSpecialist />
//           <PatientsSays data={videoData?.data} />
//           <NewEvents
//             data={newsData?.data}
//             language={language}
//             setLanguage={setLanguage}
//             isLoading={isLoading1}
//           />
//           <Accreditation />
//           <FAQsSection />
//         </>
//       )}
//       <Footer />
//     </div>
//   );
// };

// export default Home;

import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import TopCarouse from "../Components/Home_Page/TopCarouse";
import Overview from "../Components/Home_Page/Overview";
import OurDoctorCarousel from "../Components/Home_Page/OurDoctorCarousel";
import AsterSpecialist from "../Components/Home_Page/AsterSpecialist";
import PatientsSays from "../Components/Home_Page/PatientsSays";
import NewEvents from "../Components/Home_Page/NewEvents";
import Accreditation from "../Components/Home_Page/Accreditation";
import NavBar from "../Components/Common/NavBar";
import Footer from "../Components/Footer/Footer";
import FAQsSection from "../Components/Services/Services/FAQsSection";
import { useHomesQuery } from "../services/homeApi";
import { useTestimonialsQuery } from "../services/testimonialsApi";
import { useNewsQuery } from "../services/newsApi";
import Loading from "../Components/Common/Loading";
import AboutDoctor from "../Components/Home_Page/AboutDoctor";
import { useLocation } from "react-router-dom";

const Home = () => {
  const [language, setLanguage] = useState("All");
  const { data, isLoading } = useHomesQuery();
  const { data: videoData } = useTestimonialsQuery({
    page: "00",
    perPage: "00",
  });
  const { data: newsData, isLoading: isLoading1 } = useNewsQuery({
    page: "00",
    perPage: "00",
    language: language,
  });
  const { pathname } = useLocation();

  useEffect(() => {
    document.title = "Best Varicose Veins Treatment | Most Advanced Treatment | Avis Hospital"; // Set your desired page title here
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div>
      <Helmet>
        <title>Best Varicose Veins Treatment | Most Advanced Treatment | Avis Hospital</title>
        <meta name="description" content="Get the best varicose veins treatment at Avis Hospital with the most advanced techniques and experienced specialists." />
        <meta name="keywords" content="Varicose Veins Treatment, Advanced Varicose Veins Care, Avis Hospital, Vascular Care, Best Varicose Vein Surgery, Varicose Veins Specialist, Varicose Vein Clinic, Top Vascular Surgeons" />
      </Helmet>
      <NavBar />
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <TopCarouse data={data?.data[0]} from={"home"} />
          <AboutDoctor />
          <Overview data={data?.data[0]} />
          <OurDoctorCarousel />
          <AsterSpecialist />
          <PatientsSays data={videoData?.data} />
          <NewEvents
            data={newsData?.data}
            language={language}
            setLanguage={setLanguage}
            isLoading={isLoading1}
          />
          <Accreditation />
          <FAQsSection />
        </>
      )}
      <Footer />
    </div>
  );
};

export default Home;

