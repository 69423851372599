import React from "react";
import TopSection from "./TopSection";
import FAQsAccordions from "./FAQAccordion";

const WhyChooseFaqSection = () => {
  return (
    <div className="bg-[#093143] px-[20px] pb-[30px] xl:px-0 ">
      <div className="max-w-[1140px] mx-auto">
        <TopSection />
        <FAQsAccordions />
      </div>
    </div>
  );
};

export default WhyChooseFaqSection;
