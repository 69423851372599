import React, { useState } from "react";
import { BsImage } from "react-icons/bs";
import { useAddAwardMutation } from "../../../services/awardApi";
import BackDropComponent from "../../Common/BackDropComponent";
import { SuccessAlertFunction } from "../../Common/SuccessAlert";
import { FailedAlertFunction } from "../../Common/FailedAlertFunction";
import axios from "axios";

const BannerInputes = () => {
  const [addAward] = useAddAwardMutation();
  const [bannerImage, setBannerImage] = useState("");
  const [showBackdrop, setBackdrop] = useState(false);

  const handleBannersUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      setBackdrop(true)
      const file = e.target.files[0];
      const formData = new FormData();
      formData.append("file", file);
      formData.append("upload_preset", process.env.REACT_APP_PRESET);
      try {
        setBackdrop(true);
        const res = await axios.post(
          `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDNAME}/image/upload`,
          formData
        );
        setBackdrop(false);
        setBannerImage(res.data.secure_url);
      } catch (error) {
        setBackdrop(false);
        console.log(error.message);
      }
    }
  };

  const handleSubmit = () => {
    if (bannerImage) {
      setBackdrop(true);
      addAward({ image: bannerImage })
        .then((res) => {
          if (res?.data?.status) {
            setBannerImage("");
            SuccessAlertFunction(res?.data?.message);
            setBackdrop(false);
          } else {
            FailedAlertFunction(res?.data?.message);
            setBackdrop(false);
          }
        })
        .catch((error) => {
          FailedAlertFunction(error?.message);
          setBackdrop(false);
        });
    } else {
      FailedAlertFunction("Please upload an image!");
    }
  };
  return (
    <div className="flex flex-col gap-[1rem]">
      <div>
        <label
          className="text-[1.5rem] flex items-center gap-[.5rem] justify-center w-[100%] h-[100px] bg-[white] cursor-pointer"
          htmlFor="image"
        >
          <BsImage /> Upload Banner Image
          <input
            onChange={handleBannersUpload}
            className="hidden"
            type="file"
            name="image"
            id="image"
          />
        </label>
      </div>
      <div>
        {bannerImage && (
          <img
            src={bannerImage}
            className="h-[150px] w-[290px]"
            alt={"bannerImage"}
          />
        )}
      </div>
      <div>
        <button
          onClick={handleSubmit}
          className="w-[100%] h-[48px] bg-[#2796ac] hover:bg-[#1a778a] text-white uppercase"
        >
          SAVE
        </button>
      </div>
      <BackDropComponent state={showBackdrop} />
    </div>
  );
};

export default BannerInputes;
