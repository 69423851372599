import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl } from "./api";
const getTokenFromLocalStorage = () => {
  return localStorage.getItem("token");
};
export const userApi = createApi({
  reducerPath: "userApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseUrl}/user`,
    prepareHeaders: (headers) => {
      const token = getTokenFromLocalStorage();
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["User"],
  endpoints: (builder) => ({
    users: builder.query({
      query: ({ page, perPage }) => `/?page=${page}&perPage=${perPage}`,
      providesTags: ["User"],
    }),
    getUserById: builder.query({
      query: (id) => `/${id}`,
      providesTags: ["User"],
    }),
    deleteUserById: builder.mutation({
      query: (id) => ({
        url: `/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["User"],
    }),
    addUser: builder.mutation({
      query: (data) => ({
        url: "/signup",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["User"],
    }),
    loginUser: builder.mutation({
      query: (data) => ({
        url: "/signin",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["User"],
    }),
    updateUser: builder.mutation({
      query: ({ id, data }) => ({
        url: `/${id}`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["User"],
    }),
  }),
});
export const {
  useUsersQuery,
  useAddUserMutation,
  useLoginUserMutation,
  useGetUserByIdQuery,
  useUpdateUserMutation,
  useDeleteUserByIdMutation,
} = userApi;
