import React, { useState } from "react";
import SelectRole from "./SelectRole";
import { useAddUserMutation } from "../../../services/userApi";
import BackDropComponent from "../../Common/BackDropComponent";
import { SuccessAlertFunction } from "../../Common/SuccessAlert";
import { FailedAlertFunction } from "../../Common/FailedAlertFunction";

const TopForm = () => {
  const [role, setRole] = useState("admin");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [addUser] = useAddUserMutation();
  const [showBackdrop, setBackdrop] = useState(false);
  const handleCreate = (e) => {
    e.preventDefault();
    const data = {
      email: email,
      password: password,
      role: role,
    };
    if (role && email && password) {
      setBackdrop(true);
      addUser(data)
        .then((res) => {
          if (res?.data?.status) {
            setEmail("");
            setPassword("");
            setRole("");
            SuccessAlertFunction(res?.data?.message);
            setBackdrop(false);
          } else {
            setBackdrop(false);
            FailedAlertFunction(res?.data?.message);
          }
        })
        .catch((error) => {
          setBackdrop(false);
          FailedAlertFunction(error?.message);
        });
    } else {
      FailedAlertFunction("Please fill all the details!");
    }
  };
  return (
    <div>
      <form className="flex flex-col xl:flex-row justify-between items-center gap-[1rem]">
        <input
          className="w-[100%] h-[40px] px-[20px] py-[2px] text-[.875rem] text-[#000] bg-[#fff] focus:outline-none"
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          className="w-[100%] h-[40px] px-[20px] py-[2px] text-[.875rem] text-[#000] bg-[#fff] focus:outline-none"
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <SelectRole role={role} setRole={setRole} />
        <button
          onClick={handleCreate}
          className="w-[100%] md:w-[350px] h-[40px] bg-[#2796ac] hover:bg-[#1a778a] text-white uppercase"
        >
          Sumbit
        </button>
      </form>
      <BackDropComponent state={showBackdrop} />
    </div>
  );
};

export default TopForm;
