import React from "react";

const TopSection = () => {
  return (
    <div className="max-w-[1140px] py-[3rem] mx-auto">
      <h1 className="text-[#0a2a6c] text-[1.875rem] lg:text-[2.875rem] font-semibold">
        Plan Your Care
      </h1>
      <div className="flex justify-between items-center">
        <div className="max-w-[915px] text-[#384f7e]">
          <p className="leading-[1.5rem]">
            At Avis Vascular Centre, we provide professional, transparent and hassle-free treatments. Our dedicated team of international coordinators assists overseas patients seeking a consultation, a second opinion, or non-surgical treatment from India's top interventional radiologists.
          </p>
        </div>
      </div>
    </div>
  );
};

export default TopSection;
