import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl } from "./api";
const getTokenFromLocalStorage = () => {
  return localStorage.getItem("token");
};
export const homeApi = createApi({
  reducerPath: "homeApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseUrl}/homes`,
    prepareHeaders: (headers) => {
      const token = getTokenFromLocalStorage();
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["Home"],
  endpoints: (builder) => ({
    homes: builder.query({
      query: () => `/`,
      providesTags: ["Home"],
    }),
    getHomeById: builder.query({
      query: (id) => `/${id}`,
      providesTags: ["Home"],
    }),
    deleteHomeById: builder.mutation({
      query: (id) => ({
        url: `/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Home"],
    }),
    addHome: builder.mutation({
      query: (data) => ({
        url: "/",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Home"],
    }),
    updateHome: builder.mutation({
      query: ({ id, data }) => ({
        url: `/${id}`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["Home"],
    }),
  }),
});
export const {
  useHomesQuery,
  useAddHomeMutation,
  useGetHomeByIdQuery,
  useUpdateHomeMutation,
  useDeleteHomeByIdMutation,
} = homeApi;
