import React from "react";
// import img from "../../../Assets/Image/";

const Card = () => {
  return (
    <div className="max-w-[1140px] mx-auto">
      {/* <img src={img} alt="" /> */}
    </div>
  );
};

export default Card;
