import React, { useEffect, useState } from "react";
import { Dialog } from "@mui/material";
import { MdOutlineClose } from "react-icons/md";
import { useUpdateFaqMutation } from "../../../services/faqApi";
import { toast } from "react-toastify";

const EditFAQSModal = ({ row, showEditableModal, setEditableModal }) => {
  //console.log(row);
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [updateFaq] = useUpdateFaqMutation();
  useEffect(() => {
    setQuestion(row?.question);
    setAnswer(row?.answer);
  }, [row]);

  const handleUpdateFaq = () => {
    const data = {
      question: question,
      answer: answer,
    };
    if (question && answer) {
      updateFaq({ id: row?._id, data: data }).then((res) => {
        if (res?.data?.status) {
          setQuestion("");
          setAnswer("");
          setEditableModal(false);
          toast.success(res?.data?.message);
        } else {
          toast.error(res?.data?.message);
        }
      });
    } else {
      toast.error("Please fill all the details!");
    }
  };
  return (
    <Dialog maxWidth="lg" open={showEditableModal}>
      <div className="flex flex-col gap-[2rem] w-[100%] md:w-[600px] bg-white px-[2rem] py-[3rem] relative">
        {/* close modal button  */}
        <div className="absolute top-[.8rem] right-[1rem]">
          <MdOutlineClose
            onClick={() => setEditableModal(false)}
            className="text-[1.5rem] text-red-500 cursor-pointer"
          />
        </div>
        <div className="flex flex-col gap-[.5rem]">
          <div className="flex flex-col">
            <label className="text-[13px]" htmlFor="faqHeading">
              FAQ Heading
            </label>
            <input
              type="text"
              className="w-[100%] h-[48px] p-[1rem] border focus:outline-none"
              placeholder="FAQ Heading"
              name="faqHeading"
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
            />
          </div>
          <div className="flex flex-col">
            <label className="text-[13px]" htmlFor="faqText">
              FAQ Text
            </label>
            <textarea
              className="border w-[100%] bg-[#f2f2f2] focus:outline-none p-[1rem]"
              name="faqText"
              id="faqText"
              cols="30"
              rows="10"
              placeholder="FAQ Text"
              value={answer}
              onChange={(e) => setAnswer(e.target.value)}
            ></textarea>
          </div>
        </div>
        <div>
          <button
            onClick={handleUpdateFaq}
            className="w-[100%] h-[48px] bg-[#2796ac] hover:bg-[#1a778a] text-white uppercase"
          >
            Save
          </button>
        </div>
      </div>
    </Dialog>
  );
};

export default EditFAQSModal;
