import doctorImg from "../Assets/Images/Sports-med-Doc.jpg";

export const AboutDoctorData = {
  img: doctorImg,
  name: "Dr Pradyumna Reddy",
  overview:
    "MBBS, MD - Radio Diagnosis/Radiology, Interventional Radiologist,Radiologist,30 Years Experience Overall (24 years as a specialist)",
  contact: {
    phone: "+91 88865 66445",
    email: "rajradio@gmail.com",
  },
  avaibilities: [
    { days: "Monday - Friday", time: "09:00 - 18:00" },
    { days: "Saturday", time: "09:00 - 18:00" },
    { days: "Sunday", time: "Closed" },
  ],
  doctorDetails: [
    "Dr. K Pradyumna Reddy is one of the Best Interventional Radiologist who practices at Apollo Hospitals in Jubilee Hills, Hyderabad. He has hands-on experience of 6 years in providing various radiology services in the Interventional Radiology Department. ",
    "He completed MBBS from the prestigious SVS Medical College – Mahabubnagar in 2011 and MD in Radio Diagnosis / Radiology from MediCiti Institute of Medical Sciences in the year 2015. Later he worked as an Interventional Radiology and Musculoskeletal Radiology in Tan Tock Seng Hospital, Singapore for 1 Year.",
    "After returning to India he did a fellowship in Intervention Radiology which involve Complex Interventional procedures like endovascular aortic repair (EVAR), trans jugular intravenous portosystemic shunt (TIPS) and complex venous interventions in the year 2018 from D Y Patil University, Mumbai.",
    "He also worked Under Dr Gireesh Warawdekar in Lilavathi hospitals, Holy Family and Breach Candy Hospital etc in the year 2019.",
    "His passion and dedication towards radiology services made him one of the Best Interventional Radiologists in Hyderabad at a very young age. Over the past years, technology in radiology services has been improved effectively and some new innovative procedures also developed. Dr. Pradyumna Reddy, Interventional Radiologist  trained in all basic & advanced radiology methods and mastered performing the following procedures:",
  ],
  detailsLists: [
    "Endovenous Laser Ablation for Varicose Veins",
    "High-Intensity Focused Ultrasound Ablation of Uterine Fibroids",
    "High-Intensity Focused Ultrasound Ablation of Uterine Fibroids",
    "High-Intensity Focused Ultrasound Ablation of Uterine Fibroids",
    "High-Intensity Focused Ultrasound Ablation of Uterine Fibroids",
    "High-Intensity Focused Ultrasound Ablation of Uterine Fibroids",
    "High-Intensity Focused Ultrasound Ablation of Uterine Fibroids",
  ],
  memberships: [
    "Member of Indian Medical Association (IMA).",
    "Member of Indian Medical Association (IMA).",
    "Member of Indian Medical Association (IMA).",
    "Member of Indian Medical Association (IMA).",
    "Member of Indian Medical Association (IMA).",
    "Member of Indian Medical Association (IMA).",
  ]
};
