import React, { useState } from "react";
import ArrowComp from "./ArrowComp";
import { useNavigate } from "react-router-dom";

const AsterCarouselCard = ({ el }) => {
  const [isHovered, setIsHovered] = useState(false);
  const navigate = useNavigate();
  return (
    <div
      onClick={() => navigate(`/service/${el?.name?.split(' ').join('-')}`)}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      className="activeTranition flex flex-col justify-center h-[240px] w-[225px] px-[20px] xl:px-[40px] bg-white hover:bg-[#0653a2]  text-[#384f7e] hover:text-white hover:scale-[1.1] cursor-pointer box-content"
    >
      <img src={el?.icon} className="h-[50px] w-[50px]" alt="icon" />{" "}
      <h6
        className={`font-semibold text-[${
          isHovered ? "white" : "#0a2a6c"
        }] mt-[.625rem]`}
      >
        {el?.name}
      </h6>
      {el?.overview
        ?.join("\n")
        ?.slice(0, 80)
        ?.concat(".....")
        ?.split("\n")
        .map((o) => (
          <p className="font-[300] overflow-hidden text-[14px]">
            {o}
          </p>
        ))}
      {isHovered && (
        <div className="mt-[2.187rem]">
          <button className="flex items-center gap-[10px] text-white uppercase font-semibold">
            Know More
            <p style={{ fill: "white" }}>
              <ArrowComp />
            </p>
          </button>
        </div>
      )}
    </div>
  );
};

export default AsterCarouselCard;
