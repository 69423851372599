import React from "react";
import FacebookIconComp from "./FacebookIconComp";
import InstagramIconComp from "./InstagramIconComp";
import YoutubeIconComp from "./YoutubeIconComp";
import { useNavigate } from "react-router-dom";

const SmallDrawerComp = () => {
  const navigate = useNavigate();

  // Function to handle brochure download
  const handleDownloadBrochure = () => {
    const brochureUrl = 'https://flipbookpdf.net/web/site/367fe87d55ec82e091b7dfd259e70315669b4796202407.pdf.html';
    window.open(brochureUrl, '_blank');
  };

  return (
    <div>
      <ul className="flex flex-col">
        <li
          onClick={() => navigate("/")}
          className="h-[63px] flex items-center text-[1.625rem] text-[#0a2a6c] hover:text-[#0653a2] font-bold cursor-pointer"
        >
          Home
        </li>
        <li
          onClick={() => navigate("/about-us")}
          className="h-[63px] flex items-center text-[1.625rem] text-[#0a2a6c] hover:text-[#0653a2] font-bold cursor-pointer"
        >
          About Us
        </li>
        <li
          onClick={() => navigate("/international-patients")}
          className="h-[63px] flex items-center text-[1.625rem] text-[#0a2a6c] hover:text-[#0653a2] font-bold cursor-pointer"
        >
          International Patients
        </li>
        <li
          onClick={() => navigate("/about-doctor")}
          className="h-[63px] flex items-center text-[1.625rem] text-[#0a2a6c] hover:text-[#0653a2] font-bold cursor-pointer"
        >
          About Doctor
        </li>
        <li
          onClick={() => navigate("/doctor-talks")}
          className="h-[63px] flex items-center text-[1.625rem] text-[#0a2a6c] hover:text-[#0653a2] font-bold cursor-pointer"
        >
          Doctor Talks
        </li>
        <li
          onClick={() => navigate("/all-testimonials")}
          className="h-[63px] flex items-center text-[1.625rem] text-[#0a2a6c] hover:text-[#0653a2] font-bold cursor-pointer"
        >
          Testimonials
        </li>
        <li
          onClick={() => navigate("/all-news-events-blogs")}
          className="h-[63px] flex items-center text-[1.625rem] text-[#0a2a6c] hover:text-[#0653a2] font-bold cursor-pointer"
        >
          Blogs
        </li>
        <li
          onClick={() => navigate("/privacy-policy")}
          className="h-[63px] flex items-center text-[1.625rem] text-[#0a2a6c] hover:text-[#0653a2] font-bold cursor-pointer"
        >
          Privacy Policy
        </li>
        {/* Download Brochure Button */}
        <li
          onClick={handleDownloadBrochure}
          className="h-[63px] flex items-center text-[1.625rem] text-[#0a2a6c] hover:text-[#0653a2] font-bold cursor-pointer"
        >
          Download Brochure
        </li>
      </ul>

      {/* Follow Us Section */}
      <div className="w-[100%] h-[100%] flex flex-col items-start gap-[1rem] relative mt-[1rem]">
        <div className="flex items-center gap-[1rem] whitespace-nowrap">
          <p className="text-[#6e81a8]">Follow Us</p>
        </div>
        <div className="flex items-center gap-[2rem]">
          <a
            target="_blank"
            href="https://www.facebook.com/AvisVascularCentre"
            rel="noreferrer"
            className="icon_comp"
          >
            <FacebookIconComp />
          </a>
          <a
            target="_blank"
            href="https://www.instagram.com/avishospitals/"
            rel="noreferrer"
            className="icon_comp"
          >
            <InstagramIconComp />
          </a>
          <a
            target="_blank"
            href="https://www.youtube.com/channel/UCDHD4EJh3hMu3Znx3eSDdTA"
            rel="noreferrer"
            className="icon_comp"
          >
            <YoutubeIconComp />
          </a>
        </div>
      </div>
    </div>
  );
};

export default SmallDrawerComp;
