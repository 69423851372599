import React from "react";
import ViewAllBtn from "../../Common/ViewAllBtn";

const TopSection = () => {
  return (
    <div className="max-w-[1140px] mx-auto">
      <h1 className="text-[1.875rem] lg:text-[2.875rem] font-semibold text-[#0a2a6c]">
        Our Doctors
      </h1>
      <div className="flex justify-between items-center">
        <div className="max-w-[915px] text-[#384f7e]">
          <p className="leading-[1.5rem]">
            Our distinguished team of healthcare experts has an unwavering commitment to excellence. Your health deserves nothing
            less than the best, and that's precisely what we deliver.
          </p>
        </div>
        <div className="hidden md:block">
          <ViewAllBtn
            text_class={"our_doctor_view_btn"}
            arrow_class={"our_doctor_view_arrow"}
            route_link={"/all-doctors"}
          />
        </div>
      </div>
    </div>
  );
};

export default TopSection;
