import React from "react";
import ContactTop from "./ContactTop";
import ContactBottom from "./ContactBottom";

const ContactSection = ({ data }) => {
  return (
    <div className="grow px-[35px] pt-[25px] pb-[3rem] lg:pb-0 bg-[#0653a2]">
      <ContactTop data={data} />
      <ContactBottom data={data} />
    </div>
  );
};

export default ContactSection;
