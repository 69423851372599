import React from "react";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import ViewAllBtn from "../Common/ViewAllBtn";
import CarouselArrowBtns2 from "../Common/CarouselArrowBtns2";
import { ourAffiliateData } from "../../utils/OurAffilitateData";
import OurAffiliateCard from "./OurAffliateCard";

const OurAffiliate = () => {
  return (
    <div id="our_affiliates" className="bg-[#0653a2] px-[20px] xl:px-0">
      <div className="max-w-[1140px] py-[48px] mx-auto">
        <h1 className="text-[2rem] lg:text-[2.875rem] text-white font-semibold">
          Our Affiliates
        </h1>
        <p className="text-white">
          At Avis Hospitals, we take immense pride in our unwavering commitment
          to a set of core values that serve as the bedrock of our
          organizational culture. These values not only shape our behavior but
          also steer our decision-making processes, creating a distinctive ethos
          that permeates every facet of our institution and is embraced by each
          member of the Avis family.
        </p>
        {/* ///// card section ///// */}
        <div className="hidden md:grid grid-cols-3 gap-[54px] py-[1.5rem]">
          {ourAffiliateData?.map((el) => (
            <OurAffiliateCard el={el} />
          ))}
        </div>
        <div className="block md:hidden py-[1.5rem]">
          <Swiper
            navigation={{
              nextEl: ".provides-arrow-right",
              prevEl: ".provides-arrow-left",
            }}
            className="mySwiper"
            modules={[Navigation]}
            breakpoints={{
              0: {
                slidesPerView: 1.18,
              },
            }}
          >
            {ourAffiliateData?.map((el) => (
              <SwiperSlide>
                <OurAffiliateCard el={el} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div className="block md:hidden">
          <CarouselArrowBtns2
            left_class="provides-arrow-left"
            right_class="provides-arrow-right"
            children={
              <ViewAllBtn
                text_class={"provides_view_btn"}
                arrow_class={"provides_view_arrow"}
              />
            }
          />
        </div>
      </div>
    </div>
  );
};

export default OurAffiliate;
