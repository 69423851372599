import React from "react";
import { useNavigate } from "react-router-dom";

const ServiceAllCard = ({ el }) => {
  const navigate = useNavigate()
  return (
    <div onClick={()=>navigate(`/service/${el?.name?.split(' ').join('-')}`)} className="flex flex-col md:flex-row items-start gap-[32px] cursor-pointer">
      <div className="bg-white min-w-[174px] h-[152px] flex justify-center items-center">
        <img src={el?.icon} className="h-[50px] w-[50px]" alt="icon" />
      </div>
      <div>
        <h6
          className={`text-[1.375rem] text-[#0a2a6c] font-semibold mt-[.625rem]`}
        >
          {el?.name}
        </h6>
        <div className="h-[106px] leading-[2.7ex] overflow-hidden">
          {el?.overview?.map((o) => (
            <p className="font-[300] overflow-hidden text-[14px]">{o}</p>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ServiceAllCard;
