import React, { useEffect, useState } from "react";
import { Dialog } from "@mui/material";
import { BsImage } from "react-icons/bs";
import { MdOutlineClose } from "react-icons/md";
import { useUpdateServiceMutation } from "../../../services/serviceApi";
import { SuccessAlertFunction } from "../../Common/SuccessAlert";
import { FailedAlertFunction } from "../../Common/FailedAlertFunction";
import BackDropComponent from "../../Common/BackDropComponent";
import axios from "axios";

const EditServicesModal = ({ row, showEditableModal, setEditableModal }) => {
  const [overview, setOverview] = useState("");
  const [name, setName] = useState("");
  const [image, setImage] = useState("");
  const [icon, setIcon] = useState("");
  const [youtubeLink, setYoutubeLink] = useState("");
  const [updateService] = useUpdateServiceMutation();
  const [showBackdrop, setBackdrop] = useState(false);
  useEffect(() => {
    setName(row?.name);
    setOverview(row?.overview?.join("\n"));
    setIcon(row?.icon);
    setImage(row?.image);
    setYoutubeLink(row?.youtubeLink);
  }, [row]);

  const handleBannersUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("upload_preset", process.env.REACT_APP_PRESET);
      try {
        setBackdrop(true);
        const res = await axios.post(
          `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDNAME}/image/upload`,
          formData
        );
        setBackdrop(false);
        setImage(res.data.secure_url);
      } catch (error) {
        setBackdrop(false);
        console.log(error.message);
      }
    }
  };

  const handleIconUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("upload_preset", process.env.REACT_APP_PRESET);
      try {
        setBackdrop(true);
        const res = await axios.post(
          `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDNAME}/image/upload`,
          formData
        );
        setBackdrop(false);
        setIcon(res.data.secure_url);
      } catch (error) {
        setBackdrop(false);
        console.log(error.message);
      }
    }
  };

  const handleUpdateService = () => {
    if (image && name && overview && icon && youtubeLink) {
      setBackdrop(true);
      updateService({
        id: row?._id,
        data: {
          image,
          name,
          overview: overview?.split("\n"),
          icon,
          youtubeLink,
        },
      })
        .then((res) => {
          if (res?.data?.status) {
            setImage("");
            setName("");
            setOverview("");
            setIcon("");
            setYoutubeLink("");
            setEditableModal(false);
            SuccessAlertFunction(res?.data?.message);
            setBackdrop(false);
          } else {
            FailedAlertFunction(res?.data?.message);
            setBackdrop(false);
          }
        })
        .catch((error) => {
          FailedAlertFunction(error?.message);
          setBackdrop(false);
        });
    } else {
      FailedAlertFunction("Please fill all the fields!");
    }
  };

  return (
    <Dialog maxWidth="lg" open={showEditableModal}>
      <div className="flex flex-col gap-[2rem] w-[100%] md:w-[600px] bg-white px-[2rem] py-[3rem] relative">
        {/* close modal button  */}
        <div className="absolute top-[.8rem] right-[1rem]">
          <MdOutlineClose
            onClick={() => setEditableModal(false)}
            className="text-[1.5rem] text-red-500 cursor-pointer"
          />
        </div>
        <div className="flex flex-col mb-[15px]">
          <label className="text-[13px]" htmlFor="service_name">
            Service
          </label>
          <input
            type="text"
            className="w-[100%] h-[48px] p-[1rem] border focus:outline-none"
            placeholder="Service Name"
            name="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>

        <div className="flex flex-col mb-[15px]">
          <label className="text-[13px]" htmlFor="youtubeLink">
            Video Link
          </label>
          <input
            type="text"
            className="w-[100%] h-[48px] p-[1rem] border focus:outline-none"
            placeholder="Video Link"
            name="name"
            value={youtubeLink}
            onChange={(e) => setYoutubeLink(e.target.value)}
          />
        </div>

        <div className="flex flex-col mb-[15px]">
          <label className="text-[13px]" htmlFor="overview">
            Overview
          </label>
          <textarea
            className="border w-[100%] min-h-[100px] max-h-[100px] focus:outline-none p-[1rem]"
            name="overview"
            id="overview"
            cols="30"
            placeholder="Overview"
            value={overview}
            onChange={(e) => setOverview(e.target.value)}
          ></textarea>
        </div>
        <div className="flex flex-col mb-[15px]">
          <label className="text-[13px]">Service Icon</label>
          <div className="mb-[15px]">
            <label
              className="text-[1.5rem] flex items-center gap-[.5rem] justify-center w-[100%] h-[100px] bg-[#f3f3f3] cursor-pointer"
              htmlFor="iconUpdate"
            >
              <BsImage /> Upload Icon
              <input
                onChange={handleIconUpload}
                className="hidden"
                type="file"
                name="iconUpdate"
                id="iconUpdate"
              />
            </label>
          </div>
          <div className="flex justify-center">
            {icon && (
              <img
                src={icon}
                className="h-[150px] w-[150px]"
                alt={"bannerImage"}
              />
            )}
          </div>
        </div>
        <div className="flex flex-col mb-[15px]">
          <label className="text-[13px]">Upload Banner</label>
          <div className="mb-[15px]">
            <label
              className="text-[1.5rem] flex items-center gap-[.5rem] justify-center w-[100%] h-[100px] bg-[#f3f3f3] cursor-pointer"
              htmlFor="imageUpdate"
            >
              <BsImage /> Upload Banner
              <input
                onChange={handleBannersUpload}
                className="hidden"
                type="file"
                name="imageUpdate"
                id="imageUpdate"
              />
            </label>
          </div>
          <div className="flex justify-center">
            {image && (
              <img
                src={image}
                className="h-[150px] w-[290px]"
                alt={"bannerImage"}
              />
            )}
          </div>
        </div>
        <div>
          <button
            onClick={handleUpdateService}
            className="w-[100%] h-[48px] bg-[#2796ac] hover:bg-[#1a778a] text-white uppercase"
          >
            Save
          </button>
        </div>
      </div>
      <BackDropComponent state={showBackdrop} />
    </Dialog>
  );
};

export default EditServicesModal;
