import React, { useState } from "react";

import { BsImage } from "react-icons/bs";
import { useAddNewsMutation } from "../../../services/newsApi";
import BackDropComponent from "../../Common/BackDropComponent";
import { SuccessAlertFunction } from "../../Common/SuccessAlert";
import { FailedAlertFunction } from "../../Common/FailedAlertFunction";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
const InputesFields = () => {
  const [heading, setHeading] = useState("");
  const [overview, setOverview] = useState("");
  const [image, setImage] = useState("");
  const [category, setCategory] = useState("");
  const [writer, setWriter] = useState("");
  const [showBackdrop, setBackdrop] = useState(false);
  const [addNews] = useAddNewsMutation();
  const [language, setLanguage] = useState("English");
  const languages = ["English", "Hindi", "Telugu", "Tamil", "Marathi", "Kannada", "Bengali"];

  // Define a function to handle changes in the editor content
  const handleEditorChange = (html) => {
    setOverview(html);
  };
  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const file = e.target.files[0];
      const formData = new FormData();
      formData.append("file", file);
      formData.append("upload_preset", process.env.REACT_APP_PRESET);
      try {
        setBackdrop(true);
        const res = await axios.post(
          `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDNAME}/image/upload`,
          formData
        );
        setBackdrop(false);
        setImage(res.data.secure_url);
      } catch (error) {
        setBackdrop(false);
        console.log(error.message);
      }
    }
  };

  const handleSave = () => {
    const data = {
      heading,
      overview: overview,
      image,
      category,
      writer,
      language,
    };
    if (heading && overview && image && category && writer && language) {
      setBackdrop(true);
      addNews(data)
        .then((res) => {
          if (res?.data?.status) {
            setHeading("");
            setOverview("");
            setCategory("");
            setImage("");
            setWriter("");
            setLanguage("English");
            // toast.success();
            SuccessAlertFunction(res?.data?.message);
            setBackdrop(false);
          } else {
            FailedAlertFunction(res?.data?.message);
            setBackdrop(false);
          }
        })
        .catch((error) => {
          FailedAlertFunction(error?.message);
          setBackdrop(false);
        });
    } else {
      FailedAlertFunction("Please fill the the fields!!");
    }
  };

  return (
    <div className="flex flex-col gap-[1rem]">
      <div className="flex flex-col">
        <label className="text-[13px] font-bold" htmlFor="heading">
          News Heading
        </label>
        <input
          type="text"
          className="w-[100%] h-[48px] bg-[#fff] p-[1rem] border focus:outline-none"
          placeholder="Heading"
          name="heading"
          value={heading}
          onChange={(e) => setHeading(e.target.value)}
        />
      </div>
      <div className="flex flex-col">
        <label className="text-[13px] font-bold" htmlFor="category">
          News Category
        </label>
        <input
          type="text"
          className="w-[100%] h-[48px] bg-[#fff] p-[1rem] border focus:outline-none"
          placeholder="News/Blog"
          name="category"
          value={category}
          onChange={(e) => setCategory(e.target.value)}
        />
      </div>
      <div className="flex flex-col">
        <label className="text-[13px] font-bold" htmlFor="category">
          Language
        </label>
        <div class="relative inline-block w-[100%]">
          <select
            value={language}
            onChange={(e) => setLanguage(e.target.value)}
            class="block appearance-none w-[100%] h-[40px] px-[20px] py-[2px] text-[.875rem] text-[#000] bg-[#ffffff] outline-none"
          >
            {languages?.map((d) => (
              <option value={d}>{d}</option>
            ))}
          </select>
          <div class="pointer-events-none absolute inset-y-0 right-[10px] flex items-center px-2 text-[#6e81a8]">
            <FontAwesomeIcon icon={faCaretDown} />
          </div>
        </div>
      </div>

      <div className="flex flex-col">
        <label className="text-[13px] font-bold" htmlFor="writer">
          News Writer
        </label>
        <input
          type="text"
          className="w-[100%] h-[48px] bg-[#fff] p-[1rem] border focus:outline-none"
          placeholder="News Writer"
          name="writer"
          value={writer}
          onChange={(e) => setWriter(e.target.value)}
        />
      </div>
      <div className="h-[250px]">
        <label className="text-[13px] font-bold" htmlFor="overview">
          News Description
        </label>

        <ReactQuill
          value={overview}
          onChange={handleEditorChange}
          style={{ height: "200px" }}
        />
      </div>

      <div className="mt-[20px]">
        <label
          className="text-[1.5rem] flex items-center gap-[.5rem] justify-center w-[100%] h-[100px] bg-[white] cursor-pointer"
          htmlFor="image"
        >
          <BsImage /> Upload Image
          <input
            onChange={handleImageUpload}
            className="hidden"
            type="file"
            name="image"
            id="image"
          />
        </label>
      </div>
      <div>
        {image && (
          <img
            src={image}
            className="h-[150px] w-[290px]"
            alt={"bannerImage"}
          />
        )}
      </div>
      <div>
        <button
          onClick={handleSave}
          className="w-[100%] h-[48px] bg-[#2796ac] hover:bg-[#1a778a] text-white uppercase"
        >
          Save
        </button>
      </div>
      <BackDropComponent state={showBackdrop} />
    </div>
  );
};

export default InputesFields;
