import React, { useEffect, useState } from "react";
import { BsArrowRight } from "react-icons/bs";
import AutoCompleteInput, { AutoCompleteService } from "./AutoFilledInput";
import { useDoctorsQuery } from "../../services/doctorApi";
import { useNavigate } from "react-router";
import { useBranchesQuery } from "../../services/branchApi";

const AppointmentSerach = () => {
  const [selected, setSelected] = useState("");
  const [doctorSelected, setDoctorSelected] = useState("");
  const { data: Doctors } = useDoctorsQuery({ page: "00", perPage: "00" });
  const { data } = useBranchesQuery({ page: "00", perPage: "00" });
  const [doctorsByCity, setDoctorsByCity] = useState([]);
  const [cityByDoctors, setCityByDoctors] = useState([]);
  const navigate = useNavigate();
  const handleNavigate = () => {
    if(selected?._id && doctorSelected?._id){
      console.log("Hello")
      navigate(`/doctor/${doctorSelected?.doctorName?.split(" ").join("-")}`);
    }else{
      if (selected) {
        navigate(`/branch/${selected?.name.split(' ').join('-')}/${selected?.city.split(' ').join('-')}`);
      }
      if (doctorSelected) {
        navigate(`/doctor/${doctorSelected?.doctorName?.split(" ").join("-")}`);
      }
    }
  };

  useEffect(() => {
    if (!selected) {
      setDoctorsByCity(Doctors?.data);
    } else {
      const tempDoctors = [];
      Doctors?.data?.forEach((element) => {
        console.log(element?.hospital?.split(" "))
        if (element?.hospital?.split(" ").includes(selected?.city)|| element?.hospital?.split(" ").includes(selected?.city.split(' ')[0])) {
          tempDoctors.push(element);
        }
      });
      setDoctorsByCity(tempDoctors);
    }
  }, [Doctors, selected]);

  useEffect(() => {
    if (!doctorSelected) {
      setCityByDoctors(data?.data);
    } else {
      const tempCity = [];
      data?.data?.forEach((element) => {
        // console.log(element)
        if (doctorSelected?.hospital?.split(" ").includes(element?.city) || doctorSelected?.hospital?.split(" ").includes(element?.city.split(' ')[0])) {
          tempCity.push(element);
        }
      });
      console.log(tempCity);
      setCityByDoctors(tempCity);
    }
  }, [data?.data, doctorSelected]);

  return (
    <div className="searchBox flex flex-col lg:flex-row items-center lg:items-center justify-between gap-[1rem] lg:gap-0 w-[100%] lg:w-[96%] xl:max-w-[1140px] h-[100%] lg:h-[82px] px-[20px] lg:px-0 py-[2rem] lg:py-0 lg:pl-[32px] bg-[#f2f2f2] lg:bg-[rgba(255,_255,_255,_0.9)] relative lg:absolute left-[50%] lg:top-[400px] xl:top-[550px] translate-x-[-50%] z-[20] shadow-[0_3px_10px_rgb(0,0,0,0.2)] rounded">
      <div className="w-[100%] h-[100%] lg:h-[40px] flex flex-col items-center lg:flex-row lg:items-center">
        <label
          className="w-fit lg:w-[100px] xl:w-[130px] text-[.875rem] text-[#0a2a6c] font-semibold"
          htmlFor="specialities"
        >
          Branches
        </label>
        <div className="flex items-center justify-center w-[100%] lg:w-[223px] xl:w-[280px] h-[100%] relative">
          <AutoCompleteService
            data={cityByDoctors}
            setSelected={setSelected}
            setDoctorSelected={setDoctorSelected}
          />
        </div>
      </div>
      <div className="w-[100%] h-[100%] lg:h-[40px] flex flex-col lg:flex-row items-center lg:items-center">
        <label
          className="w-fit lg:w-[100px] xl:w-[130px] text-[.875rem] text-[#0a2a6c] font-semibold"
          htmlFor="findDoctor"
        >
          Find A Doctor
        </label>
        <div className="flex items-center justify-center lg:justify-start w-[100%] lg:w-[223px] xl:w-[280px] h-[100%] relative">
          <AutoCompleteInput
            data={doctorsByCity}
            setSelected={setSelected}
            setDoctorSelected={setDoctorSelected}
          />
        </div>
      </div>

      <div
        onClick={handleNavigate}
        className="w-[150px] lg:w-fit h-[100%] rounded ml-[20px]"
      >
        <button className="flex items-center justify-center gap-[12px] w-[100%] lg:w-[155px] h-[100%] py-[6px] lg:py-0 bg-[#0653a2] text-[1.35rem] font-bold uppercase text-white rounded">
          Search
          <BsArrowRight />
        </button>
      </div>
    </div>
  );
};

export default AppointmentSerach;
