import React, { useState } from "react";
import Drawer from "@mui/material/Drawer";
import DrawerLeft from "./DrawerLeft";
import DrawerFollowUs from "./DrawerFollowUs";
import SmallDrawerComp from "./SmallDrawerComp";
import { TfiClose } from "react-icons/tfi";

const DrawerComp = ({ show, setShow }) => {
  const [activeMid, setActiveMid] = useState(null);
  return (
    <div>
      <Drawer anchor="right" open={show} onClose={() => setShow(false)}>
        <div className="hidden lg:flex w-[365px] h-[100%]">
          <DrawerLeft activeMid={activeMid} setActiveMid={setActiveMid} />
          {/* <DrawerMid activeMid={activeMid} /> */}
          <DrawerFollowUs />
        </div>
        <div className="w-[100vw] block lg:hidden px-[20px] py-[50px] relative">
          <TfiClose
            onClick={() => setShow(false)}
            className="absolute top-[1rem] right-[1rem] text-[1.5rem]"
            style={{ fill: "#212529" }}
          />
          <SmallDrawerComp />
        </div>
      </Drawer>
    </div>
  );
};

export default DrawerComp;
