import React from "react";

const YoutubeIconComp = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20.214"
      height="15.924"
      viewBox="0 0 20.214 15.924"
    >
      <path
        id="Youtube_copy"
        data-name="Youtube copy"
        d="M10.1,15.924c-.2,0-4.937-.035-6.443-.2l-.041,0-.043-.011c-.048-.011-.133-.023-.224-.035A3.377,3.377,0,0,1,1.121,14.59,5.409,5.409,0,0,1,.2,12.165l0-.014a32.465,32.465,0,0,1-.2-3.4V7.207A33.472,33.472,0,0,1,.2,3.773,5,5,0,0,1,1.135,1.32,3.273,3.273,0,0,1,3.427.238L3.491.232C6.1.036,10.061,0,10.1,0h.012c.04,0,4,.036,6.61.232h.014l.078.006a3.133,3.133,0,0,1,2.277,1.094,5.413,5.413,0,0,1,.92,2.424v.016a33.15,33.15,0,0,1,.2,3.434V8.749a32.926,32.926,0,0,1-.2,3.4v.016a5.409,5.409,0,0,1-.923,2.428l0,.007a3.275,3.275,0,0,1-2.3,1.087l-.051,0c-2.624.23-6.467.234-6.63.234ZM3.6,1.714l-.051.005a1.826,1.826,0,0,0-1.342.609L2.2,2.338a3.584,3.584,0,0,0-.549,1.609c0,.016-.188,1.652-.188,3.26V8.749a31.8,31.8,0,0,0,.187,3.217A3.98,3.98,0,0,0,2.216,13.6l.005.005a1.935,1.935,0,0,0,1.332.594c.106.014.207.029.3.049,1.464.153,6.122.188,6.254.188.114,0,3.968,0,6.5-.227l.056-.005A1.827,1.827,0,0,0,18,13.6a4.017,4.017,0,0,0,.561-1.631c.015-.128.188-1.7.188-3.218V7.207c0-1.551-.174-3.122-.188-3.251A3.991,3.991,0,0,0,18,2.322l0-.005A1.675,1.675,0,0,0,16.7,1.722l-.093-.008c-2.517-.189-6.326-.225-6.5-.226S6.121,1.525,3.6,1.714ZM7.359,4.775l5.5,3.26-5.5,3.112Z"
      />
    </svg>
  );
};

export default YoutubeIconComp;
