import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl } from "./api";
const getTokenFromLocalStorage = () => {
  return localStorage.getItem("token");
};
export const appointmentApi = createApi({
  reducerPath: "appointmentApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseUrl}/appointment`,
    prepareHeaders: (headers) => {
      const token = getTokenFromLocalStorage();
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["Appointment"],
  endpoints: (builder) => ({
    appointments: builder.query({
      query: (data) => `/?page=${data?.page}&limit=10&search=${data?.search}`,
      providesTags: ["Appointment"],
    }),
    getAllAppointments: builder.query({
      query: () => `/getAllAppointments`,
      providesTags: ["Appointment"],
    }),
    getAppointmentById: builder.query({
      query: (id) => `/${id}`,
      providesTags: ["Appointment"],
    }),
    addAppointment: builder.mutation({
      query: (data) => ({
        url: "/",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Appointment"],
    }),
    deleteAppointmentById: builder.mutation({
      query: (id) => ({
        url: `/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Appointment"],
    }),
    updateAppointment: builder.mutation({
      query: ({ id, data }) => ({
        url: `/${id}`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["Appointment"],
    }),
  }),
});
export const {
  useAppointmentsQuery,
  useAddAppointmentMutation,
  useGetAppointmentByIdQuery,
  useUpdateAppointmentMutation,
  useDeleteAppointmentByIdMutation,
  useGetAllAppointmentsQuery
} = appointmentApi;
