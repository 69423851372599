import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl } from "./api";
const getTokenFromLocalStorage = () => {
  return localStorage.getItem("token");
};
export const doctorApi = createApi({
  reducerPath: "doctorApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseUrl}/doctors`,
    prepareHeaders: (headers) => {
      const token = getTokenFromLocalStorage();
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["Doctor"],
  endpoints: (builder) => ({
    doctors: builder.query({
      query: ({ page, perPage }) => `/?page=${page}&perPage=${perPage}`,
      providesTags: ["Doctor"],
    }),
    getDoctorById: builder.query({
      query: (id) => `/${id}`,
      providesTags: ["Doctor"],
    }),
    getDoctorByName: builder.query({
      query: (name) => `/getDoctorByName/${name}`,
      providesTags: ["Service"],
    }),
    addDoctor: builder.mutation({
      query: (data) => ({
        url: "/",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Doctor"],
    }),
    deleteDoctorById: builder.mutation({
      query: (id) => ({
        url: `/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Doctor"],
    }),
    updateDoctor: builder.mutation({
      query: ({ id, data }) => ({
        url: `/${id}`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["Doctor"],
    }),
  }),
});
export const {
  useDoctorsQuery,
  useAddDoctorMutation,
  useGetDoctorByIdQuery,
  useUpdateDoctorMutation,
  useDeleteDoctorByIdMutation,
  useGetDoctorByNameQuery
} = doctorApi;
