import React from "react";
import FacebookIconComp from "./FacebookIconComp";
import InstagramIconComp from "./InstagramIconComp";
import YoutubeIconComp from "./YoutubeIconComp";

const DrawerFollowUs = () => {
  return (
    <div className="w-[80px] h-[100%] flex flex-col justify-center items-center gap-[2rem] relative">
      <div className="flex flex-col items-center gap-[2rem]">
      <a target="_blank" href="https://www.facebook.com/AvisVascularCentre" rel="noreferrer" className="icon_comp">
            <FacebookIconComp />
          </a>
          <a target="_blank" href="https://www.instagram.com/avishospitals/" rel="noreferrer"  className="icon_comp">
            <InstagramIconComp />
          </a>
        
          <a  target="_blank" href="https://www.youtube.com/channel/UCDHD4EJh3hMu3Znx3eSDdTA" rel="noreferrer" className="icon_comp">
            <YoutubeIconComp />
          </a>
      </div>
      <div className="flex items-center gap-[1rem] whitespace-nowrap mt-[3rem]" style={{transform: "rotate(270deg)"}}>
        <p className="text-[#6e81a8]">Follow Us</p>
        <p className="h-[1px] w-[30px] bg-[#6e81a8]"></p>
      </div>
    </div>
  );
};

export default DrawerFollowUs;
