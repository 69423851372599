import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl } from "./api";
const getTokenFromLocalStorage = () => {
  return localStorage.getItem("token");
};
export const newsApi = createApi({
  reducerPath: "newsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseUrl}/news`,
    prepareHeaders: (headers) => {
      const token = getTokenFromLocalStorage();
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["News"],
  endpoints: (builder) => ({
    news: builder.query({
      query: ({ page, perPage, language }) =>
        `/?page=${page}&perPage=${perPage}&language=${language}`,
      providesTags: ["News"],
    }),
    getNewsById: builder.query({
      query: (id) => `/${id}`,
      providesTags: ["News"],
    }),
    getNewsByName: builder.query({
      query: (name) => `/getNewsByName?heading=${name}`,
      providesTags: ["News"],
    }),
    deleteNewsById: builder.mutation({
      query: (id) => ({
        url: `/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["News"],
    }),
    addNews: builder.mutation({
      query: (data) => ({
        url: "/",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["News"],
    }),
    updateNews: builder.mutation({
      query: ({ id, data }) => ({
        url: `/${id}`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["News"],
    }),
  }),
});
export const {
  useNewsQuery,
  useAddNewsMutation,
  useGetNewsByIdQuery,
  useUpdateNewsMutation,
  useDeleteNewsByIdMutation,
  useGetNewsByNameQuery,
} = newsApi;
