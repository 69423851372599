import React from "react";
import TestimonialsInputes from "../../../Components/Dashboard/Manage_Testimonials/TestimonialsInputes";
import TestimonialTable from "../../../Components/Dashboard/Manage_Testimonials/TestimonialsTabel";

const ManageTestimonials = () => {
  return (
    <div className="container flex flex-col gap-[3rem] mx-auto my-[15px]">
      <div className="bg-[#ececec] p-[15px] rounded overflow-hidden mb-[30px]">
        <h3 className="mb-[20px] text-[24px] font-bold">Add Testimonial</h3>
        <TestimonialsInputes />
      </div>
      <div className="bg-[#ececec] p-[15px] rounded overflow-hidden">
        <h3 className="mb-[20px] text-[24px] font-bold"> Testimonials List</h3>
        <TestimonialTable />
      </div>
    </div>
  );
};

export default ManageTestimonials;
