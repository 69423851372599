import React from "react";
import { ourValuesData } from "../../utils/OurValuesData";

const OurValues = () => {
  return (
    <div className="max-w-[1140px] py-[48px] mx-auto px-[20px] xl:px-0">
      <h1 className="text-[2rem] xl:text-[2.875rem] text-[#0a2a6c] font-semibold">
        Our Values
      </h1>
      <p className="text-[#384f7e]">
        At Avis Vascular Centre, our commitment to healthcare excellence is
        rooted in a set of core values that guide every aspect of our work.
        These values reflect our unwavering dedication to our patients, our
        community, and the pursuit of medical excellence:
      </p>
      {/* ///// quotes section ///// */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-[1.5rem] mt-[2rem]">
        {ourValuesData?.map((el) => {
          return (
            <div className="flex items-center justify-between flex-col lg:flex-row gap-[2rem] bg-[#f2f2f2] py-[34px] pl-[31px] pr-[27px]">
              <div className="lg:order-1 order-2">
                <h4 className="text-[1.625rem] text-[#0a2a6c] font-semibold">
                  {el?.title}
                </h4>
                <p className="text-[#445a86] leading-[1.5rem]">{el?.text}</p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default OurValues;
