// import React, { useEffect } from "react";
// import NavBar from "../Components/Common/NavBar";
// import Footer from "../Components/Footer/Footer";
// import SkeletonComponent from "../Components/Common/SkeletonComponent";
// import { useDoctorsQuery } from "../services/doctorApi";
// import { Button, CardFooter, Typography } from "@material-tailwind/react";
// import Loading from "../Components/Common/Loading";
// import DoctorAllCard from "../Components/Common/DoctorAllCard";

// const All_Doctors = () => {
//   const [currentPage, setCurrentPage] = React.useState(1);
//   const { data, isLoading } = useDoctorsQuery({
//     page: currentPage,
//     perPage: 6,
//   });

//   useEffect(() => {
//     document.title = "Our Doctors - Avis Hospital";
//   }, []);

//   return (
//     <div>
//       <NavBar />
//       {/* ///// page components ///// */}
//       {/* //// heading section ///// */}
//       <div className="bg-[whitesmoke] px-[20px] xl:px-0 py-[3rem]">
//         <div className="max-w-[1140px] mx-auto">
//           <h1 className="text-[1.875rem] lg:text-[2.875rem] font-semibold text-[#0a2a6c]">
//             Our Doctors
//           </h1>
//           <div className="flex justify-between items-center">
//             <div className="max-w-[915px] text-[#384f7e]">
//               <p className="leading-[1.5rem]">
//                 Our distinguished team of healthcare experts has an unwavering commitment to excellence. Your health deserves nothing less than the best, and that's precisely what we deliver.
//               </p>
//             </div>
//           </div>
//         </div>
//         {isLoading ? (
//           <Loading />
//         ) : (
//           <>
//             <div>
//               <div className="max-w-[1140px] mx-auto py-[3rem] px-[20px] xl:px-0">
//                 {/* ///// ALL DOCTORS ///// */}
//                 <div className="grid grid-cols-1 gap-y-[50px] min-h-[60vh]">
//                   {data?.data?.length
//                     ? data?.data?.map((el) => (
//                         <div key={el.id}>
//                           <DoctorAllCard el={el} />
//                         </div>
//                       ))
//                     : [1, 2, 3, 4, 5].map((el) => <SkeletonComponent key={el} />)}
//                 </div>

//                 {/* ///// PAGINATION OPTIONS ///// */}
//                 <CardFooter className="flex items-center justify-between p-4 mt-[3rem]">
//                   <Typography
//                     variant="small"
//                     color="blue-gray"
//                     className="font-[500] text-[1rem] whitespace-nowrap mr-[1rem]"
//                   >
//                     Page {currentPage} of {data?.totalPages}
//                   </Typography>
//                   <div className="flex gap-2">
//                     {currentPage > 1 ? (
//                       <Button
//                         onClick={() => setCurrentPage(currentPage - 1)}
//                         variant="outlined"
//                         size="sm"
//                       >
//                         Previous
//                       </Button>
//                     ) : (
//                       <Button disabled variant="outlined" size="sm">
//                         Previous
//                       </Button>
//                     )}
//                     {currentPage < data?.totalPages ? (
//                       <Button
//                         onClick={() => setCurrentPage(currentPage + 1)}
//                         variant="outlined"
//                         size="sm"
//                       >
//                         Next
//                       </Button>
//                     ) : (
//                       <Button disabled variant="outlined" size="sm">
//                         Next
//                       </Button>
//                     )}
//                   </div>
//                 </CardFooter>
//               </div>
//             </div>
//           </>
//         )}
//       </div>
//       <Footer />
//     </div>
//   );
// };

// export default All_Doctors;

import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import NavBar from "../Components/Common/NavBar";
import Footer from "../Components/Footer/Footer";
import SkeletonComponent from "../Components/Common/SkeletonComponent";
import { useDoctorsQuery } from "../services/doctorApi";
import { Button, CardFooter, Typography } from "@material-tailwind/react";
import Loading from "../Components/Common/Loading";
import DoctorAllCard from "../Components/Common/DoctorAllCard";

const All_Doctors = () => {
  const [currentPage, setCurrentPage] = React.useState(1);
  const { data, isLoading } = useDoctorsQuery({
    page: currentPage,
    perPage: 6,
  });

  useEffect(() => {
    document.title = "Our Doctors - Avis Hospital";
  }, []);

  return (
    <div>
      <Helmet>
        <title>Our Doctors - Avis Hospital</title>
        <meta name="description" content="Meet our team of highly skilled varicose veins doctors at Avis Hospital, committed to providing the best healthcare services." />
        <meta name="keywords" content=" Varicose Veins Surgeons in India,Best Varicose Veins Doctors,Top Varicose Veins Specialists,Varicose Veins Doctors India,Leading Varicose Vein Experts" />
      </Helmet>

       <NavBar />
      <div className="bg-[whitesmoke] px-[20px] xl:px-0 py-[3rem]">
        <div className="max-w-[1140px] mx-auto">
          <h1 className="text-[1.875rem] lg:text-[2.875rem] font-semibold text-[#0a2a6c]">
            Our Doctors
          </h1>
          <div className="flex justify-between items-center">
            <div className="max-w-[915px] text-[#384f7e]">
              <p className="leading-[1.5rem]">
                Our distinguished team of healthcare experts has an unwavering commitment to excellence. Your health deserves nothing less than the best, and that's precisely what we deliver.
              </p>
            </div>
          </div>
        </div>
        {isLoading ? (
          <Loading />
        ) : (
          <>
            <div>
              <div className="max-w-[1140px] mx-auto py-[3rem] px-[20px] xl:px-0">
                <div className="grid grid-cols-1 gap-y-[50px] min-h-[60vh]">
                  {data?.data?.length
                    ? data?.data?.map((el) => (
                        <div key={el.id}>
                          <DoctorAllCard el={el} />
                        </div>
                      ))
                    : [1, 2, 3, 4, 5].map((el) => <SkeletonComponent key={el} />)}
                </div>

                <CardFooter className="flex items-center justify-between p-4 mt-[3rem]">
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-[500] text-[1rem] whitespace-nowrap mr-[1rem]"
                  >
                    Page {currentPage} of {data?.totalPages}
                  </Typography>
                  <div className="flex gap-2">
                    {currentPage > 1 ? (
                      <Button
                        onClick={() => setCurrentPage(currentPage - 1)}
                        variant="outlined"
                        size="sm"
                      >
                        Previous
                      </Button>
                    ) : (
                      <Button disabled variant="outlined" size="sm">
                        Previous
                      </Button>
                    )}
                    {currentPage < data?.totalPages ? (
                      <Button
                        onClick={() => setCurrentPage(currentPage + 1)}
                        variant="outlined"
                        size="sm"
                      >
                        Next
                      </Button>
                    ) : (
                      <Button disabled variant="outlined" size="sm">
                        Next
                      </Button>
                    )}
                  </div>
                </CardFooter>
              </div>
            </div>
          </>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default All_Doctors;
