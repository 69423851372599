import React from "react";
import FAQsAccordions from "../FAQs/FAQsAccordions";

const FAQsSection = () => {
  return (
    <div className="bg-[#f2f2f2]">
      <div className="max-w-[1140px] mx-auto py-[48px] px-[20px] xl:px-0">
        <h1 className="text-[1.875rem] lg:text-[2.875rem] text-[#0a2a6c] font-semibold">
          FAQs
        </h1>
        <p className="text-[#384f7e]">
        Want to find out more about varicose veins treatment? The answers to your questions can be found below.
        </p>
        <FAQsAccordions />
      </div>
    </div>
  );
};

export default FAQsSection;
