import React from 'react';

const Header = () => {
    return (
        <div className='w-[100%] h-[10vh] bg-[#0653a2]'>
            
        </div>
    );
};

export default Header;