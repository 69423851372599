import React from "react";

const options = [
  {
    phone: "9989527715",
    branch: "Telangana & AP",
  },
  {
    phone: "8088837000",
    branch: "Karnataka",
  },
  {
    phone: "7847045678",
    branch: "Tamil Nadu",
  },
  {
    phone: "7207948072",
    branch: " Kolkata ",
  },
  {
    phone: "9701688544",
    branch: "Maharashtra",
  },
  
];

const PhoneOptions = () => {
  return (
    <div className="flex flex-col gap-[.3rem]">
      {options?.map((option) => {
        return (
          <div className="flex flex-col text-[12px] text-white leading-[1rem] hover:bg-[#0653a2] cursor-pointer px-[1rem] py-[20px]">
            <p className="text-[1rem] font-[500]">{option?.phone}</p>
            <p>{option?.branch}</p>
          </div>
        );
      })}
    </div>
  );
};

export default PhoneOptions;
