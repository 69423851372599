import React, { useState } from "react";

const TopSections = ({ data }) => {
  const [show, setShow] = useState(false);
  return (
    <div className="flex flex-col pb-[25px]">
      <h1 className="text-[1.875rem] lg:text-[2.875rem] text-[#0a2a6c] font-[600]">
      {/* 𝐏𝐚𝐢𝐧𝐥𝐞𝐬𝐬 𝐕𝐚𝐫𝐢𝐜𝐨𝐬𝐞 𝐕𝐞𝐢𝐧𝐬 𝐓𝐫𝐞𝐚𝐭𝐦𝐞𝐧𝐭 𝐢𝐧 𝐊𝐮𝐫𝐧𝐨𝐨𝐥 𝐛𝐲 𝐓𝐨𝐩 𝐃𝐨𝐜𝐭𝐨𝐫 */}
      </h1>
      {data?.overview?.join("")?.length > 1000 ? (
        <>
           <p className="text-[#384f7e] font-[600]">
            {!show ? (
              <>
                {data?.overview
                  ?.join("\n")
                  ?.slice(0, 1000)
                  ?.concat(".....")
                  ?.split("\n")
                  .map((o) => (
                    <p className="text-[#384f7e] font-[600] mb-[15px]">{o}</p>
                  ))}
              </>
            ) : (
              <>
                {data?.overview
                  ?.join("\n")
                  ?.split("\n")
                  ?.map((o) => (
                    <p className="text-[#384f7e] font-[600] mb-[15px]">{o}</p>
                  ))}
              </>
            )}
          </p>
          {!show ? (
            <span
              onClick={() => setShow(true)}
              className="w-fit text-[14px] text-[#007bff] hover:text-[#2b6f7d] font-[600] cursor-pointer"
            >
              Read More
            </span>
          ) : (
            <span
              href=""
              onClick={() => setShow(false)}
              className="w-fit text-[14px] text-[#007bff] hover:text-[#2b6f7d] font-[600] cursor-pointer"
            >
              Read less
            </span>
          )}
        </>
      ) : (
        <>
          {data?.overview
            ?.join("\n")
            ?.concat(".....")
            ?.split("\n")
            ?.map((o) => (
              <p className="text-[#384f7e] font-[600] mb-[15px]">{o}</p>
            ))}
        </>
      )}
    </div>
  );
};

export default TopSections;
