import React from "react";

const BottomSection = () => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 max-w-[1140px] mx-auto pt-[3rem]">
      <div className="h-[125px] flex flex-col items-center border-r-[1px] px-[1rem]">
        <h3 className="text-[2rem] text-[#103547] font-bold">6</h3>
        <h3 className="text-[1.25rem] text-[#0653a2]">States</h3>
        <p className="text-[.875rem] text-[#384f7e] text-center">
          High-quality care closer to you
        </p>
      </div>
      <div className="h-[125px] flex flex-col items-center border-r-[1px] px-[1rem]">
        <h3 className="text-[2rem] text-[#103547] font-bold">24</h3>
        <h3 className="text-[1.25rem] text-[#0653a2]">Hospitals</h3>
        <p className="text-[.875rem] text-[#384f7e] text-center">
          Providing world-class healthcare
        </p>
      </div>
      <div className="h-[125px] flex flex-col items-center border-r-[1px] px-[1rem]">
        <h3 className="text-[2rem] text-[#103547] font-bold">12</h3>
        <h3 className="text-[1.25rem] text-[#0653a2]">Doctors</h3>
        <p className="text-[.875rem] text-[#384f7e] text-center">
          Working together to give best medical care
        </p>
      </div>
      <div className="h-[125px] flex flex-col items-center border-r-[1px] px-[1rem]">
        <h3 className="text-[2rem] text-[#103547] font-bold"> 8+</h3>
        <h3 className="text-[1.25rem] text-[#0653a2]">Specialities</h3>
        <p className="text-[.875rem] text-[#384f7e] text-center">
          To help you live better
        </p>
      </div>
      <div className="h-[125px] flex flex-col items-center px-[1rem]">
        <h3 className="text-[2rem] text-[#103547] font-bold">24,000+</h3>
        <h3 className="text-[1.25rem] text-[#0653a2]">Patients</h3>
        <p className="text-[.875rem] text-[#384f7e] text-center">
          Treated every year
        </p>
      </div>
    </div>
  );
};

export default BottomSection;
