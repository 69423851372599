// import React from "react";
// import icons from "../../../utils/icons";

// const ContactBottom = (data) => {
//   //console.log(data?.data);
//   return (
//     <div className="mt-[1rem]">
//       {/* ////// */}
//       <div>
//         <h3 className="text-[1.75rem] text-white font-semibold">
//           Contact Us
//         </h3>
//         <p className="text-[1.125rem] text-[#f2f2f2] font-semibold">
//           Email:{" "}
//           <a
//             href={data?.data?.email}
//             className="text-[1rem] font-[300] hover:underline"
//           >
//             {data?.data?.email}
//           </a>
//         </p>
//       </div>
//       {/* /// emergancy /// */}
//       <div className="flex items-center gap-[8px] bg-[#0f0f0f] opacity-[.6] lg:w-[315px] h-[30px] px-[1rem] py-[8px] box-content mt-[1rem]">
//         <img src={icons.ambulanceIcon} alt="" />
//         <p className="flex items-center gap-[12px] text-[1.125rem] text-[#f2f2f2] font-semibold">
//           Emergency:
        
//           <a
//             href="tel: 9989527715"
//             className="text-[1rem] font-[300] hover:underline"
//           >
//             {" "}
//             {data?.data?.emergency}
//           </a>
//         </p>
//       </div>
//       {/* /// helpline /// */}
//       <div className="flex items-start flex-col md:flex-row gap-[8px] bg-[#0f0f0f] opacity-[.6] lg:w-[315px] h-[100%] px-[1rem] py-[8px] box-content mt-[1rem]">
//         <div className="flex items-center gap-[10px]">
//           <img className="" src={icons.helplineIcon} alt="" />
//           <p className="flex gap-[12px] text-[1.125rem] text-[#f2f2f2] font-semibold">
//             Helpline:
//           </p>
//         </div>
//         <div className="text-[#f2f2f2] flex flex-col">
//           <a
//             href={`tel:  ${data?.data?.helpline}`}
//             className="text-[1rem] font-[300] hover:underline"
//           >
//             {data?.data?.helpline}
//           </a>
//           {/* <a
//             href="tel:  +91-497-6641000"
//             className="text-[1rem] font-[300] hover:underline"
//           >
//             +91-497-6641000
//           </a> */}
//         </div>
//       </div>
//       {/* /// social links /// */}
//       <div className="flex items-center gap-[2rem] mt-[1.5rem]">
//         <a
//           target="_blank"
//           href="https://www.facebook.com/AvisVascularCentre"
//           rel="noreferrer"
//         >
//           <img src={icons.facebookIcon} alt="" />
//         </a>
//         <a
//           target="_blank"
//           href="https://www.instagram.com/avishospitals/"
//           rel="noreferrer"
//         >
//           <img src={icons.instagramIcon} alt="" />
//         </a>
//         <a
//           target="_blank"
//           href="https://www.youtube.com/channel/UCDHD4EJh3hMu3Znx3eSDdTA"
//           rel="noreferrer"
//         >
//           <img src={icons.youtubeIcon} alt="" />
//         </a>
//       </div>
//     </div>
//   );
// };

// export default ContactBottom;

import React from "react";
import icons from "../../../utils/icons";

const ContactBottom = (data) => {
  return (
    <div className="mt-[1rem]">
      {/* ////// */}
      <div>
        <h3 className="text-[1.75rem] text-white font-semibold">
          Contact Us
        </h3>
        <p className="text-[1.125rem] text-[#f2f2f2] font-semibold">
          Email:{" "}
          <a
            href={`mailto:${data?.data?.email}`} // Changed to mailto link
            className="text-[1rem] font-[300] hover:underline"
          >
            {data?.data?.email}
          </a>
        </p>
      </div>
      {/* /// emergency /// */}
      <div className="flex items-start flex-col md:flex-row gap-[8px] bg-[#0f0f0f] opacity-[.6] lg:w-[315px] h-[100%] px-[1rem] py-[8px] box-content mt-[1rem]">
        <div className="flex items-center gap-[10px]">
          <img src={icons.ambulanceIcon} alt="" />
          <p className="flex gap-[12px] text-[1.125rem] text-[#f2f2f2] font-semibold">
            Emergency:
          </p>
        </div>
        <div className="text-[#f2f2f2] flex flex-col">
          <a
            href={`tel:${data?.data?.emergency}`}
            className="text-[1rem] font-[300] hover:underline"
          >
            {data?.data?.emergency}
          </a>
        </div>
      </div>
      {/* /// helpline /// */}
      <div className="flex items-start flex-col md:flex-row gap-[8px] bg-[#0f0f0f] opacity-[.6] lg:w-[315px] h-[100%] px-[1rem] py-[8px] box-content mt-[1rem]">
        <div className="flex items-center gap-[10px]">
          <img className="" src={icons.helplineIcon} alt="" />
          <p className="flex gap-[12px] text-[1.125rem] text-[#f2f2f2] font-semibold">
            Helpline:
          </p>
        </div>
        <div className="text-[#f2f2f2] flex flex-col">
          <a
            href={`tel:${data?.data?.helpline}`}
            className="text-[1rem] font-[300] hover:underline"
          >
            {data?.data?.helpline}
          </a>
        </div>
      </div>
      {/* /// social links /// */}
      <div className="flex items-center gap-[2rem] mt-[1.5rem]">
        <a
          target="_blank"
          href="https://www.facebook.com/AvisVascularCentre"
          rel="noreferrer"
        >
          <img src={icons.facebookIcon} alt="" />
        </a>
        <a
          target="_blank"
          href="https://www.instagram.com/avishospitals/"
          rel="noreferrer"
        >
          <img src={icons.instagramIcon} alt="" />
        </a>
        <a
          target="_blank"
          href="https://www.youtube.com/channel/UCDHD4EJh3hMu3Znx3eSDdTA"
          rel="noreferrer"
        >
          <img src={icons.youtubeIcon} alt="" />
        </a>
      </div>
    </div>
  );
};

export default ContactBottom;
