import React from "react";

const ContactInputs = ({ contactInfo, setContactInfo }) => {
  const handleChange = (event) => {
    setContactInfo({ ...contactInfo, [event.target.name]: event.target.value });
  };
  return (
    <div className="grid grid-cols-1 lg:grid-cols-3 gap-[1rem]">
      <input
        type="email"
        className="w-[100%] h-[48px] p-[1rem] border focus:outline-none"
        placeholder="Email"
        name="email"
        value={contactInfo?.email}
        onChange={handleChange}
      />
      <input
        type="text"
        className="w-[100%] h-[48px] p-[1rem] border focus:outline-none"
        placeholder="Emergency"
        name="emergency"
        value={contactInfo?.emergency}
        onChange={handleChange}
      />
      <input
        type="text"
        className="w-[100%] h-[48px] p-[1rem] border focus:outline-none"
        placeholder="Helpline"
        name="helpline"
        value={contactInfo?.helpline}
        onChange={handleChange}
      />
    </div>
  );
};

export default ContactInputs;
