import React from "react";
import { useState } from "react";
import { useAddTestimonialMutation } from "../../../services/testimonialsApi";
import BackDropComponent from "../../Common/BackDropComponent";
import { SuccessAlertFunction } from "../../Common/SuccessAlert";
import { FailedAlertFunction } from "../../Common/FailedAlertFunction";

const TestimonialsInputes = () => {
  const [addTestimonial] = useAddTestimonialMutation();
  const [testimonialInfo, setTestimonialInfo] = useState({
    name: "",
    address: "",
    overview: "",
    videoLink: "",
  });
  const handleChange = (e) => {
    setTestimonialInfo({
      ...testimonialInfo,
      [e.target.name]: e.target.value,
    });
  };
  const [showBackdrop, setBackdrop] = useState(false);

  const handleSave = () => {
    if (
      testimonialInfo?.name &&
      testimonialInfo?.address &&
      testimonialInfo?.overview &&
      testimonialInfo?.videoLink
    ) {
      setBackdrop(true);
      addTestimonial({
        name: testimonialInfo?.name,
        address: testimonialInfo?.address,
        overview: testimonialInfo?.overview?.split("\n"),
        videoLink: testimonialInfo?.videoLink,
      })
        .then((res) => {
          if (res?.data?.status) {
            setTestimonialInfo({
              name: "",
              address: "",
              overview: "",
              videoLink: "",
            });
            SuccessAlertFunction(res?.data?.message);
            setBackdrop(false);
          } else {
            FailedAlertFunction(res?.data?.message);
            setBackdrop(false);
          }
        })
        .catch((error) => {
          FailedAlertFunction(error?.message);
          setBackdrop(false);
        });
    } else {
      FailedAlertFunction("Please fill all the fields!");
    }
  };
  return (
    <div className="flex flex-col gap-[1rem]">
      <div className="grid grid-cols-2 gap-[1rem]">
        <input
          className="w-[100%] h-[40px] px-[20px] py-[2px] text-[.875rem] text-[#000] bg-[#fff] focus:outline-none"
          type="text"
          placeholder="Patients Name"
          name="name"
          value={testimonialInfo?.name}
          onChange={handleChange}
        />
        <input
          className="w-[100%] h-[40px] px-[20px] py-[2px] text-[.875rem] text-[#000] bg-[#fff] focus:outline-none"
          type="text"
          placeholder="Patients Adress"
          name="address"
          value={testimonialInfo?.address}
          onChange={handleChange}
        />
      </div>
      <div>
        <textarea
          className="border w-[100%] bg-[#fff] focus:outline-none p-[1rem]"
          name="overview"
          id="overview"
          cols="30"
          rows="10"
          placeholder="Overview"
          value={testimonialInfo?.overview}
          onChange={handleChange}
        ></textarea>
      </div>
      <div>
        <input
          className="w-[100%] h-[40px] px-[20px] py-[2px] text-[.875rem] text-[#000] bg-[#fff] focus:outline-none"
          type="text"
          placeholder="Youtube Video Link"
          name="videoLink"
          value={testimonialInfo?.videoLink}
          onChange={handleChange}
        />
      </div>
      <div>
        <button
          onClick={handleSave}
          className="w-[100%] h-[48px] bg-[#2796ac] hover:bg-[#1a778a] text-white uppercase"
        >
          Save
        </button>
      </div>
      <BackDropComponent state={showBackdrop} />
    </div>
  );
};

export default TestimonialsInputes;
