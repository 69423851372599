// import React from "react";
// import TopBanner from "../Components/Services/TopBanner";
// import ServiceDesc from "../Components/Services/ServiceDesc";
// import NavBar from "../Components/Common/NavBar";
// import Footer from "../Components/Footer/Footer";
// import { useParams } from "react-router-dom";
// import {
//   useGetServiceByNameQuery,
// } from "../services/serviceApi";
// import AsterSpecialist from "../Components/Home_Page/AsterSpecialist";
// import Loading from "../Components/Common/Loading";
// import PatientsSays from "../Components/Home_Page/PatientsSays";
// import { useTestimonialsQuery } from "../services/testimonialsApi";
// import HashComp from "../Components/Services/HashComp";

// const Sevice = () => {
//   const { name } = useParams();
//   const { data, isLoading } = useGetServiceByNameQuery(name?.split('-').join(' '));
//   const { data: videoData } = useTestimonialsQuery({
//     page: "00",
//     perPage: "00",
//   });
//   return (
//     <div>
      
//       <NavBar />
//       {isLoading ? (
//         <Loading></Loading>
//       ) : (
//         <>
//           <TopBanner data={data?.data} from={"service"} />
//           <HashComp />
//           <ServiceDesc data={data?.data} />
//           <AsterSpecialist />
//           <PatientsSays data={videoData?.data} />
//         </>
//       )}
//       <Footer />
//     </div>
//   );
// };

// export default Sevice;

import React, { useEffect } from "react";
import TopBanner from "../Components/Services/TopBanner";
import ServiceDesc from "../Components/Services/ServiceDesc";
import NavBar from "../Components/Common/NavBar";
import Footer from "../Components/Footer/Footer";
import { useParams } from "react-router-dom";
import { useGetServiceByNameQuery } from "../services/serviceApi";
import AsterSpecialist from "../Components/Home_Page/AsterSpecialist";
import Loading from "../Components/Common/Loading";
import PatientsSays from "../Components/Home_Page/PatientsSays";
import { useTestimonialsQuery } from "../services/testimonialsApi";
import HashComp from "../Components/Services/HashComp";

const Sevice = () => {
  const { name } = useParams();
  const { data, isLoading } = useGetServiceByNameQuery(name?.split('-').join(' '));
  const { data: videoData } = useTestimonialsQuery({
    page: "00",
    perPage: "00",
  });

  useEffect(() => {
    if (data?.data) {
      document.title = `Book your Appointment with ${data.data.name} Specialists - Avis Hospital`;
    }
  }, [data]);

  return (
    <div>
      <NavBar />
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <TopBanner data={data?.data} from={"service"} />
          <HashComp />
          <ServiceDesc data={data?.data} />
          <AsterSpecialist />
          <PatientsSays data={videoData?.data} />
        </>
      )}
      <Footer />
    </div>
  );
};

export default Sevice;

// import React, { useEffect } from "react";
// import { Helmet } from "react-helmet";
// import TopBanner from "../Components/Services/TopBanner";
// import ServiceDesc from "../Components/Services/ServiceDesc";
// import NavBar from "../Components/Common/NavBar";
// import Footer from "../Components/Footer/Footer";
// import { useParams } from "react-router-dom";
// import { useGetServiceByNameQuery } from "../services/serviceApi";
// import AsterSpecialist from "../Components/Home_Page/AsterSpecialist";
// import Loading from "../Components/Common/Loading";
// import PatientsSays from "../Components/Home_Page/PatientsSays";
// import { useTestimonialsQuery } from "../services/testimonialsApi";
// import HashComp from "../Components/Services/HashComp";

// const Sevice = () => {
//   const { name } = useParams();
//   const { data, isLoading } = useGetServiceByNameQuery(name?.split('-').join(' '));
//   const { data: videoData } = useTestimonialsQuery({
//     page: "00",
//     perPage: "00",
//   });

//   useEffect(() => {
//     if (data?.data) {
//       document.title = `Book your Appointment with ${data.data.name} Specialists - Avis Hospital`;
//     }
//   }, [data]);

//   return (
//     <div>
//       <Helmet>
//         <title>{`Book your Appointment with ${data?.data?.name} Specialists - Avis Hospital`}</title>
//         <meta name="description" content={`Learn more about ${data?.data?.name} service and book your appointment with specialists at Avis Hospital.`} />
//         <meta name="keywords" content={`${data?.data?.name}, ${data?.data?.category}, appointment booking, Avis Hospital, specialists`} />
//       </Helmet>
//       <NavBar />
//       {isLoading ? (
//         <Loading />
//       ) : (
//         <>
//           <TopBanner data={data?.data} from={"service"} />
//           <HashComp />
//           <ServiceDesc data={data?.data} />
//           <AsterSpecialist />
//           <PatientsSays data={videoData?.data} />
//         </>
//       )}
//       <Footer />
//     </div>
//   );
// };

// export default Sevice;

// import React, { useEffect } from "react";
// import { Helmet } from "react-helmet";
// import TopBanner from "../Components/Services/TopBanner";
// import ServiceDesc from "../Components/Services/ServiceDesc";
// import NavBar from "../Components/Common/NavBar";
// import Footer from "../Components/Footer/Footer";
// import { useParams } from "react-router-dom";
// import { useGetServiceByNameQuery } from "../services/serviceApi";
// import AsterSpecialist from "../Components/Home_Page/AsterSpecialist";
// import Loading from "../Components/Common/Loading";
// import PatientsSays from "../Components/Home_Page/PatientsSays";
// import { useTestimonialsQuery } from "../services/testimonialsApi";
// import HashComp from "../Components/Services/HashComp";
// import CustomVideoCarousel from "../Components/CustomVideoCarousel";

// const Sevice = () => {
//   const { name } = useParams();
//   const { data, isLoading } = useGetServiceByNameQuery(name?.split('-').join(' '));
//   const { data: videoData } = useTestimonialsQuery({
//     page: "00",
//     perPage: "00",
//   });

//   useEffect(() => {
//     if (data?.data) {
//       document.title = `Book your Appointment with ${data.data.name} Specialists - Avis Hospital`;
//     }
//   }, [data]);

//   const isSpecialPage = name === "Pain-Management"; // Check if the page is "Pain-Management"

//   return (
//     <div>
//       <Helmet>
//         <title>{`Book your Appointment with ${data?.data?.name} Specialists - Avis Hospital`}</title>
//         <meta name="description" content={`Learn more about ${data?.data?.name} service and book your appointment with specialists at Avis Hospital.`} />
//         <meta name="keywords" content={`${data?.data?.name}, ${data?.data?.category}, appointment booking, Avis Hospital, specialists`} />
//       </Helmet>
//       <NavBar />
//       {isLoading ? (
//         <Loading />
//       ) : (
//         <>
//           <TopBanner data={data?.data} from={"service"} />
//           <HashComp />
//           <ServiceDesc data={data?.data} />
//           <AsterSpecialist />
//           {isSpecialPage ? <CustomVideoCarousel /> : <PatientsSays data={videoData?.data} />}
//         </>
//       )}
//       <Footer />
//     </div>
//   );
// };

// export default Sevice;



