import React from "react";

const RightSide = ({ data }) => {
  return (
    <div className="w-[100%] lg:w-[70%]">
      <h1 className="text-[34px] text-[#373737] font-bold">About Dr. Rajah V Koppala</h1>
      <div className="flex flex-col gap-[1rem] mt-[1rem]">
        {/* {data?.doctorDetails?.map((el) => (
          <p className="text-[#0653a2]">{el}</p>
        ))} */}
        <p className="text-[#0653a2]">
          Dr. Rajah V Koppala, an internationally qualified Vascular & Interventional Radiologist with over 30 years of experience, has successfully treated thousands of patients suffering from varicose veins, osteoarthritis, thyroid nodules and other conditions through minimally invasive procedures.        </p>
        <p className="text-[#0653a2]">
          He earned his MBBS and Residency in Diagnostic Radiology from Osmania University, Hyderabad, in 1995 and obtained his MD in Vascular Interventional Radiology from England in 2001.        </p>
        <p className="text-[#0653a2]">
        Renowned for his professionalism and expertise, Dr. Koppala is a member of prestigious medical societies, including the Radiological Society of North America, Fellow of The Royal College of Radiologists, UK (FRCR), the British Society of Interventional Radiology, and Singapore Radiological Society. He is an American Board-Certified doctor with approved licenses from the Medical Council of India, Bermuda Medical Council and Singapore Medical Council.        </p>
        <p className="text-[#0653a2]">
          
          In 2003, the Singaporean government recognised Dr. Koppala's humanitarian efforts during the SARS outbreak by awarding him the President's Medal for the Bravest Healthcare Worker. He established Avis Vascular Centre in Hyderabad in 2015, which has expanded to provide specialised medical care services across India.
        </p>
        <p className="text-[#0653a2]">
          
        All our branches accept leading National Insurance providers to make our non-surgical treatments accessible. We also cater to international patients, and our dedicated International Patient Services team facilitates treatment, manages travel reservations, doctor appointments, hotel stays and domestic travel for medical tourists.

        </p>
        <p className="text-[#0653a2]">
        Dr. Koppala has performed over 40,000 minimally invasive treatments for venous disorders, achieving a success rate of over 95%. He excels in non-surgical treatments for various conditions, including Lymphedema, Uterine Fibroids, Venous Ulcers, Post-Thrombotic Syndrome, Peripheral Artery Disease and Renal Artery Stenting.        </p>
        <p className="text-[#0653a2]">
        Demonstrating a commitment to charity, Dr. Koppala conducts free medical camps nationwide to raise awareness about venous health. Through social mobilization campaigns, thousands of people in Andhra Pradesh, Telangana, Karnataka, Maharashtra, Tamil Nadu and West Bengal have received free treatments and medications.        </p>
      </div>
      {/* <ul className="list-disc list-inside mt-[1rem]">
        {data?.detailsLists?.map((el) => (
          <li className="text-[#0653a2]">{el}</li>
        ))}
      </ul>
      <div className="text-[#0653a2] mt-[1rem]">
        <h3 className="text-[20px] font-bold">Memberships</h3>
        <ul className="list-disc list-inside mt-[1rem]">
          {data?.memberships?.map((el) => (
            <li>{el}</li>
          ))}
        </ul>
      </div> */}
    </div>
  );
};

export default RightSide;
