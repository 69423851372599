import React from "react";
import InputesFields from "../../../Components/Dashboard/Manage_News_Events_Blogs/InputesFields";
import NewsBlogsTable from "../../../Components/Dashboard/Manage_News_Events_Blogs/News_Blogs_Table";


const ManageNewsEventsBlogs = () => {

  return (
    <div className="container flex flex-col gap-[1rem] mx-auto my-[15px]">
      <div className="bg-[#ececec] p-[15px] rounded overflow-hidden mb-[30px]">
        <h3 className="mb-[20px] text-[24px] font-bold">
          Add News/Events/Blogs
        </h3>
        <InputesFields />
      </div>
      <div className="bg-[#ececec] p-[15px] rounded overflow-hidden">
        <h3 className="mb-[20px] text-[24px] font-bold">
          {" "}
          News/Events/Blogs List
        </h3>
      
        <NewsBlogsTable />
      </div>
    </div>
  );
};

export default ManageNewsEventsBlogs;
