import React, { useState } from "react";
import SelectState from "../../../Components/Dashboard/Manage_Branches/SelectState";
import SelectCity from "../../../Components/Dashboard/Manage_Branches/SelectCity";
import ContactInputs from "../../../Components/Dashboard/Manage_Branches/ContactInputs";
import SelectImage from "../../../Components/Dashboard/Manage_Branches/SelectImage";
import BranchTable from "../../../Components/Dashboard/Manage_Branches/BranchTable";
import { useAddBranchMutation } from "../../../services/branchApi";
import { IoIosCloseCircle } from "react-icons/io";
import BackDropComponent from "../../../Components/Common/BackDropComponent";
import { SuccessAlertFunction } from "../../../Components/Common/SuccessAlert";
import { FailedAlertFunction } from "../../../Components/Common/FailedAlertFunction";
import axios from "axios";
import { BsImage } from "react-icons/bs";

const ManageBranches = () => {
  const [state, setState] = React.useState(null);
  const [city, setCity] = useState("");
  const [overview, setOverview] = useState("");
  const [addBranch] = useAddBranchMutation();
  const [branchName, setBranchName] = useState("");
  const [contactInfo, setContactInfo] = useState({
    email: "",
    helpline: "",
    emergency: "",
  });
  const [image, setImage] = useState("");
  const [banners, setBannners] = useState([]);
  const [showBackdrop, setBackdrop] = useState(false);

  const handleBannersUpload = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", process.env.REACT_APP_PRESET);
    try {
      setBackdrop(true);
      const res = await axios.post(
        `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDNAME}/image/upload`,
        formData
      );
      setBackdrop(false);
      setBannners([
        ...banners,
        {
          bannerImage: res?.data?.secure_url,
        },
      ]);
    } catch (error) {
      setBackdrop(false);
      console.log(error.message);
    }
  };

  const handleRemoveBanner = (b) => {
    const filter = banners.filter(
      (banner) => banner?.bannerImage !== b?.bannerImage
    );
    setBannners(filter);
  };

  const handleSave = () => {
    const data = {
      name: state,
      city,
      overview: overview.split("\n"),
      branchName,
      ...contactInfo,
      image,
      banners: banners,
    };
    if (
      state &&
      city &&
      overview &&
      branchName &&
      contactInfo?.email &&
      contactInfo?.emergency &&
      contactInfo?.helpline &&
      image
    ) {
      if (banners?.length > 0) {
        setBackdrop(true);
        addBranch(data)
          .then((res) => {
            if (res?.data?.status) {
              setContactInfo({
                email: "",
                helpline: "",
                emergency: "",
              });
              setState("");
              setCity("");
              setOverview("");
              setBranchName("");
              setBannners([]);
              SuccessAlertFunction(res?.data?.message);
              setBackdrop(false);
            } else {
              FailedAlertFunction(res?.data?.message);
              setBackdrop(false);
            }
          })
          .catch((error) => {
            FailedAlertFunction(error?.message);
            setBackdrop(false);
          });
      } else {
        FailedAlertFunction("Please add at least one banner!");
      }
    } else {
      FailedAlertFunction("Please fill all the required info!");
    }
  };

  return (
    <div className="container mx-auto my-[15px]">
      <div className="bg-[#ececec] p-[15px] rounded overflow-hidden">
        <h3 className="mb-[20px] text-[24px] font-bold">Add Branches</h3>
        <p className="text-[24px] font-bold mb-[15px]">Branch Information</p>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-[1rem]">
          <SelectState state={state} setState={setState} />
          <SelectCity state={state} city={city} setCity={setCity} />
        </div>
        <div className="flex justify-center my-[2rem]">
          <input
            className="border w-[100%] focus:outline-none p-[1rem]"
            name="branchName"
            id="branchName"
            placeholder="Branch Name"
            value={branchName}
            onChange={(e) => setBranchName(e.target.value)}
          />
        </div>
        <div className="flex justify-center my-[2rem]">
          <textarea
            className="border w-[100%] focus:outline-none p-[1rem]"
            name="overview"
            id="overview"
            cols="30"
            rows="10"
            placeholder="Overview"
            value={overview}
            onChange={(e) => {
              setOverview(e.target.value);
            }}
          ></textarea>
        </div>
        <div className="my-[2rem]">
          <ContactInputs
            setContactInfo={setContactInfo}
            contactInfo={contactInfo}
          />
        </div>
        <div className="my-[2rem]">
          {image && (
            <>
              <div className="relative mb-[15px] w-[200px]">
                <p className="text-[13px]">Added Image</p>
                <img src={image} className="h-[200px] w-[200px]" alt="images" />
                <IoIosCloseCircle
                  onClick={() => setImage("")}
                  style={{ color: "white" }}
                  className="absolute top-[28px] right-[10px] h-[30px] w-[30px] cursor-pointer"
                />
              </div>
            </>
          )}
          <SelectImage
            setBackdrop={setBackdrop}
            image={image}
            setImage={setImage}
          />
        </div>

        <div>
          <p className="text-[24px] font-bold">Add Banners</p>

          <div className="my-[15px]">
            {banners?.length !== 0 && (
              <>
                <p>Banners Added</p>

                <div className="grid grid-cols-4 gap-[15px] mb-[15px]">
                  {banners?.map((b) => (
                    <div key={b?.bannerImage} className="relative">
                      <img
                        src={b?.bannerImage}
                        className="h-[200px] w-[100%]"
                        alt="first banner"
                      />
                      <IoIosCloseCircle
                        onClick={() => handleRemoveBanner(b)}
                        style={{ color: "white" }}
                        className="absolute top-[10px] right-[10px] lg:right-[15px] h-[30px] w-[30px] cursor-pointer"
                      />
                    </div>
                  ))}
                </div>
              </>
            )}
            <div>
              <label
                className="text-[1.5rem] flex items-center gap-[.5rem] justify-center w-[100%] h-[100px] bg-[whitesmoke] cursor-pointer"
                htmlFor="updatebanner1"
              >
                <BsImage /> Upload Banner
                <input
                  onChange={handleBannersUpload}
                  className="hidden"
                  type="file"
                  name="updatebanner1"
                  id="updatebanner1"
                />
              </label>
            </div>
          </div>
        </div>
        <div>
          <button
            onClick={handleSave}
            className="w-[100%] h-[48px] bg-[#2796ac] hover:bg-[#1a778a] text-white uppercase"
          >
            Save
          </button>
        </div>
      </div>
      <div className="my-[2rem]">
        <div className="bg-[#ececec] p-[15px] rounded overflow-hidden">
          <h3 className="mb-[20px] text-[24px] font-bold">Branches List</h3>
          <BranchTable />
        </div>
      </div>
      <BackDropComponent state={showBackdrop} />
    </div>
  );
};

export default ManageBranches;
