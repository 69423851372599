import React, { useState } from "react";
import ArrowComp from "./ArrowComp";

const CarouselArrowButtons = ({ left_class, right_class, children }) => {
  const [leftHoverd, setLeftHovered] = useState(false);
  const [rightHovered, setRightHovered] = useState(false);
  return (
    <div className="flex items-center justify-between">
      <div className="flex items-center gap-[2rem]">
        <button
          onMouseEnter={() => setLeftHovered(true)}
          onMouseLeave={() => setLeftHovered(false)}
          className={`activeTranition ${left_class} flex items-center justify-center h-[48px] 2xl:h-[68px] w-[48px] 2xl:w-[68px] border-[1px] border-[#0653a2] hover:border-[#0653a2] hover:bg-[#0653a2]`}
          style={{
            transform: "rotate(-180deg)",
            fill: `${leftHoverd ? "white" : "#0653a2"}`,
          }}
        >
          <ArrowComp />
        </button>
        <button
          onMouseEnter={() => setRightHovered(true)}
          onMouseLeave={() => setRightHovered(false)}
          className={`activeTranition ${right_class} flex items-center justify-center h-[48px] 2xl:h-[68px] w-[48px] 2xl:w-[68px] border-[1px] border-[#0653a2] hover:border-[#0653a2] hover:bg-[#0653a2]`}
          style={{ fill: `${rightHovered ? "white" : "#0653a2"}` }}
        >
          <ArrowComp />
        </button>
      </div>
      <div className="block md:hidden">{children}</div>
    </div>
  );
};

export default CarouselArrowButtons;
