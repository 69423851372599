import React, { useContext, useState } from "react";
import { IoMdArrowDropdown } from "react-icons/io";
import icons from "../../utils/icons";
import "../../Styles/NavBar.css";
import OurBranchesOptions from "./OurBranchesOptions";
import OurServicesOptions from "./OurServicesOptions";
import DrawerComp from "./DrawerComp";
// import logo from "../../Assets/Images/logo.png";
import logo from "../../Assets/Images/Avis-vascular-centre-lower.png";
import { Link, useNavigate } from "react-router-dom";
import { BookFormContext, FirstModalContext } from "../../App";
import PhoneOptions from "./PhoneOptions";

const NavBar = () => {
  const [show, setShow] = useState(false);
  const { bookForm, setBookForm } = useContext(BookFormContext);
  const { setFirstModal } = useContext(FirstModalContext);
  const navigate = useNavigate();
  return (
    <div className="sticky top-0 z-[1001]">
      {/* /////// */}
      <div className="h-[100%] lg:h-[92px] w-[100%] bg-[#1c456e] lg:bg-[#0653a2]">
        <div className="flex items-center max-w-[1140px] h-[100%] px-[10px] pr-[20px]  lg:px-[20px] xl:px-0 py-[.5rem] lg:py-0 mx-auto relative">
          {/* /// logo side /// */}
          <div className="grow h-[100%] flex items-center justify-start lg:justify-end pr-[10px]">
            <Link to={"/"}>
              <img
                className="w-[265px] h-[55px] md:h-[65px] "
                src={logo}
                alt=""
              />
            </Link>
            {/* <Link to={"/"}>
              <h3 className="text-white text-[22px] font-[900] tracking-wider	ml-[10px]">
                Avis Vascular Center
              </h3>
              <p className="text-white text-[12px] text-right mt-[-5px] tracking-wide">
                An Avis Hospitals Enterprise
              </p>
            </Link> */}
          </div>
          {/* /// */}
          <div className="h-[100%]">
            <div className="flex items-center h-[100%]">
              <div className="our_branhces max-w-[160px] h-[100%] hidden lg:flex items-center gap-[.5rem] px-[8px] hover:bg-[#0653a2] cursor-pointer border-l-[1px] border-[#a2a2b9]">
                <img src={icons.hospitalIcon} alt="icon" />
                <p className="text-[0.875rem] text-white font-[500] leading-[2.2ex]">
                  Our Branches
                </p>
                <IoMdArrowDropdown className="text-white text-[2rem]" />
                {/* ///// all branches menu items ///// */}
                <div className="our_branches_menues block absolute top-[100%] left-[50%] translate-x-[-50%] w-[60%] h-[424px] bg-[#1c456e]">
                  <OurBranchesOptions />
                </div>
              </div>
              <div className="our_services max-w-[160px] h-[100%] hidden lg:flex items-center gap-[.5rem] px-[8px] hover:bg-[#0653a2] cursor-pointer border-l-[1px] border-[#a2a2b9]">
                <img src={icons.specialityIcon} alt="icon" />
                <p className="text-[0.875rem] text-white font-[500] leading-[2.2ex]">
                  Our Services
                </p>
                <IoMdArrowDropdown className="text-white text-[2rem]" />
                {/* ///// all services menu items ///// */}
                <div className="our_services_menues block absolute top-[100%] left-[50%] translate-x-[-50%]  h-[325px] bg-[#1c456e]">
                  <OurServicesOptions />
                </div>
              </div>
              <div
                onClick={()=> navigate('/all-doctors')}
                className="max-w-[160px]  h-[100%] hidden lg:flex items-center gap-[.5rem] px-[8px] hover:bg-[#0653a2] cursor-pointer border-l-[1px] border-[#a2a2b9]"
              >
                <img src={icons.calenderIcon} alt="icon" />
                <p className="text-[0.875rem] text-white font-[500] leading-[2.2ex]">
                  Book An Appointment
                </p>
              </div>
              <div
                onClick={() => {
                  navigate("/international-patients");
                }}
                className="max-w-[160px] h-[100%] hidden xl:flex items-center gap-[.5rem] px-[8px] hover:bg-[#0653a2] cursor-pointer border-l-[1px] border-[#a2a2b9]"
              >
                <img src={icons.patientIcon} alt="icon" />
                <p className="text-[0.875rem] text-white font-[500] leading-[2.2ex]">
                  International Patients
                </p>
              </div>
              <div className="branches_phone_numbers lg:max-w-[160px] h-[100%] hidden lg:flex items-center gap-[.5rem] px-[8px] hover:bg-[#0653a2] cursor-pointer border-l-0 lg:border-l-[1px] border-[#a2a2b9] relative">
                <img
                  className="w-[34px] h-[34px] object-contain"
                  src={icons.callIcon}
                  alt="icon"
                  style={{ transform: "rotate(-30deg)" }}
                />
                <p className="text-[0.875rem] text-white font-[500] leading-[2.2ex] whitespace-nowrap">
                  Call Us
                </p>
                <IoMdArrowDropdown className="min-w-[12px] text-white text-[2rem]" />
                {/* ///// phone number options ///// */}
                <div className="phone_number_menues block absolute top-[100%] right-0 w-[150%] bg-[#1c456e]">
                  <PhoneOptions />
                </div>
              </div>
              <div className="ml-[10px] lg:ml-[40px] cursor-pointer">
                <img
                  onClick={() => setShow(true)}
                  src={icons?.menuIcon}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        {/* ////// UNDER MEDIUM SCREEN VISIBLE ////// */}
        <div className="h-[52px] lg:h-[82px] lg:hidden flex items-center justify-center bg-[#0653a2] relative">
          <div className="our_branhces max-w-[160px] h-[100%] flex items-center gap-[.5rem] px-[8px] hover:bg-[#0653a2] cursor-pointer border-l-[1px] border-[#a2a2b9]">
            <img src={icons.hospitalIcon} alt="icon" />
            <p className="text-[12px] text-white font-[500] leading-[2.2ex]">
              Our Branches
            </p>
            <IoMdArrowDropdown className="text-white text-[2rem]" />
            {/* ///// all branches menu items ///// */}
            <div className="our_branches_menues block absolute top-[100%] left-[50%] translate-x-[-50%] w-[80%] h-[424px] md:h-[534px] bg-[#1c456e] overflow-y-scroll lg:overflow-y-auto">
              <OurBranchesOptions />
            </div>
          </div>
          <div className="our_services max-w-[160px] h-[100%] flex items-center gap-[.5rem] px-[8px] hover:bg-[#0653a2] cursor-pointer border-l-[1px] border-[#a2a2b9]">
            <img src={icons.specialityIcon} alt="icon" />
            <p className="text-[12px] text-white font-[500] leading-[2.2ex]">
              Our Services
            </p>
            <IoMdArrowDropdown className="text-white text-[2rem]" />
            {/* ///// all services menu items ///// */}
            <div className="our_services_menues block absolute top-[100%] left-[50%] translate-x-[-50%] w-[80%] h-[325px] bg-[#1c456e]">
              <OurServicesOptions />
            </div>
          </div>
          <div
            onClick={() => setBookForm(!bookForm)}
            className="max-w-[160px] h-[100%] flex items-center gap-[.5rem] px-[8px] hover:bg-[#0653a2] cursor-pointer border-l-[1px] border-[#a2a2b9]"
          >
            <img src={icons.calenderIcon} alt="icon" />
            <p className="text-[12px] text-white font-[500] leading-[2.2ex]">
              Book An Appointment
            </p>
          </div>
        </div>
      </div>
      {/* ///// DRAWER COMPONENT ///// */}
      <DrawerComp show={show} setShow={setShow} />
    </div>
  );
};

export default NavBar;
