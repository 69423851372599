import React, { useState } from "react";
import { BsImage } from "react-icons/bs";
import { useAddServiceMutation } from "../../../services/serviceApi";
import { SuccessAlertFunction } from "../../Common/SuccessAlert";
import { FailedAlertFunction } from "../../Common/FailedAlertFunction";
import BackDropComponent from "../../Common/BackDropComponent";
import axios from "axios";

const ServiceInputes = () => {
  const [overview, setOverview] = useState("");
  const [name, setName] = useState("");
  const [image, setImage] = useState("");
  const [icon, setIcon] = useState("");
  const [youtubeLink, setYoutubeLink] = useState("");
  const [addService] = useAddServiceMutation();
  const [showBackdrop, setBackdrop] = useState(false);

  const handleBannersUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const file = e.target.files[0];
      const formData = new FormData();
      formData.append("file", file);
      formData.append("upload_preset", process.env.REACT_APP_PRESET);
      try {
        setBackdrop(true);
        const res = await axios.post(
          `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDNAME}/image/upload`,
          formData
        );
        setBackdrop(false);
        setImage(res.data.secure_url);
      } catch (error) {
        setBackdrop(false);
        console.log(error.message);
      }
    }
  };
  const handleIconUpload = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", process.env.REACT_APP_PRESET);
    try {
      setBackdrop(true);
      const res = await axios.post(
        `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDNAME}/image/upload`,
        formData
      );
      setBackdrop(false);
      setIcon(res.data.secure_url);
    } catch (error) {
      setBackdrop(false);
      console.log(error.message);
    }
  };
  const handleAddService = () => {
    if (image && name && overview && icon && youtubeLink) {
      setBackdrop(true);
      addService({
        image,
        name,
        youtubeLink,
        overview: overview?.split("\n"),
        icon,
      })
        .then((res) => {
          if (res?.data?.status) {
            setImage("");
            setName("");
            setOverview("");
            setIcon("");
            setYoutubeLink("");
            SuccessAlertFunction(res?.data?.message);
            setBackdrop(false);
          } else {
            FailedAlertFunction(res?.data?.message);
            setBackdrop(false);
          }
        })
        .catch((error) => {
          FailedAlertFunction(error?.message);
          setBackdrop(false);
        });
    } else {
      FailedAlertFunction("Please fill all the fields!");
    }
  };
  return (
    <div>
      <div className="flex flex-col mb-[15px]">
        <label className="text-[13px]" htmlFor="service_name">
          Service
        </label>
        <input
          type="text"
          className="w-[100%] h-[48px] p-[1rem] border focus:outline-none"
          placeholder="Service Name"
          name="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </div>

      <div className="flex flex-col mb-[15px]">
        <label className="text-[13px]" htmlFor="youtubeLink">
          Video Link
        </label>
        <input
          type="text"
          className="w-[100%] h-[48px] p-[1rem] border focus:outline-none"
          placeholder="Video Link"
          name="name"
          value={youtubeLink}
          onChange={(e) => setYoutubeLink(e.target.value)}
        />
      </div>

      <div className="flex flex-col mb-[15px]">
        <label className="text-[13px]" htmlFor="overview">
          Overview
        </label>
        <textarea
          className="border w-[100%] min-h-[100px] max-h-[100px] focus:outline-none p-[1rem]"
          name="overview"
          id="overview"
          cols="30"
          placeholder="Overview"
          value={overview}
          onChange={(e) => setOverview(e.target.value)}
        ></textarea>
      </div>
      <div className="relative">
        {icon && (
          <div>
            <label className="text-[13px]" htmlFor="added">
              Added Icon
            </label>{" "}
            <img src={icon} alt="icon" className="h-[150px] w-[150px]" />
          </div>
        )}
        <div className="flex flex-col mb-[15px]">
          <label className="text-[13px]">Service Icon</label>
          <div>
            <label
              className="text-[1.5rem] flex items-center gap-[.5rem] justify-center w-[100%] h-[100px] bg-[#ffffff] cursor-pointer"
              htmlFor="icon"
            >
              <BsImage /> Upload Icon
              <input
                onChange={handleIconUpload}
                className="hidden"
                type="file"
                name="icon"
                id="icon"
              />
            </label>
          </div>
        </div>
      </div>

      <div className="relative">
        {image && (
          <div>
            <label className="text-[13px]" htmlFor="added">
              Added Banner
            </label>{" "}
            <img src={image} alt="icon" className="h-[250px] w-[350px]" />
          </div>
        )}
        <div className="flex flex-col mb-[15px]">
          <label className="text-[13px]">Upload Banner</label>
          <div>
            <label
              className="text-[1.5rem] flex items-center gap-[.5rem] justify-center w-[100%] h-[100px] bg-[#ffffff] cursor-pointer"
              htmlFor="image"
            >
              <BsImage /> Upload Banner
              <input
                onChange={handleBannersUpload}
                className="hidden"
                type="file"
                name="image"
                id="image"
              />
            </label>
          </div>
        </div>
      </div>

      <div>
        <button
          onClick={handleAddService}
          className="w-[100%] h-[48px] px-[1rem] font-semibold bg-[#2796ac] hover:bg-[#195d6b] uppercase text-white"
        >
          Add Service
        </button>
      </div>
      <BackDropComponent state={showBackdrop} />
    </div>
  );
};

export default ServiceInputes;
