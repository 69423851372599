import axios from "axios";
import React from "react";
import { BsImage } from "react-icons/bs";

const SelectImage = ({ setImage, setBackdrop }) => {
  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", process.env.REACT_APP_PRESET);
    try {
      setBackdrop(true);
      const res = await axios.post(
        `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDNAME}/image/upload`,
        formData
      );
      setBackdrop(false);
      setImage(res.data.secure_url)
    } catch (error) {
      setBackdrop(false);
      console.log(error.message);
    }
  };

  return (
    <div>
      <label
        className="text-[1.5rem] flex items-center gap-[.5rem] justify-center w-[100%] h-[100px] bg-[whitesmoke] cursor-pointer"
        htmlFor="image"
      >
        <BsImage /> Upload Image
        <input
          onChange={handleImageUpload}
          className="hidden"
          type="file"
          name="image"
          id="image"
        />
      </label>
    </div>
  );
};

export default SelectImage;
