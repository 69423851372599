import React, { useEffect } from "react";
import TopSection from "../Components/Doctor_About/TopSection";
import OverviewSection from "../Components/Doctor_About/OverviewSection";
import AreaOfExpertise from "../Components/Doctor_About/AreaOfExpertise";
import NavBar from "../Components/Common/NavBar";
import Footer from "../Components/Footer/Footer";
import { useParams } from "react-router-dom";
import { useGetDoctorByNameQuery } from "../services/doctorApi";
import Loading from "../Components/Common/Loading";

const DoctorAbout = () => {
  const { name } = useParams();
  const { data, isLoading } = useGetDoctorByNameQuery(name?.split('-').join(' '));

  useEffect(() => {
    if (data && data.data) {
      document.title = `${name} - Varicose Veins Specialist`;
    } else {
      document.title = "Loading...";
    }
  }, [data]);

  return (
    <div className="mx-auto">
      <NavBar />
      {isLoading ? (
        <Loading />
      ) : (
        <div className="flex flex-col gap-[2rem] max-w-[1140px] py-[3rem] px-[20px] xl:px-0 mx-auto min-h-[85vh]">
          <TopSection data={data?.data} />
          <OverviewSection data={data?.data} />
          <AreaOfExpertise data={data?.data} />
          {/* <OtherSimilarDoctor /> */}
        </div>
      )}
      <Footer />
    </div>
  );
};

export default DoctorAbout;
