import Swal from "sweetalert2";

export const SuccessAlertFunction = (message) => {
  Swal.fire({
    position: "center",
    icon: "success",
    title: message,
    showConfirmButton: true,
    timer: 5000,
  });
};
