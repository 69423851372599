import React, { useEffect, useState } from "react";
import { Dialog } from "@mui/material";
import { MdOutlineClose } from "react-icons/md";
import { SuccessAlertFunction } from "../../Common/SuccessAlert";
import { FailedAlertFunction } from "../../Common/FailedAlertFunction";
import { useUpdateLeadMutation } from "../../../services/leadApi";

const EditableRowModal = ({ row, showEditableModal, setEditableModal }) => {
  const [status, setStatus] = useState("pending");
  const [updateLead] = useUpdateLeadMutation();
  useEffect(() => {
    setStatus(row?.status);
  }, [row]);
  // UPDATE ROW FUNCTION
  const handleSave = () => {
    if (status) {
      updateLead({ id: row?._id, data: { status: status } }).then((res) => {
        if (res?.data?.status) {
          setStatus("");
          setEditableModal(false);
          SuccessAlertFunction(res?.data?.message);
        } else {
          FailedAlertFunction(res?.data?.message);
        }
      });
    } else {
      FailedAlertFunction("Please fill the link field!");
    }
  };

  return (
    <Dialog maxWidth="lg" open={showEditableModal}>
      <div className="flex flex-col gap-[2rem] w-[100%] md:w-[600px] bg-white px-[2rem] py-[3rem] relative">
        {/* close modal button  */}
        <div className="absolute top-[.8rem] right-[1rem]">
          <MdOutlineClose
            onClick={() => setEditableModal(false)}
            className="text-[1.5rem] text-red-500 cursor-pointer"
          />
        </div>
        <div className="grid grid-cols-1 items-center gap-[.5rem]">
          <div className="flex flex-col">
            <select
              value={status}
              onChange={(e) => setStatus(e.target.value)}
              className="w-[100%] h-[40px] border-[2px] border-[black] focus:outline-none text-black rounded-md"
              // placeholder="Select an option"
            >
              <option value={"pending"}> Pending</option>
              <option value={"contacted"}>Contacted</option>
              <option value={"lost"}>Lost</option>
            </select>
          </div>
        </div>
        <div>
          <button
            onClick={handleSave}
            className="w-[100%] h-[48px] bg-[#2796ac] hover:bg-[#1a778a] text-white uppercase"
          >
            Save
          </button>
        </div>
      </div>
    </Dialog>
  );
};

export default EditableRowModal;
