import React from "react";
import doctorImage from "../../Assets/Images/Doctor-image-2.png";
const AboutDoctor = () => {
  return (
    <div className="max-w-[1240px] mx-auto mt-[50px]">
      <div className="flex flex-col lg:flex-row justify-center items-center">
        <div className="w-[100%] lg:w-[40%] p-[40px]">
          <img src={doctorImage} alt="Dr. Rajah V Koppala" />
        </div>{" "}
        <div className="w-[100%] px-[15px] lg:px-[0] lg:w-[70%]">
          {/* <p className="text-[#444444] text-[22px] font-semibold">
            WELCOME TO THE{" "}
          </p>{" "} */}
          {/* <div className="w-[80px] border-b-[3px] border-[#e9223f] my-[10px]">
            {" "}
          </div>{" "} */}
          <h2 className="text-[#e9223f] font-bold text-4xl lg:text-6xl">
            {" "}
            Message from{" "}
          </h2>{" "}
          <h2 className="text-[#193a5e] text-4xl lg:text-7xl mb-[30px]">
            Dr. Rajah V Koppala{" "}
          </h2>{" "}
          <p className="mb-[20px] font-semibold text-[#434343]">
            After over 24 years as an Interventional Radiologist with an MD from England, I’m delighted to be serving patients PAN India at Avis Hospitals. It is my mission to bring the latest in medicine to the masses and heal those in pain. {" "}
          </p>{" "}
          <p className="mb-[20px] font-semibold text-[#434343]">
             Our internationally recognised vascular and vein specialists offer minimally invasive treatments for delicate health issues like Deep Vein Thrombosis (DVT), uterine fibroids and varicocele among others. {" "}
          </p>{" "}
          <p className="mb-[20px] font-semibold text-[#434343]">
            The first branch of Avis Hospitals was established in 2015, and today, we proudly stand with 20+ branches, turning my vision of a healthier India into a tangible reality. We have transformed over 40,000 lives, but this is only the beginning. {" "}
          </p>{" "}
          <p className="mb-[20px] font-semibold text-[#434343]">
             At Avis Hospitals, your health is the heartbeat of our vision and mission.
          </p>{" "}
          <div className="w-[100%] flex justify-end items-center">
            <div className="text-center">
              <p className="text-[#e9223f] font-bold text-[18px]">
                Dr. Rajah V Koppala{" "}
              </p>{" "}
              <p className="text-[#193a5e]  text-[16px] mb-[30px]">
                Vascular & Interventional Radiologist{" "}
              </p>{" "}
            </div>{" "}
            {/* <div>
              <img
                className="h-[105px] w-[121px]"
                src="https://drpradyumnareddy.com/wp-content/uploads/2020/11/img-cardiology-signature.png"
                alt="sign"
              />
            </div>{" "} */}
          </div>{" "}
        </div>{" "}
      </div>{" "}
    </div>
  );
};

export default AboutDoctor;
