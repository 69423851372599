import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

// import required modules
import { Navigation } from "swiper/modules";
import AsterCarouselCard from "../../Common/AsterCarouselCard";
import CarouselArrowBtns2 from "../../Common/CarouselArrowBtns2";
import ViewAllBtn from "../../Common/ViewAllBtn";

const CarouselComp = ({ data }) => {
  return (
    <>
      <div className="max-w-[1140px] mx-auto">
        <Swiper
          navigation={{
            nextEl: ".aster-specialist-arrow-right",
            prevEl: ".aster-specialist-arrow-left",
          }}
          className="mySwiper"
          modules={[Navigation]}
          breakpoints={{
            0: {
              slidesPerView: 1.18,
            },
            768: {
              slidesPerView: 2.5, // Number of slides per view on tablets
            },
            1024: {
              slidesPerView: 3.5, // Number of slides per view on desktops
            },
            1300: {
              slidesPerView: 3.5,
            },
            1500: {
              slidesPerView: 3.5,
            },
          }}
        >
          {data?.map((el) => (
            <SwiperSlide>
              <div className="py-[50px]">
                <AsterCarouselCard el={el} />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className="max-w-[1140px] mx-auto">
        <CarouselArrowBtns2
          left_class="aster-specialist-arrow-left"
          right_class="aster-specialist-arrow-right"
          children={
            <ViewAllBtn
              text_class={"ester_specialist_view_btn"}
              arrow_class={"ester_specialist_view_arrow"}
            />
          }
        />
      </div>
    </>
  );
};

export default CarouselComp;
