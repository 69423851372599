import React, { useState } from "react";
import ArrowComp from "./ArrowComp";

const CarouselArrowButtons = ({ left_class, right_class, children, from }) => {
  const [color, setColor] = useState("white");
  const [color1, setColor1] = useState("white");
  const handleHoverColor = () => {
    setColor("#0653a2");
  };
  return (
    <div className="flex items-center justify-between">
      <div className="flex items-center gap-[2rem]">
        <button
          className={`${left_class} flex items-center justify-center h-[48px] 2xl:h-[68px] w-[48px] 2xl:w-[68px] border-[1px] border-white ${
            from === "news-event"
              ? "hover:border-[#0653a2] hover:bg-[#ffffff]"
              : "hover:border-[#0653a2] hover:bg-[#0653a2]"
          } `}
          style={{
            transform: "rotate(-180deg)",
            fill: `${from === "news-event" ? color : "white"}`,
          }}
          onMouseOver={handleHoverColor}
          onMouseLeave={() => setColor("white")}
        >
          <ArrowComp />
        </button>
        <button
          className={`${right_class} flex items-center justify-center h-[48px] 2xl:h-[68px] w-[48px] 2xl:w-[68px] border-[1px] border-white ${
            from === "news-event"
              ? "hover:border-[#0653a2] hover:bg-[#ffffff]"
              : "hover:border-[#0653a2] hover:bg-[#0653a2]"
          }`}
          style={{
            fill: `${from === "news-event" ? color1 : "white"}`,
          }}
          onMouseOver={() => setColor1("#0653a2")}
          onMouseLeave={() => setColor1("white")}
        >
          <ArrowComp />
        </button>
      </div>
      <div className="block md:hidden">{children}</div>
    </div>
  );
};

export default CarouselArrowButtons;
