import React from "react";
import ViewAllBtn from "../../Common/ViewAllBtn";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
const TopSection = ({ language, setLanguage }) => {
  const languages = ["All", "English", "Hindi", "Telugu", "Tamil", "Marathi", "Kannada", "Bengali"];
  return (
    <div className="max-w-[1140px] mx-auto">
      <div className="flex justify-between items-start">
        <h1 className="text-[1.875rem] lg:text-[2.875rem] font-semibold text-white">
          News, Events and Blogs
        </h1>
        <div className="flex flex-col">
          <div class="relative min-w-[140px] inline-block w-[100%]">
            <select
              value={language}
              onChange={(e) => setLanguage(e.target.value)}
              class="block appearance-none w-[100%] h-[40px] px-[20px] py-[2px] text-[.875rem] text-[#000] bg-[#ffffff] outline-none"
            >
              {languages?.map((d) => (
                <option value={d}>{d}</option>
              ))}
            </select>
            <div class="pointer-events-none absolute inset-y-0 right-[10px] flex items-center px-2 text-[#6e81a8]">
              <FontAwesomeIcon icon={faCaretDown} />
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-between items-center">
        <div className="max-w-[915px] text-white">
          <p className="leading-[1.5rem]">
            Explore the latest achievements, events, and in-depth insights
            directly from Avis Vascular Centre, your trusted provider of the
            best vascular care.
          </p>
        </div>
        <div className="hidden md:block">
          <ViewAllBtn
            text_class={"ester_view_btn"}
            arrow_class={"ester_view_arrow"}
            route_link={"/all-news-events-blogs"}
          />
        </div>
      </div>
    </div>
  );
};

export default TopSection;
