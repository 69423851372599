import React from "react";
import Table from "../../../Components/Dashboard/Manage_Links/Table";
import { useGetAllLeadsQuery } from "../../../services/leadApi";
import csvDownload from "json-to-csv-export";
import moment from "moment";

const ManageLeads = () => {
  const { data: leads } = useGetAllLeadsQuery();

  const handlExport = async() => {
    const exportData = [];
    leads?.data?.forEach((element, i) => {
      exportData.push({
        id: i,
        firstName: element?.firstName,
        lastName: element?.lastName,
        email: element?.email,
        state: element?.state,
        city: element?.city,
        phone: `00${element?.phone.slice(0, 2)}-${element?.phone.slice(2, element?.phone?.length)}`,
        status: element?.status,
        createdAt: moment(element?.createdAt).format("DD/MM/YYYY"),
      });
    });
    const dataToConvert = {
      data: exportData,
      filename: `leads_report_${moment(new Date()).format("DD/MM/YYYY")}`,
      delimiter: ",",
      headers: [
        "id",
        "firstName",
        "lastName",
        "email",
        "state",
        "city",
        "phone",
        "status",
        "createdAt",
      ],
    };
    csvDownload(dataToConvert);
  };

  return (
    <div className="container flex flex-col gap-[1rem] mx-auto my-[15px]">
      <div className="bg-[#ececec] p-[15px] rounded overflow-hidden">
        <div className="flex justify-between items-start mb-[15px]">
          <h3 className="mb-[20px] text-[24px] font-bold">Manage Leads</h3>
          <button
            onClick={handlExport}
            className="bg-[#2d7bc9] text-white px-[20px] rounded py-[10px] hover:bg-[#0653a2]"
          >
            Export Data
          </button>
        </div>

        <Table />
      </div>
    </div>
  );
};

export default ManageLeads;
