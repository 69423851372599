import React from "react";

const AreaOfExpertise = ({ data: DoctorAboutData }) => {
  return (
    <div>
      {DoctorAboutData?.expertise?.length > 0 && (
        <>
          <h1 className="text-[#0a2a6c] text-[2.25rem] font-semibold">
            Area of expertise
          </h1>
          <ul className="list-disc list-inside text-[#6e81a8] mt-[1rem]">
            {DoctorAboutData?.expertise?.map((el) => (
              <li>{el}</li>
            ))}
          </ul>
        </>
      )}
    </div>
  );
};

export default AreaOfExpertise;
