import React from "react";
import NavBar from "../Components/Common/NavBar";
import Footer from "../Components/Footer/Footer";
import Section from "../Components/Testimonial/Section";
import { useParams } from "react-router-dom";
import {
  useGetTestimonialByIdQuery,
  useTestimonialsQuery,
} from "../services/testimonialsApi";
import PatientsSays from "../Components/Home_Page/PatientsSays";
import Loading from "../Components/Common/Loading";

const Testimonial = () => {
  const { id } = useParams();
  const { data, isLoading } = useGetTestimonialByIdQuery(id);
  const { data: videoData } = useTestimonialsQuery({
    page: "00",
    perPage: "00",
  });
  return (
    <div>
      
      <NavBar />
      {isLoading ? (
        <Loading></Loading>
      ) : (
        <>
          <Section data={data?.data} />
          <PatientsSays data={videoData?.data} />
        </>
      )}
      <Footer />
    </div>
  );
};

export default Testimonial;
