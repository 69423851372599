import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";

const Card = ({ el }) => {
  return (
    <Link to={`/news-events-blogs?search=${el?.heading?.split(' ')?.join('_')}`}>
      <div className="activeTranition max-w-[490px] h-[315px] overflow-hidden relative hover:scale-[1.1] cursor-pointer">
        <div className="w-[100%] h-[190px] relative overflow-hidden">
          <img
            className="w-[100%] h-[100%] object-cover absolute top-0 left-0 z-[10]"
            src={el?.image}
            alt=""
          />
          <p className="bg-[#0653a2] text-[#f2f2f2] px-[.635rem] py-[.5rem] text-[.75rem] absolute top-[1.43rem] right-[1.625rem] z-[20]">
            {el?.category}
          </p>
        </div>
        <div className="bg-white pl-[36px] pr-[18px] pt-[25px] pb-[40px]">
          {/* <div
            className="style-tags @apply mb-[10px]"
            dangerouslySetInnerHTML={{
              __html: `${el?.overview?.slice(0, 60)}...`,
            }}
          /> */}
          <h6 className="text-[1.1rem] font-semibold w-[100%] text-[#0a2a6c] truncate">
            {el?.heading}
          </h6>
          <div className="flex items-center flex-wrap gap-[6px]">
            <p className="text-[.75rem] text-[#0a2a6c] font-semiold">
              {el?.writer}
            </p>
            <p className="w-[2px] bg-[#6e81a8] h-[10px]"></p>
            <p className="text-[.75rem] text-[#6e81a8]">
              {moment(el?.createdAt).format("MMMM Do YYYY, h:mm:ss a")}
            </p>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default Card;
