import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { City, State } from "country-state-city";

export default function SelectCity({ state, city, setCity }) {
  const [cityList, setCityList] = React.useState([]);
  const [open, setOpen] = React.useState(false);

  const handleChange = (event) => {
    setCity(event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  React.useEffect(() => {
    const states = State.getStatesOfCountry("IN");
    if (states?.length > 0) {
      const isoCode = states.find(s =>  s?.name === state)
      //console.log(isoCode)
      const cities = City.getCitiesOfState("IN", isoCode?.isoCode);
      setCityList(cities);
    }
  }, [state]);

  return (
    <div className="w-[100%]">
      <FormControl size="medium" sx={{ width: "100%", background: "white" }}>
        <InputLabel id="demo-controlled-open-select-label">City</InputLabel>
        <Select
          labelId="demo-controlled-open-select-label"
          id="demo-controlled-open-select"
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          value={city}
          label="city"
          onChange={handleChange}
        >
          {cityList?.map((city) => (
            <MenuItem value={city.name}>{city.name}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
