import React, { useState } from "react";
import NavBar from "../Components/Common/NavBar";
import Footer from "../Components/Footer/Footer";
import { useNavigate, useParams } from "react-router-dom";
import { useGetDoctorByNameQuery } from "../services/doctorApi";
import PhoneInput from "react-phone-input-2";
import { useAddAppointmentMutation } from "../services/appointmentApi";
import { FailedAlertFunction } from "../Components/Common/FailedAlertFunction";
import BackDropComponent from "../Components/Common/BackDropComponent";
import { useBranchesQuery } from "../services/branchApi";

const Appointment = () => {
  const { name } = useParams();
  const { data, isLoading } = useGetDoctorByNameQuery(
    name?.split("-").join(" ")
  );
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [branch, setBranch] = useState("");
  const [phone, setPhone] = useState("");
  const [date, setDate] = useState("");
  const [email, setEmail] = useState("");
  const [description, setDescription] = useState("");
  const navigate = useNavigate();
  const [showBackdrop, setBackdrop] = useState(false);
  const [addAppointment] = useAddAppointmentMutation();
  const {data: branches} = useBranchesQuery({page:0, perPage: 0})
  const submitRequest = () => {
    const bodyData = {
      doctorName: data?.data?.doctorName,
      doctorSpeciality: data?.data?.speciality,
      doctorId: data?.data?._id,
      branch,
      firstName,
      lastName,
      phone,
      description,
      email,
      date,
    };
    if (
      branch !== ""&&
      branch !== "Select"&&
      branch &&
      firstName &&
      lastName &&
      phone &&
      description &&
      email &&
      date
    ) {
      addAppointment(bodyData)
        .then((res) => {
          if (res.data?.status) {
            navigate("/thank-you");
            setBackdrop(false);

            setFirstName("");
            setLastName("");
            setPhone("");
            setBranch("Select");
          } else {
            setBackdrop(false);

            FailedAlertFunction("Failed to submit the request. Try again!");
          }
        })
        .catch((error) => console.log(error));
    } else {
      setBackdrop(false);

      FailedAlertFunction("Please fill all the required info!");
    }
  };
  return (
    <div>
      <NavBar />
      <div className="max-w-[1140px] mx-auto min-h-[80vh]">
        <div className="bg-[#f2f2f2] p-[20px] px-[40px] my-[40px]">
          <h3 className="text-[1.4rem] lg:text-[2.2rem] text-[#0a2a6c] text-center font-bold mb-[25px]">
            Request An Appointment
          </h3>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-[30px]">
            <div>
              <label className="text-[#6176a5] text-[18px] font-semibold block mb-[10px]">
                Doctor<span className="text-[red]">*</span>
              </label>
              <input
                className="w-[100%] px-[10px] py-[10px] text-[1rem] text-[#000] bg-[#b2b2b26a] outline-none rounded"
                type="text"
                placeholder="First Name"
                value={data?.data?.doctorName}
                disabled
              />
            </div>

            <div>
              <label className="text-[#6176a5] text-[18px] font-semibold block mb-[10px]">
                Speciality<span className="text-[red]">*</span>
              </label>
              <input
                className="w-[100%] px-[10px] py-[10px] text-[1rem] text-[#000] bg-[#b2b2b26a] outline-none rounded"
                type="text"
                placeholder="First Name"
                value={data?.data?.speciality}
              />
            </div>

            <div>
              <label className="text-[#6176a5] text-[18px] font-semibold block mb-[10px]">
                Appointment Date<span className="text-[red]">*</span>
              </label>
              <input
                className="w-[100%] px-[10px] py-[10px] text-[1rem] text-[#000] bg-[#fff] outline-none rounded"
                type="date"
                placeholder="First Name"
                value={date}
                onChange={(e) => setDate(e.target.value)}
              />
            </div>

            <div class="relative inline-block w-[100%]">
              <label className="text-[#6176a5] text-[18px] font-semibold block mb-[10px]">
                Select Branch<span className="text-[red]">*</span>
              </label>
              <select
                value={branch}
                onChange={(e) => setBranch(e.target.value)}
                class="w-[100%] px-[10px] py-[10px] text-[1rem] text-[#000] bg-[#fff] outline-none rounded"
              >
                <option value={"Select"}>- Select -</option>
                {
                  branches?.data?.map(d =>  <option value={d?.branchName}>{d?.branchName}</option>)
                }
              </select>
            </div>

            <div>
              <label className="text-[#6176a5] text-[18px] font-semibold block mb-[10px]">
                First Name<span className="text-[red]">*</span>
              </label>
              <input
                className="w-[100%] px-[10px] py-[10px] text-[1rem] text-[#000] bg-[#fff] outline-none rounded"
                type="text"
                placeholder="First Name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </div>

            <div>
              <label className="text-[#6176a5] text-[18px] font-semibold block mb-[10px]">
                Last Name<span className="text-[red]">*</span>
              </label>
              <input
                className="w-[100%] px-[10px] py-[10px] text-[1rem] text-[#000] bg-[#fff] outline-none rounded"
                type="text"
                placeholder="Last Name"
                name="lastName"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
            <div>
              <label className="text-[#6176a5] text-[18px] font-semibold block mb-[10px]">
                Email<span className="text-[red]">*</span>
              </label>
              <input
                className="w-[100%] px-[10px] py-[10px] text-[1rem] text-[#000] bg-[#fff] outline-none rounded"
                type="email"
                placeholder="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            <div className="w-[100%] relative phone-design">
              <label className="text-[#6176a5] text-[18px] font-semibold block mb-[10px]">
                Phone<span className="text-[red]">*</span>
              </label>
              <PhoneInput
                country={"in"}
                value={phone}
                onChange={(phone) => setPhone(phone)}
                width={"100%"}
              />
            </div>
          </div>
          <div className="mt-[20px]">
            <label className="text-[#6176a5] text-[18px] font-semibold block mb-[10px]">
              Description<span className="text-[red]">*</span>
            </label>
            <textarea
              className="w-[100%] h-[100px] px-[10px] py-[10px] text-[1rem] text-[#000] bg-[#fff] outline-none rounded"
              type="text"
              placeholder="Write Your Problem..."
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>
          <div className="mt-[25px] flex justify-center items-center">
            <button
              onClick={submitRequest}
              className="bg-[#2d7bc9] text-white w-[200px] px-[40px] rounded py-[12px] hover:bg-[#0653a2]"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
      <BackDropComponent state={showBackdrop} />
      <Footer />
    </div>
  );
};

export default Appointment;
