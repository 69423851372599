import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

// import required modules
import { Navigation } from "swiper/modules";
import CarouselArrowBtns2 from "../../Common/CarouselArrowBtns2";
import PatientSaysCard from "./PatientSaysCard";
import ViewAllBtn from "../../Common/ViewAllBtn";
import SkeletonComponent from "../../Common/SkeletonComponent";

const PatientSaysCarousel = ({ data }) => {
  return (
    <div>
      {/* carousel section */}
      <div>
        <Swiper
          slidesPerView={3}
          spaceBetween={30}
          navigation={{
            nextEl: ".patient-says-arrow-right",
            prevEl: ".patient-says-arrow-left",
          }}
          className="mySwiper"
          modules={[Navigation]}
          breakpoints={{
            0: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 2, // Number of slides per view on tablets
            },
            1024: {
              slidesPerView: 3, // Number of slides per view on desktops
            },
          }}
        >
          {data?.length
            ? data?.map((el) => (
                <SwiperSlide>
                  <div className="py-[28px]">
                    <PatientSaysCard el={el} />
                  </div>
                </SwiperSlide>
              ))
            : [1, 2, 3, 4, 5].map((el) => (
                <SwiperSlide>
                  <SkeletonComponent />
                </SwiperSlide>
              ))}
        </Swiper>
        <div className="max-w-[1140px] mx-auto">
          <CarouselArrowBtns2
            left_class={"patient-says-arrow-left"}
            right_class={"patient-says-arrow-right"}
            children={
              <ViewAllBtn
                text_class={"ester_specialist_view_btn"}
                arrow_class={"ester_specialist_view_arrow"}
                route_link={"/all-testimonials"}
              />
            }
          />
        </div>
      </div>
    </div>
  );
};

export default PatientSaysCarousel;
