import axios from "axios";
import React, { createContext, useEffect, useState } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RequiredAdmin from "./Auth/RequiredAdmin";
import AboutDoctor from "./Pages/AboutDoctor";
import AboutUs from "./Pages/AboutUs";
import AllNewsEventsBlogs from "./Pages/AllNewsEventsBlogs";
import AllTestimonials from "./Pages/AllTestimonials";
import AllDoctors from "./Pages/All_Doctors";
import AllServices from "./Pages/All_Services";
import Appointment from "./Pages/Appointment";
import BranchDetails from "./Pages/BranchDetails";
import Layout from "./Pages/Dashboard/Layout";
import Login from "./Pages/Dashboard/Pages/Login";
import ManageAppointments from "./Pages/Dashboard/Pages/ManageAppointments";
import ManageAwards from "./Pages/Dashboard/Pages/ManageAwards";
import ManageBranches from "./Pages/Dashboard/Pages/ManageBranches";
import ManageDoctors from "./Pages/Dashboard/Pages/ManageDoctors";
import ManageFAQS from "./Pages/Dashboard/Pages/ManageFAQS";
import ManageLeads from "./Pages/Dashboard/Pages/ManageLeads";
import ManageNewsEventsBlogs from "./Pages/Dashboard/Pages/ManageNewsEventsBlogs";
import ManageServices from "./Pages/Dashboard/Pages/ManageServices";
import ManageTestimonials from "./Pages/Dashboard/Pages/ManageTestimonials";
import ManageUsers from "./Pages/Dashboard/Pages/ManageUsers";
import DoctorAbout from "./Pages/DoctorAbout";
import DoctorTalks from "./Pages/DoctorTalks";
import Home from "./Pages/Home";
import HomeBanner from "./Pages/HomeBanner";
import InternationalPatients from "./Pages/InternationPatients";
import NewsEventBlogs from "./Pages/NewsEventBlogs";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import Sevice from "./Pages/Sevice";
import Testimonial from "./Pages/Testimonial";
import ThankYou from "./Pages/ThankYou";
import { baseUrl } from "./services/api";
import ScrollToTop from "./utils/ScrollToTop";

export const BookFormContext = createContext();
export const UserContext = createContext();
export const FirstModalContext = createContext();

function App() {
  const [firstModal, setFirstModal] = useState(true);
  const [bookForm, setBookForm] = useState(false);
  const [user, setUser] = useState(null);
  const [refetch, setRefetch] = useState(0);
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    if (token) {
      setLoading(true);
      axios
        .get(baseUrl + "/user/autoLogin", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setUser(res?.data?.result);
          setLoading(false);
          if (res?.data?.result?.role === "admin") {
            navigate("/dashboard");
          }
        })
        .catch((error) => console.log(error));
    }
  }, [token, refetch]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div>
      <ScrollToTop>
        <FirstModalContext.Provider value={{ firstModal, setFirstModal }}>
          <BookFormContext.Provider value={{ bookForm, setBookForm }}>
            <UserContext.Provider
              value={{ user, setUser, refetch, setRefetch, loading }}
            >
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/branch/:name/:city" element={<BranchDetails />} />
                <Route path="/about-us" element={<AboutUs />} />
                <Route path="/service/:name" element={<Sevice />} />
                <Route path="/all-doctors" element={<AllDoctors />} />
                <Route path="/all-services" element={<AllServices />} />
                <Route path="/all-testimonials" element={<AllTestimonials />} />
                <Route
                  path="/all-news-events-blogs"
                  element={<AllNewsEventsBlogs />}
                />
                <Route path="/about-doctor" element={<AboutDoctor />} />
                <Route path="/doctor-talks" element={<DoctorTalks />} />
                <Route
                  path="/dashboard"
                  element={
                    <RequiredAdmin>
                      <Layout></Layout>
                    </RequiredAdmin>
                  }
                >
                  <Route index element={<ManageUsers></ManageUsers>}></Route>
                  <Route
                    path="/dashboard/manage-branches"
                    element={<ManageBranches></ManageBranches>}
                  ></Route>
                  <Route
                    path="/dashboard/manage-doctors"
                    element={<ManageDoctors></ManageDoctors>}
                  ></Route>
                  <Route
                    path="/dashboard/manage-services"
                    element={<ManageServices></ManageServices>}
                  ></Route>
                  <Route
                    path="/dashboard/manage-awards"
                    element={<ManageAwards />}
                  ></Route>
                  <Route
                    path="/dashboard/manage-testimonials"
                    element={<ManageTestimonials />}
                  ></Route>
                  <Route
                    path="/dashboard/manage-news-event-blogs"
                    element={<ManageNewsEventsBlogs />}
                  ></Route>
                  <Route
                    path="/dashboard/manage-faqs"
                    element={<ManageFAQS />}
                  ></Route>
                  <Route
                    path="/dashboard/manage-leads"
                    element={<ManageLeads />}
                  ></Route>
                  <Route
                    path="/dashboard/manage-appointments"
                    element={<ManageAppointments />}
                  ></Route>
                  <Route
                    path="/dashboard/manage-home"
                    element={<HomeBanner />}
                  ></Route>
                </Route>
                <Route path="/doctor/:name" element={<DoctorAbout />} />
                <Route path="/appointment/:name" element={<Appointment />} />
                <Route path="/login" element={<Login />} />
                <Route path="/news-events-blogs" element={<NewsEventBlogs />} />
                <Route path="/testimonial/:id" element={<Testimonial />} />
                <Route
                  path="/international-patients"
                  element={<InternationalPatients />}
                />
                <Route path="/thank-you" element={<ThankYou />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              </Routes>
            </UserContext.Provider>
          </BookFormContext.Provider>
        </FirstModalContext.Provider>
        <ToastContainer />
      </ScrollToTop>
    </div>
  );
}

export default App;
