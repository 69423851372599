import React from "react";

const TopSection = () => {
  return (
    <div className="max-w-[1140px] mx-auto">
      <h1 className="text-[1.875rem] lg:text-[2.875rem] font-semibold text-[#0a2a6c]">
        Accreditations & Awards
      </h1>
      <div className="flex justify-between items-center">
        <div className="max-w-[915px] text-[#384f7e]">
          <p className="leading-[1.5rem]">
          Avis Vascular Centre has garnered a reputation as India’s No. 1 Vascular & Interventional Radiology hospital. Here are some accolades we have earned from esteemed institutions and organisations:
          </p>
        </div>
      </div>
    </div>
  );
};

export default TopSection;
