import React from "react";

const TopSection = () => {
  return (
    <div className="max-w-[1140px] py-[3rem] mx-auto">
      <h1 className="text-white text-[1.875rem] lg:text-[2.875rem] font-semibold">
        Why Choose Avis
      </h1>
      <div className="flex justify-between items-center">
        <div className="max-w-[915px] text-white">
          <p className="leading-[1.5rem]">
          Avis Hospitals provide the highest quality of care so you can return home without pain after receiving your treatment. With our network of speciality hospitals, highly qualified doctors, and world-class technology, we bring global standards of medical care to our patients.          </p>
        </div>
      </div>
    </div>
  );
};

export default TopSection;
