import React, { useContext } from "react";
import logo from "../../Assets/Images/Avis-vascular-centre-lower.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faUsers,
  faHouseMedicalFlag,
  faUserDoctor,
  faBedPulse,
  faCommentDots,
  faCalendarDays,
  faQuestion,
  faHome,
  faSignOut,
  faUpRightFromSquare,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import { UserContext } from "../../App";
const Sidebar = ({ showFull, setShowFull, mobileNav, setMobileNav }) => {
  const { setUser } = useContext(UserContext);
  const navigate = useNavigate();
  const routes = [
    {
      id: 2,
      route: "/dashboard",
      icon: (
        <FontAwesomeIcon icon={faUsers} className="text-[24px] px-[20px]" />
      ),
      name: "Manage Users",
    },
    {
      id: 6,
      route: "/dashboard/manage-home",
      icon: <FontAwesomeIcon icon={faHome} className="text-[24px] px-[20px]" />,
      name: "Manage Home",
    },
    {
      id: 10,
      route: "/dashboard/manage-leads",
      icon: (
        <FontAwesomeIcon
          icon={faUpRightFromSquare}
          className="text-[24px] px-[20px]"
        />
      ),
      name: "Manage Leads",
    },
    {
      id: 10,
      route: "/dashboard/manage-appointments",
      icon: (
        <FontAwesomeIcon
          icon={faUsers}
          className="text-[24px] px-[20px]"
        />
      ),
      name: "Manage Appointments",
    },
    {
      id: 3,
      route: "/dashboard/manage-branches",
      icon: (
        <FontAwesomeIcon
          icon={faHouseMedicalFlag}
          className="text-[24px] px-[20px]"
        />
      ),
      name: "Manage Branches",
    },
    {
      id: 4,
      route: "/dashboard/manage-doctors",
      icon: (
        <FontAwesomeIcon
          icon={faUserDoctor}
          className="text-[24px] px-[20px]"
        />
      ),
      name: "Manage Doctors",
    },
    {
      id: 5,
      route: "/dashboard/manage-services",
      icon: (
        <FontAwesomeIcon icon={faBedPulse} className="text-[24px] px-[20px]" />
      ),
      name: "Manage Services",
    },
    {
      id: 7,
      route: "/dashboard/manage-testimonials",
      icon: (
        <FontAwesomeIcon
          icon={faCommentDots}
          className="text-[24px] px-[20px]"
        />
      ),
      name: "Manage Testimonials",
    },
    {
      id: 8,
      route: "/dashboard/manage-news-event-blogs",
      icon: (
        <FontAwesomeIcon
          icon={faCalendarDays}
          className="text-[24px] px-[20px]"
        />
      ),
      name: "Manage News Events Blogs",
    },
    {
      id: 10,
      route: "/dashboard/manage-awards",
      icon: (
        <FontAwesomeIcon
          icon={faCalendarDays}
          className="text-[24px] px-[20px]"
        />
      ),
      name: "Manage Awards",
    },
    {
      id: 9,
      route: "/dashboard/manage-faqs",
      icon: (
        <FontAwesomeIcon icon={faQuestion} className="text-[24px] px-[20px]" />
      ),
      name: "Manage FAQS",
    },
  ];

  const handleLogOut = () => {
    localStorage.removeItem("token");
    setUser(null);
    navigate("/");
  };
  return (
    <>
      <div
        className={`fixed h-[100vh]  bg-[#0653a2] top-0 hidden lg:block z-[100000] ${
          showFull ? "w-[70px]" : "w-[300px]"
        } `}
      >
        <div
          className={`h-[10vh] ${
            showFull
              ? "flex justify-center items-center"
              : "flex justify-between items-center px-[15px] py-[15px] "
          }`}
        >
          {!showFull && <img src={logo} className="w-[200px]" alt="logo" />}
          <div>
            <FontAwesomeIcon
              icon={faBars}
              className="text-[28px] text-[#fff] cursor-pointer"
              onClick={() => setShowFull(!showFull)}
            />
          </div>
        </div>
        <div className={`${showFull ? "flex flex-col justify-center" : ""}`}>
          {routes?.map((route) => (
            <Link to={route?.route}>
              <div className="flex justify-start items-center text-[#fff] py-[15px] hover:bg-[#0e2327] hover:text-[#fff] cursor-pointer">
                <div>{route?.icon}</div>
                {!showFull && <div className="text-[20px]">{route?.name}</div>}
              </div>
            </Link>
          ))}
          <div
            onClick={handleLogOut}
            className="flex justify-start items-center text-[#fff] py-[15px] hover:bg-[#0e2327] hover:text-[#fff] cursor-pointer"
          >
            <div>
              <FontAwesomeIcon
                icon={faSignOut}
                className="text-[24px] px-[20px]"
              />
            </div>
            {!showFull && <div className="text-[20px]">Logout</div>}
          </div>
        </div>
      </div>

      {/* mobile device */}
      <div
        className={`fixed h-[100vh]  bg-[#0653a2] top-0 block lg:hidden ${
          mobileNav ? "w-[70px]" : "w-[300px]"
        } `}
      >
        <div
          className={`h-[10vh] ${
            mobileNav
              ? "flex justify-center items-center"
              : "flex justify-between items-center px-[15px] py-[15px] "
          }`}
        >
          {!mobileNav && <img src={logo} className="w-[200px]" alt="logo" />}
          <div>
            <FontAwesomeIcon
              icon={faBars}
              className="text-[28px] text-[#fff] cursor-pointer"
              onClick={() => setMobileNav(!mobileNav)}
            />
          </div>
        </div>
        <div className={`${mobileNav ? "flex flex-col justify-center" : ""}`}>
          {routes?.map((route) => (
            <Link to={route?.route}>
              <div className="flex justify-start items-center text-[#fff] py-[15px] hover:bg-[#0e2327] hover:text-[#fff] cursor-pointer">
                <div onClick={() => setMobileNav(false)}>{route?.icon}</div>
                {!mobileNav && <div className="text-[20px]">{route?.name}</div>}
              </div>
            </Link>
          ))}
          <div className="flex justify-start items-center text-[#fff] py-[15px] hover:bg-[#0e2327] hover:text-[#fff] cursor-pointer">
            <div onClick={handleLogOut}>
              {" "}
              <FontAwesomeIcon
                icon={faSignOut}
                className="text-[24px] px-[20px]"
              />
            </div>
            {!mobileNav && <div className="text-[20px]">Logout</div>}
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
