import React from "react";
import bannerImg from "../../Assets/Images/about-doctor.jpeg";

const TopBanner = () => {
  return (
    <div className="w-[100%] h-[190px] relative">
      {" "}
      {/* absolute bg image */}{" "}
      <img
        className="absolute w-[100%] h-[100%] object-fill"
        src={bannerImg}
        alt="banner"
      />
      <div className="max-w-[1140px] h-[100%] flex flex-col items-end justify-center mx-auto relative z-[10]">
        <h1 className="text-[20px] md:text-[25px] lg:text-[32px] font-bold text-white">
          Dr. Rajah V Koppala
        </h1>{" "}
        <div className="text-white text-[12px]">
          <p> Vascular & Interventional Radiologist</p>
        </div>
      </div>
    </div>
  );
};

export default TopBanner;
