import React from "react";
import TopForm from "../../../Components/Dashboard/Manage_Users/TopForm";
import UserTable from "../../../Components/Dashboard/Manage_Users/UserTable";

const ManageUsers = () => {
  return (
    <div className="flex flex-col gap-[1rem] max-w-[1300px] mx-auto mt-[3rem]">
      <div className="bg-[#ececec] p-[15px] rounded overflow-hidden">
        <h3 className="mb-[20px] text-[24px] font-bold">Add Users</h3>
        <TopForm />
      </div>
      <div className="bg-[#ececec] p-[15px] rounded overflow-hidden">
        <h3 className="mb-[20px] text-[24px] font-bold"> Users List</h3>

        <UserTable />
      </div>
    </div>
  );
};

export default ManageUsers;
