import React from "react";
import DoctorInfo from "../../../Components/Dashboard/Manage_Doctors/DoctorInfo";
import DoctorTable from "../../../Components/Dashboard/Manage_Doctors/DoctorTable";

const ManageDoctors = () => {
  return (
    <div className="container mx-auto my-[15px]">
      <div className="bg-[#ececec] p-[15px] rounded overflow-hidden mb-[30px]">
        <h3 className="mb-[20px] text-[24px] font-bold">Add Doctor</h3>
        <DoctorInfo />
      </div>
      <div className="bg-[#ececec] p-[15px] rounded overflow-hidden">
        <h3 className="mb-[20px] text-[24px] font-bold"> Doctors List</h3>
        <DoctorTable />
      </div>
    </div>
  );
};

export default ManageDoctors;
