import React from "react";
import bannerImg from "../../Assets/Images/about-us-new.jpg.webp";

const TopBanner = () => {
  return (
    <div>
      {/* ////// banner section ////// */}
      <div id="overview" className="w-[100%] h-[400px] relative">
        {/* ////// absolute background image ////// */}
        <img
          className="h-[100%] w-[100%] object-cover absolute top-0 z-[10]"
          src={bannerImg}
          alt=""
        />
        <div className="h-[100%] max-w-[1140px] mx-auto flex items-center relative z-[20] px-[20px] xl:px-0">
          <h1 className="text-[1.5rem] lg:text-[2.875rem] text-white font-semibold">
            About Avis Hospitals - India
          </h1>
        </div>
      </div>
      {/* ////// text section ////// */}
      <div className="max-w-[1140px] mx-auto flex flex-col gap-[1rem] bg-white text-[#6176a5] leading-[1.5rem] py-[48px] px-[20px] xl:px-0">
        <p>
          At Avis Vascular Centre, we have always been at the forefront of
          healthcare excellence. With state-of-the-art facilities and a panel of
          internationally trained specialists, we take pride in providing
          top-notch medical care to our patients.
        </p>
        <p>
        Our core strength lies in our expertise in day care and short stay surgeries for a wide range of painful conditions. Whether you require relief from joint problems like osteoarthritis, vein problems such as varicose veins, or tumours such as uterine fibroids or thyroid nodules, you can trust us to deliver the highest standards of care.
        </p>
        <p>
        We accept insurance, ensuring that you have seamless access to the care you deserve. Additionally, we partake in state government employee reimbursement schemes, further enhancing the accessibility of our services.
        </p>
        
        <p>
          Our commitment to excellence extends beyond borders. We take pride in
          welcoming international patients from various parts of the world,
          including the Middle East and African countries. Avis Vascular Centre
          is a trusted choice for patients from the world over.
        </p>
        <p>Explore our services and discover a new standard of care today.</p>
      </div>
    </div>
  );
};

export default TopBanner;
