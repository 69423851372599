import React, { useState } from "react";
import { useAddFaqMutation } from "../../../services/faqApi";
import BackDropComponent from "../../Common/BackDropComponent";
import { SuccessAlertFunction } from "../../Common/SuccessAlert";
import { FailedAlertFunction } from "../../Common/FailedAlertFunction";

const InputFields = () => {
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [addFaq] = useAddFaqMutation();
  const [showBackdrop, setBackdrop] = useState(false);

  const handleAddFaq = () => {
    const data = {
      question: question,
      answer: answer,
    };
    if (question && answer) {
      setBackdrop(true);
      addFaq(data)
        .then((res) => {
          if (res?.data?.status) {
            setQuestion("");
            setAnswer("");
            SuccessAlertFunction(res?.data?.message);
            setBackdrop(false);
          } else {
            FailedAlertFunction(res?.data?.message);
            setBackdrop(false);
          }
        })
        .catch((error) => {
          FailedAlertFunction(error?.message);
          setBackdrop(false);
        });
    } else {
      FailedAlertFunction("Please fill all the details!");
    }
  };
  return (
    <div className="flex flex-col gap-[2rem]">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-[1rem]">
        <div className="flex flex-col">
          <label className="text-[13px]" htmlFor="faqHeading">
            FAQ Heading
          </label>
          <input
            type="text"
            className="w-[100%] h-[48px] bg-[#fff] p-[1rem] border focus:outline-none"
            placeholder="FAQ Heading"
            name="faqHeading"
            value={question}
            onChange={(e) => setQuestion(e.target.value)}
          />
        </div>
        <div className="flex flex-col">
          <label className="text-[13px]" htmlFor="faqText">
            FAQ Text
          </label>
          <input
            type="text"
            className="w-[100%] h-[48px] bg-[#fff] p-[1rem] border focus:outline-none"
            placeholder="FAQ Text"
            name="faqText"
            value={answer}
            onChange={(e) => setAnswer(e.target.value)}
          />
        </div>
      </div>
      <div>
        <button
          onClick={handleAddFaq}
          className="w-[100%] h-[48px] bg-[#2796ac] hover:bg-[#1a778a] text-white uppercase"
        >
          Add
        </button>
      </div>
      <BackDropComponent state={showBackdrop} />
    </div>
  );
};

export default InputFields;
