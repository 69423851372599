// import React, { useEffect } from "react";
// import NavBar from "../Components/Common/NavBar";
// import Footer from "../Components/Footer/Footer";
// import TopBanner from "../Components/International_Patients/TopBanner";
// import TreatSection from "../Components/International_Patients/We_Treat_Well/TreatSection";
// import PlanSection from "../Components/International_Patients/Plan_Your_Care/PlanSection";
// import AvisExcellenceSection from "../Components/International_Patients/Avis_Excellence/AvisExcellenceSection";
// import WhyChooseFaqSection from "../Components/International_Patients/Why_Choose_Avis/WhyChooseFaqSection";

// const InternationalPatients = () => {
//   useEffect(() => {
//     document.title = "Advanced Varicose Veins Treatments for International Patients - Avis Hospital";
//   }, []);

//   return (
//     <div className="mx-auto">
//       <NavBar />
//       <TopBanner from={'service'}/>
//       <div className="pt-[3rem] px-[20px] xl:px-0">
//         <TreatSection />
//         <AvisExcellenceSection />
//         <PlanSection />
//         <WhyChooseFaqSection />
//       </div>
//       <Footer />
//     </div>
//   );
// };

// export default InternationalPatients;

import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import NavBar from "../Components/Common/NavBar";
import Footer from "../Components/Footer/Footer";
import TopBanner from "../Components/International_Patients/TopBanner";
import TreatSection from "../Components/International_Patients/We_Treat_Well/TreatSection";
import PlanSection from "../Components/International_Patients/Plan_Your_Care/PlanSection";
import AvisExcellenceSection from "../Components/International_Patients/Avis_Excellence/AvisExcellenceSection";
import WhyChooseFaqSection from "../Components/International_Patients/Why_Choose_Avis/WhyChooseFaqSection";

const InternationalPatients = () => {
  useEffect(() => {
    document.title = "Advanced Varicose Veins Treatments for International Patients - Avis Hospital";
  }, []);

  return (
    <div className="mx-auto">
      <Helmet>
        <title>Advanced Varicose Veins Treatments for International Patients - Avis Hospital</title>
        <meta name="description" content="Discover advanced treatments for Varicose Veins at Avis Hospital, tailored specifically for international patients seeking the best care." />
        <meta name="keywords" content="International Patients, Varicose Veins Treatment, Avis Hospital, Advanced Varicose Veins Treatments, Varicose Veins" />
      </Helmet>
      <NavBar />
      <TopBanner from={'service'}/>
      <div className="pt-[3rem] px-[20px] xl:px-0">
        <TreatSection />
        <AvisExcellenceSection />
        <PlanSection />
        <WhyChooseFaqSection />
      </div>
      <Footer />
    </div>
  );
};

export default InternationalPatients;
