import React from "react";
import BannerInputes from "../../../Components/Dashboard/Manage_Sliders/BannerInputes";
import SliderTable from "../../../Components/Dashboard/Manage_Sliders/SliderTable";

const ManageAwards = () => {
  return (
    <div className="container flex flex-col gap-[3rem] mx-auto my-[15px]">
      <div className="bg-[#ececec] p-[15px] rounded overflow-hidden mb-[30px]">
        <h3 className="mb-[20px] text-[24px] font-bold">Add Award</h3>
        <BannerInputes />
      </div>
      <div className="bg-[#ececec] p-[15px] rounded overflow-hidden">
        <h3 className="mb-[20px] text-[24px] font-bold"> Awards List</h3>
        <SliderTable />
      </div>
    </div>
  );
};

export default ManageAwards;
