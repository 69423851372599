import React, { useContext } from "react";
import TopCarouseDiv1 from "./CarouselDivs/TopCarouseDiv1";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Pagination, Autoplay } from "swiper/modules";
import AppointmentSerach from "./AppointmentSerach";
import InQueryForm from "./InQueryForm";
import { BookFormContext, FirstModalContext } from "../../App";
import { Slide } from "react-awesome-reveal";

const TopCarouse = ({ data, from }) => {
  const { bookForm } = useContext(BookFormContext);
  const { firstModal, setFirstModal } = useContext(FirstModalContext);
  return (
    <div
      className={`serviceBannerContainer w-[100%] h-[210px] md:h-[480px] lg:h-[500px] xl:h-[700px] relative ${
        firstModal
          ? "mt-0 h-[200px]"
          : `${from === "branch" ? "lg:mb-0" : "h-[300px] mb-[200px] lg:mb-0"}`
      }`}
    >
      {from === "home" && (
        <Swiper
          pagination={{
            clickable: true,
          }}
          autoplay
          loop
          modules={[Pagination, Autoplay]}
        >
          {data?.banners?.map((d) => (
            <SwiperSlide>
              <TopCarouseDiv1 d={d} />
            </SwiperSlide>
          ))}
        </Swiper>
      )}
      {from === "branch" && (
        <Swiper
          pagination={{
            clickable: true,
          }}
          autoplay
          loop
          modules={[Pagination, Autoplay]}
        >
          {data?.banners?.map((d) => (
            <SwiperSlide>
              <TopCarouseDiv1 d={d} />
            </SwiperSlide>
          ))}

          {/* <SwiperSlide>
          <TopCarouselDiv2 />
        </SwiperSlide> */}
        </Swiper>
      )}

      {from === "branch" && (
        <>
          {firstModal && (
            <Slide
              delay={500}
              className="w-[100%] top-[133px] xl:translate-y-[-50%] fixed lg:absolute lg:top-[50%] lg:left-0 lg:right-0 z-[1000] block lg:hidden"
            >
              <InQueryForm
                stylesClass={"right-0 left-0 top-0 w-[100%] xl:w-[350px] block"}
                from={"middle"}
                from1={"slide"}
                setFirstModal={setFirstModal}
              />
            </Slide>
          )}
        </>
      )}
      {from === "home" && (
        <>
          {!bookForm && firstModal && (
            <Slide
              delay={2500}
              className="w-[100%] top-[133px] xl:translate-y-[-50%] fixed lg:absolute lg:top-[50%] lg:left-0 lg:right-0 z-[1000]"
            >
              <InQueryForm
                stylesClass={"right-0 left-0 top-0 w-[100%] xl:w-[350px] block"}
                from={"middle"}
                from1={"slide"}
                setFirstModal={setFirstModal}
              />
            </Slide>
          )}
          {bookForm && !firstModal && (
            <InQueryForm
              stylesClass={"right-0 left-0 top-0 w-[100%] xl:w-[350px] block"}
              from={"middle"}
              setFirstModal={setFirstModal}
            />
          )}
        </>
      )}
      {from === "home" ? (
        <>{!bookForm && !firstModal && <AppointmentSerach />}</>
      ) : (
        <>
          {/* {!bookForm && !firstModal && (
            <InQueryForm
              stylesClass={`h-[620px] xl:right-[5%] top-0 w-[100%] xl:w-[300px] inqueryFormhidden ${
                from === "branch" && "hidden lg:block"
              }`}
              from={"side"}
              setFirstModal={setFirstModal}
            />
          )} */}
          {bookForm && (
            <InQueryForm
              stylesClass={`right-0 left-0 top-0 w-[100%] xl:w-[350px] block ${
                from === "branch" && "block lg:hidden"
              }`}
              from={"middle"}
            />
          )}
          <InQueryForm
            stylesClass={`h-[620px] xl:right-[5%] top-0 w-[100%] xl:w-[300px] inqueryFormhidden ${
              from === "branch" && "hidden lg:block"
            }`}
            from={"side"}
            setFirstModal={setFirstModal}
          />
        </>
      )}
    </div>
  );
};

export default TopCarouse;
