import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

// import required modules
import { Navigation } from "swiper/modules";
import CarouselArrowButtons from "../../Common/CarouselArrowButtons";
import Card from "./Card";
import ViewAllBtn from "../../Common/ViewAllBtn";
import SkeletonComponent from "../../Common/SkeletonComponent";

const Carousel = ({ data, isLoading }) => {
  return (
    <>
      <div className="max-w-[1140px] mx-auto">
        <Swiper
          spaceBetween={45}
          navigation={{
            nextEl: ".news-events-arrow-right",
            prevEl: ".news-events-arrow-left",
          }}
          className="mySwiper"
          modules={[Navigation]}
          breakpoints={{
            0: {
              slidesPerView: 1.18,
            },
            768: {
              slidesPerView: 2.5, // Number of slides per view on tablets
            },
            1024: {
              slidesPerView: 3.5, // Number of slides per view on desktops
            },
            1300: {
              slidesPerView: 3.5,
            },
            1500: {
              slidesPerView: 3.5,
            },
          }}
        >
          {!isLoading ? (
            <>
              {data?.length > 0 ? (
                <>
                  {data?.map((el) => (
                    <SwiperSlide>
                      <div className="py-[50px]">
                        <Card el={el} />
                      </div>
                    </SwiperSlide>
                  ))}
                </>
              ) : (
                <>
                  <h3 className="mb-[110px] mt-[50px] text-[white] text-[28px] text-center">No Blogs Available!</h3>
                </>
              )}
            </>
          ) : (
            [1, 2, 3, 4, 5].map((el) => (
              <SwiperSlide>
                <SkeletonComponent />
              </SwiperSlide>
            ))
          )}
        </Swiper>
      </div>
      <div className="max-w-[1140px] mx-auto">
        <CarouselArrowButtons
          left_class="news-events-arrow-left"
          right_class="news-events-arrow-right"
          from="news-event"
          children={
            <ViewAllBtn
              text_class={"ester_view_btn"}
              arrow_class={"ester_view_arrow"}
              route_link={"/all-news-events-blogs"}
            />
          }
        />
      </div>
    </>
  );
};

export default Carousel;
