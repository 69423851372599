import React from "react";

const DoctorTalk = ({ el }) => {
  const getEmbedLink = (link) => {
    const youtubeRegex =
      /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/(watch\?v=.{11}|embed\/.{11}|.{11})/;
    if (youtubeRegex.test(link)) {
      const videoId = link?.match(
        /(?:youtu\.be\/|youtube\.com\/(?:watch\?(?:.*&)?v=|(?:embed|v)\/))([\w-]{11})/
      )[1];
      return `https://www.youtube.com/embed/${videoId}`;
    } else {
      return false;
    }
  };
  return (
    <div className="w-[292px] xl:w-[360px] h-[330px]  cursor-pointer">
      <div className="w-[100%] h-[230px]">
        <iframe
          style={{ width: "100%", height: "100%" }}
          src={getEmbedLink(el?.videoLink)}
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      </div>
      <div className="px-[33px] py-[16px] bg-[#f2f2f2]">
        <div>
          <h6 className="text-[#0a2a6c] font-semibold">{el?.doctorName}</h6>
          <small className="text-[#6e81a8]">{el?.qualification}</small>
        </div>
      </div>
    </div>
  );
};

export default DoctorTalk;
