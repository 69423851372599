import React from "react";
import { Link } from "react-router-dom";

const DrawerLeft = ({ activeMid, setActiveMid }) => {
  // Function to handle brochure download
  const handleDownloadBrochure = () => {
    // Change 'path_to_brochure' to the actual path of your brochure file
    const brochureUrl = 'https://flipbookpdf.net/web/site/367fe87d55ec82e091b7dfd259e70315669b4796202407.pdf.html';
    window.open(brochureUrl, '_blank');
  };

  return (
    <div className="border min-w-[105px] max-h-[100%] px-[33.578px] py-[42.500px] box-content">
      <ul className="flex flex-col gap-[1rem]">
        <li
          onMouseEnter={() => setActiveMid(null)}
          className="text-[18px] text-[#0a2a6c] hover:text-[#0653a2] font-bold cursor-pointer"
        >
          <Link to={"/"}>Home</Link>
        </li>
        <li
          onMouseEnter={() => setActiveMid("about us")}
          className="flex items-center gap-[10px] text-[18px] text-[#0a2a6c] hover:text-[#0653a2] font-bold cursor-pointer"
        >
          <Link to={"/about-us"}> About Us</Link>
        </li>
        <li
          onMouseEnter={() => setActiveMid("academics")}
          className="flex items-center gap-[10px] text-[18px] text-[#0a2a6c] hover:text-[#0653a2] font-bold cursor-pointer"
        >
          <Link to={"/international-patients"}> International Patients</Link>
        </li>
        <li
          onMouseEnter={() => setActiveMid("academics")}
          className="flex items-center gap-[10px] text-[18px] text-[#0a2a6c] hover:text-[#0653a2] font-bold cursor-pointer"
        >
          <Link to={"/about-doctor"}>About Doctor</Link>
        </li>
        <li
          onMouseEnter={() => setActiveMid("academics")}
          className="flex items-center gap-[10px] text-[18px] text-[#0a2a6c] hover:text-[#0653a2] font-bold cursor-pointer"
        >
          <Link to={"/doctor-talks"}>Doctor Talks</Link>
        </li>
        <li
          onMouseEnter={() => setActiveMid("academics")}
          className="flex items-center gap-[10px] text-[18px] text-[#0a2a6c] hover:text-[#0653a2] font-bold cursor-pointer"
        >
          <Link to={"/all-testimonials"}>Testimonials</Link>
        </li>
        <li
          onMouseEnter={() => setActiveMid("academics")}
          className="flex items-center gap-[10px] text-[18px] text-[#0a2a6c] hover:text-[#0653a2] font-bold cursor-pointer"
        >
          <Link to={"/all-news-events-blogs"}>Blogs</Link>
        </li>
        <li
          onMouseEnter={() => setActiveMid("academics")}
          className="flex items-center gap-[10px] text-[18px] text-[#0a2a6c] hover:text-[#0653a2] font-bold cursor-pointer"
        >
          <Link to={"/privacy-policy"}>Privacy Policy</Link>
        </li>
        {/* <li
          onMouseEnter={() => setActiveMid("refund policy")}
          className="flex items-center gap-[10px] text-[18px] text-[#0a2a6c] hover:text-[#0653a2] font-bold cursor-pointer"
        >
          <Link to={"/refund-policy"}>Refund Policy</Link>
        </li> */}
        {/* <li
          onMouseEnter={() => setActiveMid("shipping")}
          className="flex items-center gap-[10px] text-[18px] text-[#0a2a6c] hover:text-[#0653a2] font-bold cursor-pointer"
        >
          <Link to={"/shipping"}>Shipping</Link>
        </li> */}
        {/* Download Brochure Button */}
        <li
          className="flex items-center gap-[10px] text-[18px] text-[#0a2a6c] hover:text-[#0653a2] font-bold cursor-pointer"
          onClick={handleDownloadBrochure} // Call handleDownloadBrochure when clicked
        >
          <button className="text-[18px] text-[#0a2a6c] hover:text-[#0653a2] font-bold cursor-pointer">
            Download Brochure
          </button>
        </li>
      </ul>
    </div>
  );
};

export default DrawerLeft;
