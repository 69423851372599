import React, { useState } from "react";
import SelectImage from "../Manage_Branches/SelectImage";
import { useAddDoctorMutation } from "../../../services/doctorApi";
import SelectHospital from "./SelectHospital";
import BackDropComponent from "../../Common/BackDropComponent";
import { SuccessAlertFunction } from "../../Common/SuccessAlert";
import { FailedAlertFunction } from "../../Common/FailedAlertFunction";

const DoctorInfo = () => {
  const [addDoctor] = useAddDoctorMutation();
  const [overview, setOverview] = useState("");
  const [hospital, setHospital] = React.useState("");
  const [doctorsInfo, setDoctorsInfo] = useState({
    doctorName: "",
    role: "",
    qualification: "",
    speciality: "",
    languages: "",
  });
  const [image, setImage] = useState("");
  const [expertise, setExpertise] = useState("");
  const [showBackdrop, setBackdrop] = useState(false);

  const handleChange = (e) => {
    setDoctorsInfo({ ...doctorsInfo, [e.target.name]: e.target.value });
  };
  const handleSave = () => {
    const data = {
      ...doctorsInfo,
      overview: overview?.split("\n"),
      image,
      hospital,
      expertise: expertise?.split("\n"),
    };
    if (overview && hospital && image) {
      setBackdrop(true);
      addDoctor(data)
        .then((res) => {
          if (res?.data?.status) {
            setDoctorsInfo({
              doctorName: "",
              role: "",
              qualification: "",
              speciality: "",
              hospital: "",
              overview: "",
            });
            setImage("");
            setExpertise("");
            setExpertise("");
            SuccessAlertFunction(res?.data?.message);
            setBackdrop(false);
          } else {
            FailedAlertFunction(res?.data?.message);
            setBackdrop(false);
          }
        })
        .catch((error) => {
          setBackdrop(false);
          FailedAlertFunction(error?.message);
        });
    } else {
    }
  };
  return (
    <div className="w-[100%] flex flex-col gap-[2rem] py-[3rem]">
      <div className="grid grid-cols-3 gap-[1rem]">
        <input
          className="w-[100%] h-[40px] px-[20px] py-[2px] text-[.875rem] text-[#000] bg-[#fff] focus:outline-none"
          type="text"
          placeholder="Doctor Name"
          name="doctorName"
          value={doctorsInfo?.doctorName}
          onChange={handleChange}
        />
        <input
          className="w-[100%] h-[40px] px-[20px] py-[2px] text-[.875rem] text-[#000] bg-[#fff] focus:outline-none"
          type="text"
          placeholder="Role"
          name="role"
          value={doctorsInfo?.role}
          onChange={handleChange}
        />
        <input
          className="w-[100%] h-[40px] px-[20px] py-[2px] text-[.875rem] text-[#000] bg-[#fff] focus:outline-none"
          type="text"
          placeholder="Qalification"
          name="qualification"
          value={doctorsInfo?.qualification}
          onChange={handleChange}
        />
      </div>
      <div className="grid grid-cols-3 gap-[1rem]">
        <input
          className="w-[100%] h-[40px] px-[20px] py-[2px] text-[.875rem] text-[#000] bg-[#fff] focus:outline-none"
          type="text"
          placeholder="Speciality"
          name="speciality"
          value={doctorsInfo?.speciality}
          onChange={handleChange}
        />
        <SelectHospital hospital={hospital} setHospital={setHospital} />
        <input
          className="w-[100%] h-[40px] px-[20px] py-[2px] text-[.875rem] text-[#000] bg-[#fff] focus:outline-none"
          type="text"
          placeholder="Languages"
          name="languages"
          value={doctorsInfo?.languages}
          onChange={handleChange}
        />
      </div>
      <div>
        <textarea
          className="border w-[100%] focus:outline-none p-[1rem]"
          name="overview"
          id="overview"
          cols="30"
          rows="10"
          placeholder="Overview"
          value={doctorsInfo?.overview}
          onChange={(e) => {
            setOverview(e.target.value);
          }}
        ></textarea>
      </div>
      <div className="flex flex-col">
        <textarea
          className="border w-[100%] min-h-[100px] max-h-[100px] focus:outline-none p-[1rem]"
          name="area_of_expertise"
          id="area_of_expertise"
          cols="30"
          placeholder="Area of Expertise"
          value={expertise}
          onChange={(e) => setExpertise(e.target.value)}
        ></textarea>
      </div>
      <div>
        <SelectImage
          setBackdrop={setBackdrop}
          image={image}
          setImage={setImage}
        />
      </div>
      <div className="flex justify-center">
        {image && (
          <img
            src={image}
            className="h-[150px] w-[150px]"
            alt={"bannerImage"}
          />
        )}
      </div>
      <div>
        <button
          onClick={handleSave}
          className="w-[100%] h-[48px] bg-[#2796ac] hover:bg-[#1a778a] text-white uppercase"
        >
          Save
        </button>
      </div>
      <BackDropComponent state={showBackdrop} />
    </div>
  );
};

export default DoctorInfo;
