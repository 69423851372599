import React from "react";

const InstagramIconComp = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17.327"
      height="17.913"
      viewBox="0 0 17.327 17.913"
    >
      <path
        id="instagram_copy"
        data-name="instagram copy"
        d="M5.16,17.913A5.257,5.257,0,0,1,0,12.576V5.336A5.256,5.256,0,0,1,5.16,0h7.006a5.258,5.258,0,0,1,5.161,5.336v7.24a5.258,5.258,0,0,1-5.161,5.337ZM1.742,5.336v7.24A3.479,3.479,0,0,0,5.16,16.111h7.006a3.478,3.478,0,0,0,3.416-3.535V5.336A3.478,3.478,0,0,0,12.166,1.8H5.16A3.479,3.479,0,0,0,1.742,5.336Zm2.44,3.62A4.564,4.564,0,0,1,8.662,4.323a4.563,4.563,0,0,1,4.481,4.632,4.563,4.563,0,0,1-4.481,4.633A4.564,4.564,0,0,1,4.181,8.955Zm1.743,0a2.786,2.786,0,0,0,2.738,2.83A2.784,2.784,0,0,0,11.4,8.956,2.784,2.784,0,0,0,8.662,6.125,2.786,2.786,0,0,0,5.924,8.956Zm6.156-4.6a1.075,1.075,0,1,1,1.075,1.111A1.093,1.093,0,0,1,12.08,4.356Z"
        
      />
    </svg>
  );
};

export default InstagramIconComp;
