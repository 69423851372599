import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl } from "./api";
const getTokenFromLocalStorage = () => {
  return localStorage.getItem("token");
};
export const branchApi = createApi({
  reducerPath: "branchApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseUrl}/branches`,
    prepareHeaders: (headers) => {
      const token = getTokenFromLocalStorage();
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["Branch"],
  endpoints: (builder) => ({
    branches: builder.query({
      query: ({ page, perPage }) => `/?page=${page}&perPage=${perPage}`,
      providesTags: ["Branch"],
    }),
    getBranchById: builder.query({
      query: (id) => `/${id}`,
      providesTags: ["Branch"],
    }),
    getBranchByName: builder.query({
      query: (data) => `/getBranchByName/${data?.name}/${data.city}`,
      providesTags: ["Branch"],
    }),
    addBranch: builder.mutation({
      query: (data) => ({
        url: "/",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Branch"],
    }),
    updateBranch: builder.mutation({
      query: ({ id, data }) => ({
        url: `/${id}`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["Branch"],
    }),
    deleteBranchById: builder.mutation({
      query: (id) => ({
        url: `/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Branch"],
    }),
  }),
});
export const {
  useBranchesQuery,
  useAddBranchMutation,
  useGetBranchByIdQuery,
  useUpdateBranchMutation,
  useDeleteBranchByIdMutation,
  useGetBranchByNameQuery
} = branchApi;
