import React, { useContext } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { UserContext } from "../App";

const RequiredAdmin = ({ children }) => {
  let location = useLocation();
  const token = localStorage.getItem("token");
  const { user } = useContext(UserContext);
  if (!token) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  } else {
    if (user?.role === "admin") {
      return children;
    } else {
      return <Navigate to="/" state={{ from: location }} replace />;
    }
  }
};

export default RequiredAdmin;
