import * as React from "react";
import { AiOutlineEdit } from "react-icons/ai";
import { Button, CardFooter, Typography } from "@material-tailwind/react";
import EditableRowModal from "../Manage_Links/EditableRowModal";
import { useLeadsQuery } from "../../../services/leadApi";
import moment from "moment";

const headCells = [
  {
    id: "firstName",
    numeric: false,
    disablePadding: false,
    label: "First Name",
  },
  {
    id: "lastName",
    numeric: false,
    disablePadding: false,
    label: "Last Name",
  },
  {
    id: "email",
    numeric: false,
    disablePadding: false,
    label: "Email",
  },
  {
    id: "phone",
    numeric: false,
    disablePadding: false,
    label: "phone",
  },
  {
    id: "state",
    numeric: false,
    disablePadding: false,
    label: "State",
  },
  {
    id: "city",
    numeric: false,
    disablePadding: false,
    label: "City",
  },
  {
    id: "createdAt",
    numeric: false,
    disablePadding: false,
    label: "Leads Created",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "status",
  },
  {
    id: "action",
    numeric: false,
    disablePadding: false,
    label: "Action",
  },
];

export default function Table() {
  const [showEditableModal, setEditableModal] = React.useState(false);
  const [editableRow, setEditableRow] = React.useState({});
  const [currentPage, setCurrentPage] = React.useState(1);
  const [searchText, setSearchText] = React.useState("");
  const { data, refetch } = useLeadsQuery({
    page: currentPage,
    search: searchText,
  });
  

  return (
    <>
      {/* ////// TABLE ////// */}
      <div className="w-[100%] overflow-x-auto">
        <div className="w-[100%] flex justify-between items-center gap-[20px]">
          <div className="w-[80%]">
            <input
              className="w-[100%] outline-none px-[15px] py-[8px] rounded-md text-[16px]"
              placeholder="Search By First Name, Last Name, Phone, Email or Status"
              onChange={(e) => setSearchText(e.target.value)}
              value={searchText}
            />
          </div>
          <div className="w-[20%] flex  items-center gap-[20px]">
            <button
              onClick={() => {
                setCurrentPage(1);
                refetch();
              }}
              className="w-[50%] h-[40px] block bg-[#eb4825] hover:bg-[#eb4825]  text-white uppercase rounded"
            >
              Search
            </button>
            <button
              onClick={() => {
                setSearchText("");
                refetch("");
                setCurrentPage(1);
              }}
              className="bg-[#eb4825] block text-[white] w-[50%] px-[20px] py-[8px] rounded cursor-pointer"
            >
              Clear
            </button>
          </div>
        </div>
        <table className="mt-4 w-full min-w-max table-auto text-left">
          {/* ///// TABLE HEAD ///// */}
          <thead>
            <tr>
              {headCells?.map((head, index) => (
                <th
                  key={head}
                  className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4"
                >
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="text-[1rem] font-bold leading-none opacity-70"
                  >
                    {head?.label}
                  </Typography>
                </th>
              ))}
            </tr>
          </thead>
          {/* ///// TABLE BODY ///// */}
          <tbody>
            {data?.data?.map((row, index) => {
              const isLast = index === data?.data?.length - 1;
              const classes = isLast
                ? "p-4"
                : "p-4 border-b border-blue-gray-50";

              return (
                <tr key={row?.link}>
                  <td className={classes}>
                    <p>{row?.firstName}</p>
                  </td>
                  <td className={classes}>
                    <p>{row?.lastName}</p>
                  </td>
                  <td className={classes}>
                    <p>{row?.email}</p>
                  </td>
                  <td className={classes}>
                    <p>
                      +{row?.phone.slice(0, 2)}-
                      {row?.phone.slice(2, row?.phone?.length)}
                    </p>
                  </td>
                  <td className={classes}>
                    <p>
                    {row?.state}
                    </p>
                  </td>
                  <td className={classes}>
                    <p>
                    {row?.city}
                    </p>
                  </td>
                  <td className={classes}>
                    <p>{moment(row?.createdAt).format("DD/MM/YYYY")}</p>
                  </td>
                  <td className={classes}>
                    <p>{row?.status}</p>
                  </td>
                  <td className={classes}>
                    <div className="flex gap-[.5rem]">
                      <AiOutlineEdit
                        onClick={() => {
                          setEditableModal(true);
                          setEditableRow(row);
                        }}
                        className="text-[1.3rem] cursor-pointer"
                      />
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        {/* ////// TABLE FOOTER ////// */}
        <CardFooter className="flex items-center justify-between border-t border-blue-gray-50 p-4">
          <Typography
            variant="small"
            color="blue-gray"
            className="font-normal whitespace-nowrap mr-[1rem]"
          >
            Page {currentPage} of {data?.totalPages}
          </Typography>
          <div>Total {data?.totalLeads} Items</div>
          <div className="flex gap-2">
            {currentPage > 1 ? (
              <Button
                onClick={() => setCurrentPage(currentPage - 1)}
                variant="outlined"
                size="sm"
              >
                Previous
              </Button>
            ) : (
              <Button disabled variant="outlined" size="sm">
                Previous
              </Button>
            )}
            {currentPage < data?.totalPages ? (
              <Button
                onClick={() => setCurrentPage(currentPage + 1)}
                variant="outlined"
                size="sm"
              >
                Next
              </Button>
            ) : (
              <Button disabled variant="outlined" size="sm">
                Next
              </Button>
            )}
          </div>
        </CardFooter>
      </div>
      {/* ////// EDIT ROW MODAL ////// */}
      <EditableRowModal
        showEditableModal={showEditableModal}
        setEditableModal={setEditableModal}
        row={editableRow}
      />
      {/* ////// CONFIRMATION ROW DELETE MODAL ////// */}
    </>
  );
}
