import React from "react";
import { Link } from "react-router-dom";

const PatientSaysCard = ({ el }) => {
  return (
    <Link to={`/testimonial/${el?.id}`}>
      <div className="activeTranition w-[292px] xl:w-[360px] h-[430px] hover:scale-[1.1] cursor-pointer">
        <div className="w-[100%] h-[230px]">
          <iframe
            style={{ width: "100%", height: "100%" }}
            src={`https://www.youtube.com/embed/${el?.embedLink}`}
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </div>
        <div className="px-[33px] py-[16px] bg-[#f2f2f2]">
          {el?.overview
            ?.join("\n")
            ?.slice(0, 80)
            ?.concat(".....")
            ?.split("\n")
            .map((o) => (
              <p className=" leading-[3ex] mb-[20px] text-[#384f7e] font-[300] overflow-hidden text-[14px]">
                {o}
              </p>
            ))}

          <div>
            <h6 className="text-[#0a2a6c] font-semibold">{el?.name}</h6>
            <small className="text-[#6e81a8]">{el?.address}</small>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default PatientSaysCard;
