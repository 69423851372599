import React from "react";
import chairmanImg from "../../Assets/Images/chairman copy new.jpg.webp";

const ChairmanMessage = () => {
  return (
    <div className="flex items-center flex-col xl:flex-row gap-[2rem] max-w-[1140px] mx-auto py-[48px] px-[20px] xl:px-0">
      <div>
        <h1 className="text-[2rem] lg:text-[2.875rem] font-semibold">
          Chairman’s Message
        </h1>
        <p className="text-[#384f7e] mt-[2rem]">
          At Avis Vascular Centre, we're committed to healthcare excellence
          without boundaries. Our team of renowned specialists, coupled with
          state-of-the-art facilities, ensures that you receive the finest care
          available.
        </p>
        <p className="text-[#384f7e] mt-[1rem]">
          We're on a mission to make quality healthcare accessible to all across
          India through an extensive network of hospitals. Avis Hospitals
          actively participate in government reimbursement schemes and
          collaborate with major insurance providers to ensure affordability.
        </p>
        <p className="text-[#384f7e] mt-[1rem]">
          Our success is thanks to blessings of the divine, our visionary
          leadership, and a dedicated team. We're not just a healthcare
          provider; we're your partner on the path to a healthier future.
        </p>
        <div className="mt-[1rem]">
          <h6 className="text-[18px] text-[#384f7e] font-semibold">
            Dr. Rajah V Koppala
          </h6>
          <p className="text-[#384f7e]">
            MBBS, MD, Founder Chairman and Managing Director
          </p>
          <p className="text-[#384f7e]">Avis Vascular Centre</p>
        </div>
      </div>
      <div>
        <img
          className="w-[100%] xl:min-w-[590px] h-[518px] object-cover"
          src={chairmanImg}
          alt=""
        />
      </div>
    </div>
  );
};

export default ChairmanMessage;
