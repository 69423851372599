import React from "react";
import { useServicesQuery } from "../../../services/serviceApi";
import TopSection from "./TopSection";
import CarouselComp from "./CarouselComp";

const AvisExcellenceSection = () => {
  const { data } = useServicesQuery({page: '00', perPage: '00'});
  return (
    <div>
      <div className="bg-[#f2f2f2] py-[60px] px-[20px] xl:px-0">
        <div>
          <TopSection />
          <CarouselComp data={data?.data} />
        </div>
      </div>
    </div>
  );
};

export default AvisExcellenceSection;
