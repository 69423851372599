import React from "react";
import moment from "moment";

const Section = ({ data }) => {
  return (
    <div className="max-w-[1140px] mx-auto py-[1rem] md:py-[2rem] xl:py-[3rem] px-[20px] xl:px-0">
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-[2rem]">
        <div className="w-[100%] h-[300px]">
          <iframe
            style={{ width: "100%", height: "100%" }}
            src={`https://www.youtube.com/embed/${data?.embedLink}`}
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </div>
        <div>
          {data?.overview
            ?.join("\n")
            ?.slice(0, 80)
            ?.concat(".....")
            ?.split("\n")
            .map((o) => (
              <p className=" leading-[3ex] mb-[20px] text-[#384f7e] font-[300] overflow-hidden text-[14px]">
                {o}
              </p>
            ))}
          <h4 className="mt-[1rem] text-[#0a2a6c] font-semibold">
            {data?.name}
          </h4>
          <p className="text-[13px] text-[#6e81a8]">
            {moment(data?.createdAt).format("MMMM Do YYYY, h:mm:ss a")}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Section;
