import React from "react";

const ContactTop = ({ data }) => {
  return (
    <div className="mb-[50px]">
      <div className="flex justify-between items-center text-white ">
        <h3 className="text-[1.75rem] font-semibold">Address</h3>
        {/* <button className="flex items-center gap-[8px] text-[14px]">
          GET DIRECTIONS{" "}
          <p style={{ fill: "white" }}>
            <ArrowComp />
          </p>
        </button> */}
      </div>
      <p className="text-[#f2f2f2] text-[.9rem] font-[300] leading-[1.5rem]">
        {data?.branchName} <br />
        {data?.city}, {data?.name}, India
      </p>
      {/* <button className="view_images_button flex items-center gap-[.65rem] w-[176px] h-[24px] text-[#4dc39a] bg-white hover:bg-transparent hover:text-white font-semibold uppercase mt-[1.5rem] pl-[20px] pr-[35px] py-[1rem] rounded-[.25rem] border-[1px] border-white box-content whitespace-nowrap relative">
        <p className="view_images_button_globe">
          <GlobeIconComp />
        </p>
        VIEW IMAGES
        <p className="view_images_button_arrow absolute top-[50%] right-[20px] translate-y-[-50%]">
          <ArrowComp />
        </p>
      </button> */}
    </div>
  );
};

export default ContactTop;
