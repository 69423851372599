import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import NavBar from "../Components/Common/NavBar";
import TopCarouse from "../Components/Home_Page/TopCarouse";
import Overview from "../Components/Home_Page/Overview";
import Footer from "../Components/Footer/Footer";
import AsterSpecialist from "../Components/Home_Page/AsterSpecialist";
import PatientsSays from "../Components/Home_Page/PatientsSays";
import { useTestimonialsQuery } from "../services/testimonialsApi";
import Loading from "../Components/Common/Loading";
import { useGetBranchByNameQuery } from "../services/branchApi";

const BranchDetails = () => {
  const { name, city } = useParams();
  const { data, isLoading } = useGetBranchByNameQuery({
    name: name?.split('-')?.join(' '),
    city: city?.split('-')?.join(' '),
  });
  const { data: videoData } = useTestimonialsQuery({
    page: "00",
    perPage: "00",
  });

  useEffect(() => {
    if (data?.data) {
      let first10Words = "";
      // Check if overview is a string before splitting
      if (typeof data.data.overview === 'string') {
        first10Words = data.data.overview.split(' ').slice(0, 9).join(' ');
      } else {
        // Handle the case where overview is not a string (e.g., handle as per your data structure)
        first10Words = data.data.overview.toString().split(' ').slice(0, 9).join(' '); // Convert to string or handle as appropriate
      }
      document.title = `${first10Words}`;
    }
  }, [data]);

  return (
    <div>
      <NavBar data={data?.data} />
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <TopCarouse data={data?.data} from={"branch"} />
          <Overview data={data?.data} />
          <AsterSpecialist />
          <PatientsSays data={videoData?.data} />
        </>
      )}
      <Footer />
    </div>
  );
};

export default BranchDetails;
