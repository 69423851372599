// import React, { useState } from "react";
// import TopBanner from "../Components/About_Us/TopBanner";
// import OurValues from "../Components/About_Us/OurValues";
// import OurAffiliate from "../Components/About_Us/OurAffiliate";
// import ChairmanMessage from "../Components/About_Us/ChairmanMessage";
// import NavBar from "../Components/Common/NavBar";
// import { useNewsQuery } from "../services/newsApi";
// import NewEvents from "../Components/Home_Page/NewEvents";
// import Footer from "../Components/Footer/Footer";

// const AboutUs = () => {
//   const [language, setLanguage] = useState("All");
//   const { data: newsData, isLoading } = useNewsQuery({
//     page: "00",
//     perPage: "00",
//     language: language,
//   });
//   return (
//     <div>
      
//       <NavBar />
//       <TopBanner />
//       {/* <AboutHealthcare /> */}
//       <OurValues />
//       <OurAffiliate />
//       <ChairmanMessage />
//       {/* <OurLeadership /> */}
//       <NewEvents
//         data={newsData?.data}
//         language={language}
//         setLanguage={setLanguage}
//         isLoading={isLoading}
//       />
//       <Footer />
//     </div>
//   );
// };

// export default AboutUs;

import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import TopBanner from "../Components/About_Us/TopBanner";
import OurValues from "../Components/About_Us/OurValues";
import OurAffiliate from "../Components/About_Us/OurAffiliate";
import ChairmanMessage from "../Components/About_Us/ChairmanMessage";
import NavBar from "../Components/Common/NavBar";
import { useNewsQuery } from "../services/newsApi";
import NewEvents from "../Components/Home_Page/NewEvents";
import Footer from "../Components/Footer/Footer";

const AboutUs = () => {
  const [language, setLanguage] = useState("All");
  const { data: newsData, isLoading } = useNewsQuery({
    page: "00",
    perPage: "00",
    language: language,
  });

  useEffect(() => {
    document.title = "Avis Vascular Centre - India’s only comprehensive hospital for Varicose Veins and Lymphatic Disorders";
  }, []);

  return (
    <div>
      <Helmet>
        <title>Avis Vascular Centre - India’s only comprehensive hospital for Varicose Veins and Lymphatic Disorders</title>
        <meta name="description" content="India's leading vascular centre for varicose veins, venous and lymphatic disorders. 18+ branches across India. Best vascular surgeons with high success rate." />
        <meta name="keywords" content="Varicose Veins Treatment,Best Varicose Vein Surgery,Varicose Veins Specialist,Varicose Vein Clinic,Top Vascular Surgeon" />
      </Helmet>
      <NavBar />
      <TopBanner />
      <OurValues />
      <OurAffiliate />
      <ChairmanMessage />
      <NewEvents
        data={newsData?.data}
        language={language}
        setLanguage={setLanguage}
        isLoading={isLoading}
      />
      <Footer />
    </div>
  );
};

export default AboutUs;
