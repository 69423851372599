import React from "react";

const TopCarouseDiv1 = ({ d }) => {
  return (
    <div className="serviceBanner w-[100vw] pb-[2rem] lg:pb-0 h-[210px] md:h-[480px] lg:h-[500px] xl:h-[700px] relative">
      {/* //// absolute background image //// */}
      <img
        className="bannerImge absolute top-0 w-[100%] h-[100%] md:h-[100%] object-fill lg:object-cover z-[10]"
        src={d?.bannerImage}
        alt="Heal your swollen legs with long-term relief through painless varicose veins treatment from India's top vascular specialist at Avis Vascular Centre."
      />
    </div>
  );
};

export default TopCarouseDiv1;
