import React from "react";
import TopSection from "./Patients_Says/TopSection";
import PatientSaysCarousel from "./Patients_Says/PatientSaysCarousel";

const PatientsSays = ({ data }) => {
  return (
    <div
      id="patient_stories"
      className="max-w-[1140px] mx-auto py-[48px] px-[20px] xl:px-0"
    >
      <TopSection />
      <PatientSaysCarousel data={data} />
    </div>
  );
};

export default PatientsSays;
