import React from "react";
import icons from "../../utils/icons";
import { Link } from "react-router-dom";
import { useServicesQuery } from "../../services/serviceApi";

const OurServicesOptions = () => {
  const { data } = useServicesQuery({ page: "00", perPage: "00" });
  return (
    <div className="w-[100%] h-[100%] overflow-y-auto lg:h-[100%] flex flex-col lg:flex-row gap-[1rem] px-[20px] lg:px-[18px] py-[15px]">
      <div className="">
        <div className="flex items-center gap-[8px]">
          <img src={icons?.stethoscopeIcon} alt="" />
          <h3 className="text-[20px] font-bold text-white">Specialities</h3>
        </div>
        {/* devider  */}
        <div className="w-[100%] h-[.5px] bg-[#f2f2f2] my-[.7rem]"></div>
        <div className="flex flex-col lg:flex-row  gap-[.4rem]">
          <div className="flex flex-col gap-[8px]">
            {data?.data?.slice(0, 12)?.map((el) => (
              <Link to={`/service/${el?.name?.split(' ').join('-')}`} className="text-white">
                {el?.name}
              </Link>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurServicesOptions;
