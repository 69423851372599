import React from "react";
import ContactSection from "./ContactSection";

const MidSection = ({ data }) => {
  return (
    <div>
      <div className=" flex flex-col lg:flex-row">
        <img
          className="w-[100%] lg:w-[50%] xl:max-w-[70%] lg:h-[500px]"
          src={data?.image}
          alt="Avis Hospital Hyderabad"
        />
        <ContactSection data={data} />
      </div>
    </div>
  );
};

export default MidSection;
