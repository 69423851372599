import React, { useEffect, useState } from "react";
import { Dialog } from "@mui/material";
import { BsImage } from "react-icons/bs";
import { MdOutlineClose } from "react-icons/md";
import ReactQuill from "react-quill";
import { SuccessAlertFunction } from "../../Common/SuccessAlert";
import { FailedAlertFunction } from "../../Common/FailedAlertFunction";
import { useUpdateNewsMutation } from "../../../services/newsApi";
import BackDropComponent from "../../Common/BackDropComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";

const EditNewsBlogsModal = ({ row, showEditableModal, setEditableModal }) => {
  const [heading, setHeading] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState("");
  const [category, setCategory] = useState("");
  const [writer, setWriter] = useState("");
  const [updateNews] = useUpdateNewsMutation();
  const [showBackdrop, setBackdrop] = useState(false);
  const [language, setLanguage] = useState("English");
  const languages = ["English", "Hindi", "Telugu", "Tamil", "Marathi", "Kannada", "Bengali",];
  useEffect(() => {
    setHeading(row?.heading);
    setCategory(row?.category);
    setWriter(row?.writer);
    setDescription(row?.description);
    setImage(row?.image);
    setLanguage(row?.language);
  }, [row]);

  // UPDATING ROW FUNCTION
  const handleSave = () => {
    setBackdrop(true);
    const data = {
      heading,
      overview: description,
      image,
      category,
      writer,
      language,
    };
    //console.log(data)
    updateNews({ id: row?._id, data: data })
      .then((res) => {
        if (res?.data?.status) {
          setHeading("");
          setDescription("");
          setCategory("");
          setImage("");
          setWriter("");
          setLanguage("English");
          setEditableModal(false);
          SuccessAlertFunction(res?.data?.message);
          setBackdrop(false);
        } else {
          FailedAlertFunction(res?.data?.message);
          setBackdrop(false);
          setEditableModal(false);
        }
      })
      .catch((error) => {
        FailedAlertFunction(error?.message);
        setBackdrop(false);
      });
  };

  // Define a function to handle changes in the editor content
  const handleEditorChange = (html) => {
    setDescription(html);
  };

  // handle image upload function

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("upload_preset", process.env.REACT_APP_PRESET);
      try {
        setBackdrop(true);
        const res = await axios.post(
          `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDNAME}/image/upload`,
          formData
        );
        setBackdrop(false);
        setImage(res.data.secure_url);
      } catch (error) {
        setBackdrop(false);
        console.log(error.message);
      }
    }
  };

  return (
    <Dialog maxWidth="lg" open={showEditableModal}>
      <div className="flex flex-col gap-[2rem] w-[100%] md:w-[600px] bg-white px-[2rem] py-[3rem] relative">
        {/* close modal button  */}
        <div className="absolute top-[.8rem] right-[1rem]">
          <MdOutlineClose
            onClick={() => setEditableModal(false)}
            className="text-[1.5rem] text-red-500 cursor-pointer"
          />
        </div>
        <div className="w-[100%]flex flex-col gap-[1rem]">
          <div className="flex flex-col mb-[15px]">
            <label className="text-[13px] font-bold" htmlFor="heading">
              News Heading
            </label>
            <input
              type="text"
              className="w-[100%] h-[48px] bg-[#fff] p-[1rem] border focus:outline-none"
              placeholder="Heading"
              name="heading"
              value={heading}
              onChange={(e) => setHeading(e.target.value)}
            />
          </div>
          <div className="flex flex-col mb-[15px]">
            <label className="text-[13px] font-bold" htmlFor="category">
              News Category
            </label>
            <input
              type="text"
              className="w-[100%] h-[48px] bg-[#fff] p-[1rem] border focus:outline-none"
              placeholder="News/Blog"
              name="category"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
            />
          </div>
          <div className="flex flex-col my-[15px]">
            <label className="text-[13px] font-bold" htmlFor="category">
              Language
            </label>
            <div class="relative inline-block w-[100%]">
              <select
                value={language}
                onChange={(e) => setLanguage(e.target.value)}
                class="block appearance-none w-[100%] h-[40px] px-[20px] py-[2px] text-[.875rem] text-[#000] bg-[#f3f3f3] outline-none"
              >
                {languages?.map((d) => (
                  <option value={d}>{d}</option>
                ))}
              </select>
              <div class="pointer-events-none absolute inset-y-0 right-[10px] flex items-center px-2 text-[#6e81a8]">
                <FontAwesomeIcon icon={faCaretDown} />
              </div>
            </div>
          </div>
          <div className="flex flex-col mb-[15px]">
            <label className="text-[13px] font-bold" htmlFor="writer">
              News Writer
            </label>
            <input
              type="text"
              className="w-[100%] h-[48px] bg-[#fff] p-[1rem] border focus:outline-none"
              placeholder="News Writer"
              name="writer"
              value={writer}
              onChange={(e) => setWriter(e.target.value)}
            />
          </div>
          <div className="h-[250px]">
            <label className="text-[13px] font-bold" htmlFor="description">
              News Description
            </label>

            <ReactQuill
              value={description}
              onChange={handleEditorChange}
              style={{ height: "180px" }}
            />
          </div>

          <div className="mt-[20px]">
            <label
              className="text-[1.5rem] flex items-center gap-[.5rem] justify-center w-[100%] h-[100px] bg-[#f3f3f3] cursor-pointer"
              htmlFor="updateImage"
            >
              <BsImage /> Upload Image
              <input
                onChange={handleImageUpload}
                className="hidden"
                type="file"
                name="updateImage"
                id="updateImage"
              />
            </label>
          </div>
          <div className="mb-[20px]">
            {image && (
              <img
                src={image}
                className="h-[150px] w-[290px] mx-auto mt-[15px]"
                alt={"bannerImage"}
              />
            )}
          </div>
          <div>
            <button
              onClick={handleSave}
              className="w-[100%] h-[48px] bg-[#2796ac] hover:bg-[#1a778a] text-white uppercase"
            >
              Save
            </button>
          </div>
        </div>
      </div>
      <BackDropComponent state={showBackdrop} />
    </Dialog>
  );
};

export default EditNewsBlogsModal;
