import React from "react";
import ServiceInputes from "../../../Components/Dashboard/Manage_Service/ServiceInputes";
import ServicesTable from "../../../Components/Dashboard/Manage_Service/ServicesTable";

const ManageServices = () => {
  return (
    <div className="container flex flex-col gap-[1rem] mx-auto my-[15px]">
      <div className="bg-[#ececec] p-[15px] rounded overflow-hidden mb-[30px]">
        <h3 className="mb-[20px] text-[24px] font-bold">Add Service</h3>
        <ServiceInputes />
      </div>
      <div className="bg-[#ececec] p-[15px] rounded overflow-hidden">
        <h3 className="mb-[20px] text-[24px] font-bold"> Services List</h3>
        <ServicesTable />
      </div>
    </div>
  );
};

export default ManageServices;
