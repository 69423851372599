import { Box, Skeleton } from "@mui/material";
import React from "react";

const SkeletonComponent = () => {
  return (
    <div className="py-[50px] mr-[1rem]">
      <Skeleton variant="rectangular" width="100%" height="250px" />
      <Box sx={{ pt: 0.5 }}>
        <Skeleton width="100%" />
        <Skeleton width="100%" />
        <Skeleton width="100%" />
      </Box>
    </div>
  );
};

export default SkeletonComponent;
