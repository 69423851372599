import * as React from "react";
import { HiOutlineTrash } from "react-icons/hi";
import { AiOutlineEdit } from "react-icons/ai";
import ModalDeleteTableRow from "../../Common/ModalDeleteTableRow";
import { toast } from "react-toastify";
import {
  useDeleteHomeByIdMutation,
} from "../../../services/homeApi";
import EditHomeModal from "./EditHomeModal";
import { Button, CardFooter, Typography } from "@material-tailwind/react";

const headCells = [
  {
    id: "state",
    numeric: false,
    disablePadding: false,
    label: "State",
  },
  {
    id: "city",
    numeric: false,
    disablePadding: false,
    label: "City",
  },
  {
    id: "branchname",
    numeric: false,
    disablePadding: false,
    label: "Branch Name",
  },
  {
    id: "overview",
    numeric: false,
    disablePadding: false,
    label: "Overview",
  },
  {
    id: "email",
    numeric: false,
    disablePadding: false,
    label: "Email",
  },
  {
    id: "emergancy",
    numeric: false,
    disablePadding: false,
    label: "Emergency",
  },
  {
    id: "helpline",
    numeric: false,
    disablePadding: false,
    label: "Helpline",
  },
  {
    id: "image",
    numeric: false,
    disablePadding: false,
    label: "Image",
  },
  {
    id: "action",
    numeric: false,
    disablePadding: false,
    label: "Action",
  },
];

export default function HomeTable({data}) {
  const [editableBranch, setEditableBranch] = React.useState({});
  const [showEditableModal, setEditableModal] = React.useState(false);
  const [showDeleteModal, setDeleteModal] = React.useState(false);
  const [deleteRowId, setDeleteRowId] = React.useState("");
  const [deleteHomeById] = useDeleteHomeByIdMutation();

  // REMOVE TABLE ROW FUNCTION
  const rmvFunc = () => {
    if (deleteRowId) {
      deleteHomeById(deleteRowId).then((res) => {
        if (res?.data?.status) {
          setDeleteRowId("");
          toast.success(res?.data?.message);
        } else {
          toast.error(res?.data?.message);
        }
      });
    }
  };

  return (
    <>
      {/* ////// TABLE ////// */}
      <div className="w-[100%] overflow-x-auto">
        <table className="mt-4 w-full min-w-max table-auto text-left">
          {/* ///// TABLE HEAD ///// */}
          <thead>
            <tr>
              {headCells?.map((head, index) => (
                <th
                  key={head}
                  className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4"
                >
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="text-[1rem] font-bold leading-none opacity-70"
                  >
                    {head?.label}
                  </Typography>
                </th>
              ))}
            </tr>
          </thead>
          {/* ///// TABLE BODY ///// */}
          <tbody>
            {data?.data?.map((row, index) => {
              const isLast = index === data?.data?.length - 1;
              const classes = isLast
                ? "p-4"
                : "p-4 border-b border-blue-gray-50";

              return (
                <tr key={row?.city}>
                  <td className={classes}>
                    <p>{row?.name}</p>
                  </td>
                  <td className={classes}>{row.city}</td>
                  <td className={classes}>{row.branchName}</td>
                  <td className={classes}>
                    <p className="w-[200px] text-center truncate">
                      {row.overview}
                    </p>
                  </td>
                  <td className={classes}>{row.email}</td>
                  <td className={classes}>{row.emergency}</td>
                  <td className={classes}>{row.helpline}</td>
                  <td className={classes}>
                    <p className="w-[100px] text-center truncate">
                      {}
                    </p>
                    <img src={row.image} className="h-[100px] w-[100px]" alt="img" />
                  </td>
                  <td className={classes}>
                    <div className="flex gap-[.5rem]">
                      <AiOutlineEdit
                        onClick={() => {
                          setEditableBranch(row);
                          setEditableModal(true);
                        }}
                        className="text-[1.3rem] cursor-pointer"
                      />
                      <HiOutlineTrash
                        onClick={() => {
                          setDeleteRowId(row?._id);
                          setDeleteModal(true);
                        }}
                        className="text-[1.3rem] cursor-pointer"
                      />
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        {/* ////// TABLE FOOTER ////// */}
        <CardFooter className="flex items-center justify-between border-t border-blue-gray-50 p-4">
          <Typography
            variant="small"
            color="blue-gray"
            className="font-normal whitespace-nowrap mr-[1rem]"
          >
            Page 1 of 10
          </Typography>
          <div className="flex gap-2">
            <Button variant="outlined" size="sm">
              Previous
            </Button>
            <Button variant="outlined" size="sm">
              Next
            </Button>
          </div>
        </CardFooter>
      </div>
      {/* ////// ROW EDITING MODAL ////// */}
      <EditHomeModal
        row={editableBranch}
        showEditableModal={showEditableModal}
        setEditableModal={setEditableModal}
      />
      {/* ////// CONFIRMATION MODAL ////// */}
      <ModalDeleteTableRow
        state={showDeleteModal}
        action={setDeleteModal}
        rmvFunc={rmvFunc}
      />
    </>
  );
}
