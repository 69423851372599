import React, { useEffect, useState } from "react";
import { Dialog } from "@mui/material";
import { BsImage } from "react-icons/bs";
import { MdOutlineClose } from "react-icons/md";
import { SuccessAlertFunction } from "../../Common/SuccessAlert";
import { FailedAlertFunction } from "../../Common/FailedAlertFunction";
import { useUpdateAwardMutation } from "../../../services/awardApi";
import BackDropComponent from "../../Common/BackDropComponent";
import axios from "axios";

const EditSliderModal = ({ row, showEditableModal, setEditableModal }) => {
  const [updateAward] = useUpdateAwardMutation();
  const [bannerImage, setBannerImage] = useState("");
  const [showBackdrop, setBackdrop] = useState(false);
  useEffect(() => {
    setBannerImage(row?.image);
  }, [row?.image]);

  const handleBannersUpload = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", process.env.REACT_APP_PRESET);
    try {
      setBackdrop(true);
      const res = await axios.post(
        `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDNAME}/image/upload`,
        formData
      );
      setBackdrop(false);
      setBannerImage(res.data.secure_url);
    } catch (error) {
      setBackdrop(false);
      console.log(error.message);
    }
  };

  const handleSubmit = () => {
    if (bannerImage) {
      setBackdrop(true);
      updateAward({ id: row?._id, data: { image: bannerImage } })
        .then((res) => {
          if (res?.data?.status) {
            setBannerImage("");
            setBackdrop(false);
            setEditableModal(false);
            SuccessAlertFunction(res?.data?.message);
          } else {
            FailedAlertFunction(res?.data?.message);
            setBackdrop(false);
          }
        })
        .catch((error) => {
          FailedAlertFunction(error?.message);
          setBackdrop(false);
        });
    } else {
      FailedAlertFunction("Please upload an image!");
    }
  };
  return (
    <Dialog maxWidth="lg" open={showEditableModal}>
      <div className="flex flex-col gap-[2rem] w-[100%] md:w-[600px] bg-white px-[2rem] py-[3rem] relative">
        {/* close modal button  */}
        <div className="absolute top-[.8rem] right-[1rem]">
          <MdOutlineClose
            onClick={() => setEditableModal(false)}
            className="text-[1.5rem] text-red-500 cursor-pointer"
          />
        </div>
        <div className="flex justify-center">
          {bannerImage && (
            <img
              src={bannerImage}
              className="h-[150px] w-[290px]"
              alt={"bannerImage"}
            />
          )}
        </div>
        <div>
          <label
            className="text-[1.5rem] flex items-center gap-[.5rem] justify-center w-[100%] h-[100px] bg-[#f3f3f3] cursor-pointer"
            htmlFor="imageUpdate"
          >
            <BsImage /> Upload Banner Image
            <input
              onChange={handleBannersUpload}
              className="hidden"
              type="file"
              name="imageUpdate"
              id="imageUpdate"
            />
          </label>
        </div>

        <div>
          <button
            onClick={handleSubmit}
            className="w-[100%] h-[48px] bg-[#2796ac] hover:bg-[#1a778a] text-white uppercase"
          >
            SAVE
          </button>
        </div>
      </div>
      <BackDropComponent state={showBackdrop} />
    </Dialog>
  );
};

export default EditSliderModal;
