import React from "react";
import NavBar from "../Components/Common/NavBar";
import Footer from "../Components/Footer/Footer";

const PrivacyPolicy = () => {
  return (
    <div class="">
        <NavBar />
      <div class="max-w-[1440px] mx-auto p-8 rounded-md">
        <h1 class="text-3xl font-bold mb-6">Privacy Policy</h1>

        <h2 class="text-xl font-bold mt-4">Introduction</h2>
        <p class="mb-4">
          Welcome to Avis Vascular Centre! We are committed to protecting the privacy
          and confidentiality of your personal information. This Privacy Policy
          outlines our practices concerning the collection, use, and disclosure
          of your information in connection with our healthcare services.
        </p>

        <h2 class="text-xl font-bold mt-4">Information We Collect</h2>
        <p class="mb-2">
          We collect personal information to provide you with quality healthcare
          services. This may include, but is not limited to:
        </p>
        <ul class="list-disc ml-8 mb-4">
          <li>Patient name, date of birth, and contact details.</li>
          <li>
            Medical history, current health conditions, and treatment
            information.
          </li>
          <li>Insurance and payment details.</li>
          <li>Emergency contact information.</li>
        </ul>
        <p class="mb-2">
          We may also collect sensitive information related to your health, such
          as:
        </p>
        <ul class="list-disc ml-8">
          <li>Diagnosis and treatment records.</li>
          <li>Lab results and diagnostic imaging.</li>
          <li>Medication history.</li>
        </ul>

        <h2 class="text-xl font-bold mt-4">How We Use Your Information</h2>
        <p class="mb-2">We use your information for the following purposes:</p>
        <ul class="list-disc ml-8 mb-4">
          <li>Providing medical care and treatment.</li>
          <li>Managing and coordinating healthcare services.</li>
          <li>Billing and processing insurance claims.</li>
          <li>Conducting medical research (only with your explicit consent).</li>
        </ul>

        <h2 class="text-xl font-bold mt-4">Information Sharing</h2>
        <p class="mb-2">We may share your information with:</p>
        <ul class="list-disc ml-8 mb-4">
          <li>Healthcare professionals involved in your care.</li>
          <li>Insurance providers for billing purposes.</li>
          <li>Public health agencies as required by law.</li>
          <li>Researchers (only with your explicit consent).</li>
        </ul>

        <h2 class="text-xl font-bold mt-4">Information Security</h2>
        <p class="mb-2">
          We take measures to protect your information, including:
        </p>
        <ul class="list-disc ml-8">
          <li>Secure electronic systems and encrypted communication.</li>
          <li>Restricted access to your information to authorized personnel.</li>
          <li>Regular training for staff on privacy and security practices.</li>
        </ul>

        <h2 class="text-xl font-bold mt-4">Your Rights</h2>
        <p class="mb-2">You have the right to:</p>
        <ul class="list-disc ml-8">
          <li>Access your medical records.</li>
          <li>Request corrections to your information.</li>
          <li>
            Object to the processing of your information for certain purposes.
          </li>
          <li>Withdraw consent for research participation.</li>
        </ul>

        <h2 class="text-xl font-bold mt-4">Retention of Information</h2>
        <p class="mb-4">
          We retain your information for as long as necessary for the purposes
          outlined in this policy and as required by applicable laws.
        </p>

        <h2 class="text-xl font-bold mt-4">Changes to the Privacy Policy</h2>
        <p class="mb-4">
          We may update this Privacy Policy to reflect changes in our practices.
          We will notify you of any material changes and obtain your consent if
          required by law.
        </p>

        <h2 class="text-xl font-bold mt-4">Contact Us</h2>
        <p>
          If you have any questions or concerns about our privacy practices,
          please contact our Privacy Officer at{" "}
          <a href="mailto:info@avishospitals.com" class="text-blue-500">
            info@avishospitals.com.
          </a>
        </p>
      </div>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
