import React, { useContext } from "react";
import bgImg from "../../Assets/Images/International.jpg";
import InQueryForm from "../Home_Page/InQueryForm";
import { BookFormContext, FirstModalContext } from "../../App";
import { Slide } from "react-awesome-reveal";

const TopBanner = ({ data, from }) => {
  const { bookForm } = useContext(BookFormContext);
  const { firstModal, setFirstModal } = useContext(FirstModalContext);
  return (
    <div className="serviceBannerContainer w-[100%]  h-[210px] md:h-[480px] lg:h-[500px] xl:h-[700px] py-[2rem] xl:py-0 relative">
      <img
        className="serviceBanner w-[100%] h-[210px] md:h-[100%] absolute top-0 z-[10]"
        src={bgImg}
        alt="Seek Affordable Treatment for Varicose Veins in India"
      />
      {/* {!bookForm && (
        <InQueryForm
          stylesClass={`h-[620px] xl:right-[5%] top-0 w-[100%] xl:w-[300px] inqueryFormhidden ${
            from === "service" && "hidden lg:block"
          }`}
          from={"side"}
        />
      )}
      {bookForm && (
        <InQueryForm
          stylesClass={`right-0 left-0 top-0 w-[100%] xl:w-[350px] block`}
          from={"middle"}
        />
      )} */}
      {bookForm && (
        <InQueryForm
          stylesClass={`right-0 left-0 top-0 w-[100%] xl:w-[350px] block ${
            from === "service" && "block lg:hidden"
          }`}
          from={"middle"}
        />
      )}
      <InQueryForm
        stylesClass={`h-[620px] xl:right-[5%] top-0 w-[100%] xl:w-[300px] inqueryFormhidden ${
          from === "service" && "hidden lg:block"
        }`}
        from={"side"}
      />

      {firstModal && (
        <Slide
          delay={500}
          className="w-[100%] top-[133px] xl:translate-y-[-50%] fixed lg:absolute lg:top-[50%] lg:left-0 lg:right-0 z-[1000] block lg:hidden"
        >
          <InQueryForm
            stylesClass={"right-0 left-0 top-0 w-[100%] xl:w-[350px] block"}
            from={"middle"}
            from1={"slide"}
            setFirstModal={setFirstModal}
          />
        </Slide>
      )}
    </div>
  );
};

export default TopBanner;
