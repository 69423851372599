import React, { useEffect, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

import { Navigation } from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import CarouselArrowButtons from "../../Common/CarouselArrowBtns2";

const Carousel = ({ data }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const updateIndex = (swiperInstance) => {
    if (swiperInstance === null) return;
    const currentSlide = swiperInstance?.activeIndex;
    setActiveIndex(currentSlide);
  };
  useEffect(() => {
    if (activeIndex > 0) {
      const asterCarousel = document.getElementById("doctor_swiper_slider");
      if (asterCarousel !== null) {
        asterCarousel.removeAttribute("id");
      }
    }
  }, [activeIndex]);
  return (
    <>
      <div className="max-w-[1140px] mx-auto">
        <Swiper
          navigation={{
            nextEl: ".doctor-arrow-right",
            prevEl: ".doctor-arrow-left",
          }}
          className="mySwiper"
          modules={[Navigation]}
          onActiveIndexChange={updateIndex}
          breakpoints={{
            0: {
              slidesPerView: 1.15,
            },
            768: {
              slidesPerView: 2.5, // Number of slides per view on tablets
            },
            1024: {
              slidesPerView: 3.5, // Number of slides per view on desktops
            },
            1300: {
              slidesPerView: 3.5,
            },
            1500: {
              slidesPerView: 3.5,
            },
          }}
        >
          {data?.map((d) => (
            <SwiperSlide>
              <div className="activeTranition w-[100%] md:w-[398px] h-[150px] bg-[#f3faf9] flex items-center justify-center my-[2rem] p-[20px]">
                <img
                  className="w-[100%] object-fill h-[150px]"
                  src={d?.image}
                  alt=""
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className="max-w-[1140px] mx-auto">
        <CarouselArrowButtons
          left_class={"doctor-arrow-left"}
          right_class={"doctor-arrow-right"}
        />
      </div>
    </>
  );
};

export default Carousel;
