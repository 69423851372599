import React from "react";
import ArrowComp from "../Common/ArrowComp";

const OurAffiliateCard = ({ el }) => {
  return (
    <div className="we_provide_card w-[220px] lg:w-[100%] h-[360px] hover:scale-[1.1] cursor-pointer relative overflow-hidden">
      {/* ///// absolute background image ///// */}
      <img
        className="w-[100%] h-[100%] object-cover absolute top-0 left-0 z-[10]"
        src={el?.img}
        alt=""
      />
      {/* extra div for hover background */}
      <div className="we_provde_extra_div h-[100%] w-[100%] absolute top-0 left-0 z-[15]"></div>
      <div className="we_provide_text_div h-[100%] flex flex-col gap-[1rem] text-white px-[26px] absolute z-[20]">
        <h1 className="text-[1.5625rem] font-semibold">{el?.title}</h1>
        <p>{el?.description}</p>
        <div>
          <button className="flex items-center gap-[10px] text-white uppercase font-semibold">
            Know More
            <p style={{ fill: "white" }}>
              <ArrowComp />
            </p>
          </button>
        </div>
      </div>
    </div>
  );
};

export default OurAffiliateCard;
