import React from 'react';
import AppointmentTable from '../../../Components/Dashboard/ManageAppointment/AppointmentTable';
import { useGetAllAppointmentsQuery } from '../../../services/appointmentApi';
import moment from 'moment';
import csvDownload from "json-to-csv-export";

const ManageAppointments = () => {
  const { data } = useGetAllAppointmentsQuery();
  const handlExport = () => {
    const exportData = [];
    data?.data?.forEach((element, i) => {
      exportData.push({
        id: i,
        doctorName: element?.doctorName,
        doctorSpeciality: element?.doctorSpeciality,
        branchName: element?.branch,
        firstName: element?.firstName,
        lastName: element?.lastName,
        email: element?.email,
        description: element?.description,
        appointmentDate: moment(new Date(element?.date)).format("DD/MM/YYYY"),
        phone: `00${element?.phone.slice(0, 2)}-${element?.phone.slice(2, element?.phone?.length)}`,
        status: element?.status,
        createdAt: moment(element?.createdAt).format("DD/MM/YYYY"),
      });
    });
    const dataToConvert = {
      data: exportData,
      filename: `appointments_report_${moment(new Date()).format("DD/MM/YYYY")}`,
      delimiter: ",",
      headers: [
        "id",
        "doctorName",
        "doctorSpeciality",
        "branchName",
        "firstName",
        "lastName",
        "email",
        "description",
        "appointmentDate",
        "phone",
        "status",
        "createdAt",
      ],
    };
    csvDownload(dataToConvert);
  };
    return (
        <div className="container flex flex-col gap-[1rem] mx-auto my-[15px]">
      <div className="bg-[#ececec] p-[15px] rounded overflow-hidden">
        <div className="flex justify-between items-start mb-[15px]">
          <h3 className="mb-[20px] text-[24px] font-bold">Manage Appointments</h3>
          <button
            onClick={handlExport}
            className="bg-[#2d7bc9] text-white px-[20px] rounded py-[10px] hover:bg-[#0653a2]"
          >
            Export Data
          </button>
        </div>
        <AppointmentTable />
      </div>
    </div>
    );
};

export default ManageAppointments;