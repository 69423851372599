// import React, { useEffect, useState } from "react";
// import NavBar from "../Components/Common/NavBar";
// import Footer from "../Components/Footer/Footer";
// import SkeletonComponent from "../Components/Common/SkeletonComponent";
// import { Button, CardFooter, Typography } from "@material-tailwind/react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
// import BlogsAllCard from "../Components/Common/BlogsAllCard";
// import { useNewsQuery } from "../services/newsApi";
// import Loading from "../Components/Common/Loading";

// const AllNewsEventsBlogs = () => {
//   const [language, setLanguage] = useState("All");
//   const languages = ["All", "English", "Hindi", "Telugu", "Tamil", "Marathi", "Kannada", "Bengali"];
//   const [currentPage, setCurrentPage] = useState(1);
//   const { data, isLoading } = useNewsQuery({
//     page: currentPage,
//     perPage: 6,
//     language: language,
//   });

//   useEffect(() => {
//     document.title = "News, Events and Blogs - Avis Vascular Centre";
//   }, []);

//   return (
//     <div>
//       <NavBar />
//       <div className="bg-[whitesmoke] px-[20px] xl:px-0 py-[3rem]">
//         <div className="max-w-[1140px] mx-auto">
//           <div className="flex justify-between items-start">
//             <h1 className="text-[1.875rem] lg:text-[2.875rem] font-semibold text-[#0a2a6c]">
//               News, Events and Blogs
//             </h1>
//             <div className="flex flex-col">
//               <div className="relative min-w-[140px] inline-block w-[100%]">
//                 <select
//                   value={language}
//                   onChange={(e) => setLanguage(e.target.value)}
//                   className="block appearance-none w-[100%] h-[40px] px-[20px] py-[2px] text-[.875rem] text-[#000] bg-[#ffffff] outline-none"
//                 >
//                   {languages.map((d) => (
//                     <option key={d} value={d}>
//                       {d}
//                     </option>
//                   ))}
//                 </select>
//                 <div className="pointer-events-none absolute inset-y-0 right-[10px] flex items-center px-2 text-[#6e81a8]">
//                   <FontAwesomeIcon icon={faCaretDown} />
//                 </div>
//               </div>
//             </div>
//           </div>
//           <div className="flex justify-between items-center">
//             <div className="max-w-[915px] text-[#384f7e]">
//               <p className="leading-[1.5rem]">
//                 Explore the latest achievements, events, and in-depth insights
//                 directly from Avis Vascular Centre, your trusted provider of the
//                 best vascular care.
//               </p>
//             </div>
//           </div>
//         </div>

//         <div>
//           {isLoading ? (
//             <Loading />
//           ) : (
//             <div className="max-w-[1140px] mx-auto py-[3rem] px-[20px] xl:px-0">
//               <div className="grid grid-cols-1 gap-x-[30px] gap-y-[50px] min-h-[60vh]">
//                 {!isLoading ? (
//                   <>
//                     {data?.data?.length > 0 ? (
//                       <>
//                         {data.data.map((el) => (
//                           <div key={el.id}>
//                             <BlogsAllCard el={el} />
//                           </div>
//                         ))}
//                       </>
//                     ) : (
//                       <>
//                         <div />
//                         <div>
//                           <h3 className="mb-[110px] mt-[50px] text-[#0a2a6c] text-[32px] font-bold text-center">
//                             No Blogs Available!
//                           </h3>
//                         </div>
//                         <div />
//                       </>
//                     )}
//                   </>
//                 ) : (
//                   [1, 2, 3, 4, 5].map((el) => <SkeletonComponent key={el} />)
//                 )}
//               </div>

//               <CardFooter className="flex items-center justify-between p-4 mt-[3rem]">
//                 <Typography
//                   variant="small"
//                   color="blue-gray"
//                   className="font-[500] whitespace-nowrap mr-[1rem] text-[1rem]"
//                 >
//                   Page {currentPage} of {data?.totalPages}
//                 </Typography>
//                 <div className="flex gap-2">
//                   <Button
//                     onClick={() => setCurrentPage(currentPage - 1)}
//                     variant="outlined"
//                     size="sm"
//                     disabled={currentPage <= 1}
//                   >
//                     Previous
//                   </Button>
//                   <Button
//                     onClick={() => setCurrentPage(currentPage + 1)}
//                     variant="outlined"
//                     size="sm"
//                     disabled={currentPage >= data?.totalPages}
//                   >
//                     Next
//                   </Button>
//                 </div>
//               </CardFooter>
//             </div>
//           )}
//         </div>
//       </div>
//       <Footer />
//     </div>
//   );
// };

// export default AllNewsEventsBlogs;

import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import NavBar from "../Components/Common/NavBar";
import Footer from "../Components/Footer/Footer";
import SkeletonComponent from "../Components/Common/SkeletonComponent";
import { Button, CardFooter, Typography } from "@material-tailwind/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import BlogsAllCard from "../Components/Common/BlogsAllCard";
import { useNewsQuery } from "../services/newsApi";
import Loading from "../Components/Common/Loading";

const AllNewsEventsBlogs = () => {
  const [language, setLanguage] = useState("All");
  const languages = ["All", "English", "Hindi", "Telugu", "Tamil", "Marathi", "Kannada", "Bengali"];
  const [currentPage, setCurrentPage] = useState(1);
  const { data, isLoading } = useNewsQuery({
    page: currentPage,
    perPage: 6,
    language: language,
  });

  useEffect(() => {
    document.title = "News, Events and Blogs - Avis Vascular Centre";
  }, []);

  return (
    <div>
      <Helmet>
        <title>News, Events and Blogs - Avis Vascular Centre</title>
        <meta name="description" content="Explore the latest news, events, and blogs from Avis Vascular Centre, your trusted provider of the best vascular care." />
        <meta name="keywords" content=" Blogs, Avis Vascular Centre, Vascular Care, Varicose Veins Treatment, Best Varicose Vein Surgery, Varicose Veins Specialist, Varicose Vein Clinic, Top Vascular Surgeon, Varicose Veins, Lymphedema, Fibroids, venous diseases, Varicose Veins Treatment in Hyderabad, No Pain, Same Day Discharge, Varicose Veins, Foot Ankle Treatment in Hyderabad" />
      </Helmet>
      <NavBar />
      <div className="bg-[whitesmoke] px-[20px] xl:px-0 py-[3rem]">
        <div className="max-w-[1140px] mx-auto">
          <div className="flex justify-between items-start">
            <h1 className="text-[1.875rem] lg:text-[2.875rem] font-semibold text-[#0a2a6c]">
              News, Events and Blogs
            </h1>
            <div className="flex flex-col">
              <div className="relative min-w-[140px] inline-block w-[100%]">
                <select
                  value={language}
                  onChange={(e) => setLanguage(e.target.value)}
                  className="block appearance-none w-[100%] h-[40px] px-[20px] py-[2px] text-[.875rem] text-[#000] bg-[#ffffff] outline-none"
                >
                  {languages.map((d) => (
                    <option key={d} value={d}>
                      {d}
                    </option>
                  ))}
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-[10px] flex items-center px-2 text-[#6e81a8]">
                  <FontAwesomeIcon icon={faCaretDown} />
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-between items-center">
            <div className="max-w-[915px] text-[#384f7e]">
              <p className="leading-[1.5rem]">
                Explore the latest achievements, events, and in-depth insights
                directly from Avis Vascular Centre, your trusted provider of the
                best vascular care.
              </p>
            </div>
          </div>
        </div>

        <div>
          {isLoading ? (
            <Loading />
          ) : (
            <div className="max-w-[1140px] mx-auto py-[3rem] px-[20px] xl:px-0">
              <div className="grid grid-cols-1 gap-x-[30px] gap-y-[50px] min-h-[60vh]">
                {!isLoading ? (
                  <>
                    {data?.data?.length > 0 ? (
                      <>
                        {data.data.map((el) => (
                          <div key={el.id}>
                            <BlogsAllCard el={el} />
                          </div>
                        ))}
                      </>
                    ) : (
                      <>
                        <div />
                        <div>
                          <h3 className="mb-[110px] mt-[50px] text-[#0a2a6c] text-[32px] font-bold text-center">
                            No Blogs Available!
                          </h3>
                        </div>
                        <div />
                      </>
                    )}
                  </>
                ) : (
                  [1, 2, 3, 4, 5].map((el) => <SkeletonComponent key={el} />)
                )}
              </div>

              <CardFooter className="flex items-center justify-between p-4 mt-[3rem]">
                <Typography
                  variant="small"
                  color="blue-gray"
                  className="font-[500] whitespace-nowrap mr-[1rem] text-[1rem]"
                >
                  Page {currentPage} of {data?.totalPages}
                </Typography>
                <div className="flex gap-2">
                  <Button
                    onClick={() => setCurrentPage(currentPage - 1)}
                    variant="outlined"
                    size="sm"
                    disabled={currentPage <= 1}
                  >
                    Previous
                  </Button>
                  <Button
                    onClick={() => setCurrentPage(currentPage + 1)}
                    variant="outlined"
                    size="sm"
                    disabled={currentPage >= data?.totalPages}
                  >
                    Next
                  </Button>
                </div>
              </CardFooter>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AllNewsEventsBlogs;
