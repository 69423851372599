import React, { useContext } from "react";
import InQueryForm from "../Home_Page/InQueryForm";
import { BookFormContext, FirstModalContext } from "../../App";
import { Slide } from "react-awesome-reveal";

const TopBanner = ({ data, from, from1 }) => {
  const { bookForm } = useContext(BookFormContext);
  const { firstModal, setFirstModal } = useContext(FirstModalContext);
  return (
    <div className="serviceBannerContainer w-[100%] h-[210px] md:h-[480px] lg:h-[500px] xl:h-[700px] py-[2rem] xl:py-0 relative">
      {from1 === "branch" ? (
        <img
          className="serviceBanner w-[100%] h-[210px] md:h-[100%] absolute top-0"
          src={data?.banners[0]?.bannerImage}
          alt=""
        />
      ) : (
        <img
          className="serviceBanner w-[100%] h-[210px] md:h-[100%] absolute top-0 "
          src={data?.image}
          alt=""
        />
      )}
      {bookForm && (
        <InQueryForm
          stylesClass={`right-0 left-0 top-0 w-[100%] xl:w-[350px] block ${
            from === "service" && "block lg:hidden"
          }`}
          from={"middle"}
        />
      )}
      <InQueryForm
        stylesClass={`h-[620px] xl:right-[5%] top-0 w-[100%] xl:w-[300px] inqueryFormhidden ${
          from === "service" && "hidden lg:block"
        }`}
        from={"side"}
      />

      {firstModal && (
        <Slide
          delay={500}
          className="w-[100%] top-[133px] xl:translate-y-[-50%] fixed lg:absolute lg:top-[50%] lg:left-0 lg:right-0 z-[1000] block lg:hidden"
        >
          <InQueryForm
            stylesClass={"right-0 left-0 top-0 w-[100%] xl:w-[350px] block"}
            from={"middle"}
            from1={"slide"}
            setFirstModal={setFirstModal}
          />
        </Slide>
      )}
      {/* {!bookForm && firstModal && (
        <Slide
          delay={5000}
          className="xl:absolute xl:top-[50%] lg:left-0 lg:right-0 z-[1000]"
        >
          <InQueryForm
            stylesClass={"right-0 left-0 top-0 w-[100%] xl:w-[350px] block"}
            from={"middle"}
            setFirstModal={setFirstModal}
          />
        </Slide>
      )}
      {!bookForm && !firstModal && (
        <InQueryForm
          stylesClass={`h-[620px] xl:right-[5%] top-0 w-[100%] xl:w-[300px] inqueryFormhidden ${
            from === "service" && "hidden lg:block"
          }`}
          from={"side"}
        />
      )}
      {bookForm && !firstModal && (
        <InQueryForm
          stylesClass={`right-0 left-0 top-0 w-[100%] xl:w-[350px] block`}
          from={"middle"}
        />
      )} */}
    </div>
  );
};

export default TopBanner;
