import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";

const BlogsAllCard = ({ el }) => {
  return (
    <Link to={`/news-events-blogs?search=${el?.heading?.split(' ')?.join('_')}`}>
      <div className="bg-white  activeTranition flex gap-[32px] w-[100%] h-min overflow-hidden relative hover:scale-[1.1] cursor-pointer">
        <div className="min-w-[190px] h-[150px] relative overflow-hidden">
          <img
            className="w-[100%] h-[100%] object-cover absolute top-0 left-0 z-[10]"
            src={el?.image}
            alt=""
          />
        </div>
        <div className="pl-[36px] pr-[18px] pt-[25px] pb-[40px]">
          <h6 className="text-[1.375rem] font-semibold w-[100%] text-[#0a2a6c] truncate">
            {el?.heading}
          </h6>
          <div
            className="h-[5ex] leading-[2.5ex] overflow-hidden mt-[1rem]"
            dangerouslySetInnerHTML={{ __html: el?.overview }}
          />
          <div className="mt-[.5rem]">
            <p className="text-[12px] text-[#0a2a6c] font-semiold">
              {el?.writer}
            </p>
            <p className="text-[12px] text-[#6e81a8]">
              {moment(el?.createdAt).format("MMMM Do YYYY, h:mm:ss a")}
            </p>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default BlogsAllCard;
